/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import Button from "../../stories/Button";
import { X } from "@phosphor-icons/react";
import CustomLink from "../../stories/Link";
import AuthTextInput from "../../stories/AuthTextInput";
import { Form, Formik } from "formik";
import CheckBox from "../../stories/CheckBox";
import { Validations } from "../../constants/validations";
import { useSWRConfig } from "swr";
import { useNavigate } from "@reach/router";
import { User } from "../../store";
import { AuthService } from "../../services/AuthService";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";

const LoginUserModal = ({
  open,
  onClose,
  prefill_data,
  onLoginSuccess = () => {
    window.location.reload();
  },
  className,
}) => {
  const { cache } = useSWRConfig();
  const navigate = useNavigate();
  const { updateUserData } = useProfile();

  const initialValues = {
    email: prefill_data.email ?? "",
    password: "",
    rememberMe: false,
  };

  const logoutUser = () => {
    // navigate("/login");
    window.fcWidget.user.get().then((res) => {
      window.fcWidget.user.clear();
    });
    // await window.fcWidget.destroy();
    window.fcWidget.init({
      token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
      host: "https://wchat.in.freshchat.com",
    });
    User.clearToken();
    cache.clear();
  };

  const handleLogin = async ({ email, password, rememberMe }) => {
    // first logout and then login
    logoutUser();
    try {
      const result = await AuthService.login({
        email,
        password,
      });
      if (result.token) {
        CustomToast.success(result.message);
        User.setToken(result.token);
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: email ?? null,
        });
        updateUserData(true);
        onLoginSuccess();
      }
    } catch (e) {
      if (e.error_code === "login_credentials_mismatch") {
        CustomToast.error(e.error_messages[0]);
      } else if (e.error_code === "signup_not_completed") {
        CustomToast.error(e.error_messages[0]);
        navigate("/complete-signup", {
          state: {
            token: e.payload?.token,
            email: email,
            user: e.payload?.user,
          },
        });
      } else if (e.error_code === "email_not_verified") {
        CustomToast.error(e.error_messages[0]);
        navigate("/resend-email", { state: { email } });
      } else {
        CustomToast.error(e.error_messages[0]);
      }
    }
  };

  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex justify-between text-5xl font-bold tracking-tight text-theme-black-color"
                >
                  Login to Continue
                </Dialog.Title>
                <div className="flex flex-col w-full">
                  <div className="block mt-6 text-lg font-light leading-5 text-theme-black-300">
                    We found a user account registered with this email ID.{" "}
                    <span className="font-semibold text-theme-black-color">
                      Please login
                    </span>{" "}
                    to complete your registration and access all member
                    benefits.
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={() =>
                      Validations.onBoarding.loginValidationSchema
                    }
                    onSubmit={handleLogin}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="w-full mt-8 md:mt-12">
                          <AuthTextInput
                            name="email"
                            id="email"
                            type="text"
                            label="Email"
                            autoComplete="email"
                          />
                        </div>
                        <div className="w-full mt-4">
                          <AuthTextInput
                            name="password"
                            id="password"
                            type="password"
                            label="Password"
                            showErrorIcon={true}
                            autoComplete="current-password"
                          />
                        </div>
                        <div className="flex justify-between w-full mt-3">
                          <CheckBox
                            name="rememberMe"
                            inputLabel="Remember me"
                          />
                          <CustomLink
                            target="_blank"
                            href="/forgot-password"
                            textColor="text-blue-500"
                            activeTextColor="text-red-700"
                            showUnderLine={false}
                            underLineOnlyOnHover={false}
                            className="leading-none"
                          >
                            Forgot Password
                          </CustomLink>
                        </div>
                        <Button
                          type="submit"
                          buttonStyle="primary"
                          height="60px"
                          width="100%"
                          className="mt-6"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                        >
                          LOGIN
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  {/* <div className="flex items-center justify-start w-full mt-3 text-sm leading-5">
                    New to NetworkFP? &nbsp;
                    <CustomLink
                      href="/signup"
                      textColor="text-theme-red"
                      activeTextColor="text-theme-red"
                      fontSize="md"
                      showUnderLine={true}
                      className="leading-5"
                    >
                      Sign Up for Free
                    </CustomLink>
                  </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

LoginUserModal.defaultProps = {
  open: false,
  className: "",
};

LoginUserModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  prefill_data: PropTypes.object,
  onLoginSuccess: PropTypes.func,
  open: PropTypes.bool,
};

export default LoginUserModal;
