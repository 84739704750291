/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useProfile } from "../../../../context/userProvider";
import CommentCard from "../../../../stories/CommentCard";
import CommentInput from "../../../../stories/CommentInput";
import Text from "../../../../stories/Text";
import _ from "lodash";
import classNames from "classnames";
import { X } from "@phosphor-icons/react";

const ShowThread = ({
  comment,
  repliedToComment,
  setRepliedToComment,
  onPostComment,
}) => {
  const { userDetails } = useProfile();
  return (
    <>
      <CommentCard
        key={comment.id}
        id={comment.id}
        profileSlug={comment.profile_slug}
        userName={comment.first_name + " " + comment.last_name}
        userImage={comment.profile_picture ?? ""}
        location={comment.city}
        time={comment.created_at}
        content={comment.content}
        canReply={true}
        isReply={comment.reply_of_comment ?? false}
        replyCallback={(id) => {
          setRepliedToComment(id);
        }}
      />
      {repliedToComment === comment.id && (
        <div className={_.isEmpty(comment.replies) ? "pl-13 md:ml-14" : ""}>
          <div className="flex justify-between">
            <Text
              type="text"
              size="text-sm md:text-base"
              className="font-semibold text-theme-black-color"
              content={`Replying to ${comment?.first_name} ${comment?.last_name}`}
            />
            <div
              className="flex cursor-pointer"
              onClick={() => setRepliedToComment(undefined)}
            >
              <X size={20} />
            </div>
          </div>
          <CommentInput
            userImage={userDetails.profile_picture?.signed_url ?? ""}
            buttonText="POST REPLY"
            isReply={true}
            replyingTo={comment}
            placeholder="Type Your Reply Here"
            textErrorMessage="Please add a message"
            onSubmit={onPostComment}
          />
        </div>
      )}
      {comment?.replies?.map((reply, r_index) => (
        <ShowThread
          key={r_index}
          comment={{
            ...reply,
            reply_of_comment: {
              first_name: comment.first_name,
              last_name: comment.last_name,
              profile_slug: comment.profile_slug,
            },
          }}
          // isReply={true}
          repliedToComment={repliedToComment}
          setRepliedToComment={setRepliedToComment}
          // hideRepliesInitially={false}
          onPostComment={onPostComment}
        />
      ))}
    </>
  );
};

ShowThread.propTypes = {
  comment: PropTypes.shape({
    city: PropTypes.any,
    content: PropTypes.any,
    created_at: PropTypes.any,
    first_name: PropTypes.string,
    id: PropTypes.any,
    last_name: PropTypes.any,
    profile_picture: PropTypes.shape({
      signed_url: PropTypes.string,
    }),
    profile_slug: PropTypes.any,
    replies: PropTypes.array,
    reply_of_comment: PropTypes.bool,
  }),
  isReply: PropTypes.any,
  onPostComment: PropTypes.func,
  repliedToComment: PropTypes.any,
  setRepliedToComment: PropTypes.func,
};

const CommentsTab = ({ comments = [], onPostComment, allowCreateComment }) => {
  const [repliedToComment, setRepliedToComment] = useState(null);
  const { userDetails } = useProfile();
  const [page, setPageNumber] = useState(1);
  const [threads, setThreads] = useState([]);

  const searchReplies = (array = [], commentId) => {
    return array
      .filter((e) => e.reply_of_comment?.comment_id === commentId)
      .map((comment) => {
        const replies = searchReplies(array, comment.id);
        return { ...comment, replies: replies };
      });
  };

  useEffect(() => {
    const tempThreads = [
      ...comments
        .filter((e) => !e.reply_of_comment)
        .map((comment) => {
          const replies = searchReplies(comments, comment.id);
          return { ...comment, replies: replies };
        }),
    ];
    setThreads(tempThreads);
  }, [comments]);

  return (
    <div className="mb-10 space-y-5 transition-all duration-500">
      {allowCreateComment && (
        <React.Fragment>
          <Text
            type="text"
            size="text-sm md:text-base"
            className="font-semibold text-theme-black-color"
            content="Ask your question"
          />
          <CommentInput
            userImage={userDetails.profile_picture?.signed_url ?? ""}
            buttonText="POST"
            placeholder="Ask your question here"
            textErrorMessage="Please add a message"
            isReply={false}
            onSubmit={async (value, hasError) => {
              if (!hasError) {
                await onPostComment({
                  replied_to_comment_id: repliedToComment ?? null,
                  content: value,
                });
                return true;
              }
              return false;
            }}
          />
        </React.Fragment>
      )}
      <div className="h-[1px] w-full bg-gray-300" />
      {threads?.map((comment, index) => {
        return (
          <ShowThread
            key={`thread-${comment.id}-${index}`}
            comment={comment}
            index={index}
            // isReply={false}
            // hideRepliesInitially={true}
            repliedToComment={repliedToComment}
            setRepliedToComment={(id) => setRepliedToComment(id)}
            onPostComment={async (value, hasError) => {
              if (!hasError) {
                await onPostComment({
                  replied_to_comment_id: repliedToComment ?? null,
                  content: value,
                });
                setRepliedToComment(null);
                return true;
              }
              return false;
            }}
          />
        );
      })}
      <div className="h-[1px] w-full bg-gray-300" />
      {!allowCreateComment && (
        <Text
          type="text"
          size="text-sm md:text-base"
          className={classNames(
            "font-semibold text-theme-black-color",
            "pb-10"
          )}
          content={"You can write comment once you complete this session"}
        />
      )}
      {allowCreateComment && comments.length > 3 && (
        <React.Fragment>
          <Text
            type="text"
            size="text-sm md:text-base"
            className="font-semibold text-theme-black-color"
            content="Ask your question"
          />
          <CommentInput
            userImage={userDetails.profile_picture?.signed_url ?? ""}
            buttonText="POST"
            placeholder="Ask your question here"
            textErrorMessage="Please add a message"
            isReply={false}
            onSubmit={async (value, hasError) => {
              if (!hasError) {
                await onPostComment({
                  replied_to_comment_id: repliedToComment ?? null,
                  content: value,
                });
                return true;
              }
              return false;
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

CommentsTab.propTypes = {
  allowCreateComment: PropTypes.any,
  comments: PropTypes.array,
  entity_id: PropTypes.any,
  entity_type: PropTypes.any,
  onPostComment: PropTypes.func,
};
export default CommentsTab;
