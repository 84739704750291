/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import classNames from "classnames";
import { CircleNotch } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import _ from "lodash";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import CheckBox from "./CheckBox";
import { useMemo } from "react";

const Table = ({
  columns = [],
  isLoading,
  data = [],
  isRowSelectable = false,
  MobileViewCard,
  emptyScreen,
  showPagination = false,
  disableCheckboxIndex = [],
  paginationProps = {
    perPage: 10,
  },
  preSelectedRow = [],
  onSelectChange,
  manualPagination = false,
  hasInfinteScroll = false,
  infiniteScrollProps = {
    onPageChange: () => {},
    hasMore: false,
  },
}) => {
  const { width } = useWindowSize();
  const isDesktop = width >= 728;
  const [currentPage, setCurrentPage] = useState(0);
  const observer = React.useRef();
  const lastPostElementRef = React.useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          infiniteScrollProps.hasMore &&
          !!infiniteScrollProps?.increasePage
        ) {
          infiniteScrollProps?.increasePage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, infiniteScrollProps.hasMore]
  );

  const memoData = useMemo(() => {
    setCurrentPage(0);
    return data;
  }, [data]);

  const totalDataCount = manualPagination
    ? paginationProps.total_count
    : memoData.length;
  const totalPages = manualPagination
    ? paginationProps.total_pages
    : Math.ceil(memoData.length / paginationProps.perPage);

  const paginatedData =
    showPagination && !manualPagination
      ? _.slice(
          memoData,
          currentPage * paginationProps.perPage,
          (currentPage + 1) * paginationProps.perPage
        )
      : memoData;

  if (isLoading && !hasInfinteScroll) {
    return (
      <CircleNotch className="mx-auto animate-spin" size={20} weight="fill" />
    );
  }
  if (_.isEmpty(memoData) && !isLoading && emptyScreen) return emptyScreen;
  if (!isDesktop && MobileViewCard) {
    return (
      <Formik
        enableReinitialize
        initialValues={{ select_row: preSelectedRow ?? {} }}
      >
        {({ values, ...formProps }) => (
          <Form className="">
            {memoData.map((item, i) => (
              <MobileViewCard
                key={i}
                index={i}
                data={item}
                formikProps={{ values, ...formProps }}
              />
            ))}
          </Form>
        )}
      </Formik>
    );
  }
  return (
    <Formik enableReinitialize initialValues={{ select_row: {} }}>
      {({ values, ...formProps }) => {
        return (
          <Form className="">
            <table className="w-full table-auto">
              <thead>
                <tr className={"bg-theme-black-color"}>
                  {isRowSelectable && (
                    <th className="py-3 max-w-[72px] text-xs font-medium text-white uppercase border-b rounded-tl bg-theme-black-color"></th>
                  )}
                  {columns.map((item, i) => {
                    return (
                      <th
                        key={`${item.label}-${i}`}
                        scope="col"
                        className={classNames(
                          "px-6 py-3 text-xs font-medium uppercase bg-theme-black-color border-b text-white",
                          item.headingClassname || "",
                          getBorder(i, columns.length, isRowSelectable),
                          item.align === "center"
                            ? "text-center"
                            : item.align === "right"
                            ? "text-right"
                            : "text-left"
                        )}
                      >
                        {item.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {_.isEmpty(paginatedData) && !isLoading && (
                  <tr className="border-b border-gray-200 text-theme-black-color">
                    <td
                      colSpan={columns.length}
                      className="px-6 py-6 text-sm text-center"
                    >
                      No data
                    </td>
                  </tr>
                )}
                {paginatedData.map((item, i) => {
                  return (
                    <tr
                      key={`id-${Math.random().toString(16).slice(2)}-${i}`}
                      className="border-b border-gray-200 text-theme-black-color"
                    >
                      {isRowSelectable && (
                        <td className="flex justify-center items-center h-full max-w-[72px] w-full pl-3 py-6">
                          <CheckBox
                            labelClassName="font-semibold"
                            name={`select_row.${
                              currentPage * paginationProps.perPage + i
                            }`}
                            errorMessage=""
                            errorColor=""
                            className=""
                            checked={
                              values.select_row[
                                currentPage * paginationProps.perPage + i
                              ]
                            }
                            value={
                              values.select_row[
                                currentPage * paginationProps.perPage + i
                              ]
                            }
                            disabled={disableCheckboxIndex.includes(
                              currentPage * paginationProps.perPage + i
                            )}
                            onChange={(event) => {
                              if (onSelectChange) {
                                formProps.setFieldValue(
                                  `select_row.${
                                    currentPage * paginationProps.perPage + i
                                  }`,
                                  event.target.checked
                                );
                                onSelectChange({
                                  event,
                                  rowIndexInTable: i,
                                  rowIndexInData:
                                    currentPage * paginationProps.perPage + i,
                                  rowValue: item,
                                  formikProps: { values, ...formProps },
                                });
                              } else {
                                formProps.setFieldValue(
                                  `select_row.${
                                    currentPage * paginationProps.perPage + i
                                  }`,
                                  event.target.checked
                                );
                              }
                            }}
                            type="checkbox"
                          />
                        </td>
                      )}
                      {columns.map((col, j) => {
                        if (col.renderCell)
                          return (
                            <td
                              key={`td-${col.name ?? col.id}-${
                                item.id ??
                                item.uid ??
                                "id-" + Math.random().toString(16).slice(2)
                              }-${i}`}
                              className={classNames(
                                "px-6 py-6 min-w-[150px] text-sm",
                                col.rowCellClassName,
                                col.align === "center"
                                  ? "text-center"
                                  : col.align === "right"
                                  ? "text-right"
                                  : "text-left"
                              )}
                            >
                              {col.renderCell({
                                rowValue: item,
                                column: col,
                                rowIndex:
                                  currentPage * paginationProps.perPage + i,
                                columnIndex: j,
                              })}
                            </td>
                          );
                        return (
                          <td
                            key={`td1-${
                              item.id ??
                              item.uid ??
                              "id-" + Math.random().toString(16).slice(2)
                            }-${j}`}
                            className={classNames(
                              "px-6 min-w-[150px] py-6 text-sm",
                              col.rowCellClassName,
                              col.align === "center"
                                ? "text-center"
                                : col.align === "right"
                                ? "text-right"
                                : "text-left"
                            )}
                          >
                            {item[col.name]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {hasInfinteScroll && <tr ref={lastPostElementRef} />}
                {hasInfinteScroll && isLoading && (
                  <tr>
                    <td colSpan={columns.length + (isRowSelectable ? 1 : 0)}>
                      <div className="h-10 flex items-center justify-center w-full">
                        <CircleNotch
                          className="animate-spin"
                          size={24}
                          weight="fill"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {showPagination && (
                <tfoot>
                  <tr>
                    <td colSpan={columns.length + (isRowSelectable ? 1 : 0)}>
                      <div className="flex justify-between w-full px-2 pt-4 pb-2">
                        <div className="block text-theme-black-300">
                          Showing{" "}
                          <span className="font-medium text-theme-black-color">
                            {totalDataCount === 0
                              ? 0
                              : currentPage * paginationProps.perPage + 1}
                          </span>{" "}
                          to{" "}
                          <span className="font-medium text-theme-black-color">
                            {(currentPage + 1) * paginationProps.perPage >
                            totalDataCount
                              ? totalDataCount
                              : (currentPage + 1) * paginationProps.perPage}
                          </span>{" "}
                          of{" "}
                          <span className="font-medium text-theme-black-color">
                            {totalDataCount ?? 0}
                          </span>{" "}
                          Entries
                        </div>
                        <div className="flex text-sm leading-4 bg-white border border-gray-200 rounded-md overflow-clip">
                          <div
                            onClick={
                              currentPage === 0
                                ? null
                                : () => setCurrentPage((prev) => prev - 1)
                            }
                            className={classNames(
                              "px-3 text-theme-black-color py-1.5 font-semibold",
                              currentPage === 0
                                ? "text-theme-black-300 bg-theme-black-color/10 cursor-not-allowed"
                                : "text-theme-black-color cursor-pointer"
                            )}
                          >
                            Previous
                          </div>
                          {currentPage > 0 && (
                            <div
                              onClick={() => setCurrentPage((p) => p - 1)}
                              className="px-3 cursor-pointer text-theme-black-300 py-1.5 border-l border-gray-200"
                            >
                              {currentPage}
                            </div>
                          )}
                          <div className="px-3 cursor-pointer font-semibold text-theme-orange bg-theme-orange/10 py-1.5 border border-y-0 border-gray-200">
                            {currentPage + 1}
                          </div>
                          {currentPage + 1 < totalPages && (
                            <div
                              onClick={() => setCurrentPage((p) => p + 1)}
                              className="px-3 cursor-pointer text-theme-black-300 py-1.5 border-r border-gray-200"
                            >
                              {currentPage + 2}
                            </div>
                          )}
                          <div
                            onClick={
                              currentPage + 1 === totalPages
                                ? null
                                : () => setCurrentPage((prev) => prev + 1)
                            }
                            className={classNames(
                              "px-3 text-theme-black-color py-1.5 font-semibold",
                              currentPage + 1 === totalPages
                                ? "text-theme-black-300 bg-theme-black-color/10 cursor-not-allowed"
                                : "text-theme-black-color cursor-pointer"
                            )}
                          >
                            Next
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </Form>
        );
      }}
    </Formik>
  );
};

const getBorder = (i, columnLength, isRowSelectable) => {
  if (i === 0 && !isRowSelectable) {
    return "rounded-tl";
  } else if (i === columnLength - 1) {
    return "rounded-tr";
  }
  return "";
};

Table.defaultProps = {
  columns: [],
  isLoading: false,
  data: [],
};

Table.propTypes = {
  MobileViewCard: PropTypes.element,
  columns: PropTypes.array,
  data: PropTypes.array,
  disableCheckboxIndex: PropTypes.array,
  emptyScreen: PropTypes.any,
  hasInfinteScroll: PropTypes.bool,
  infiniteScrollProps: PropTypes.shape({
    hasMore: PropTypes.bool,
    onPageChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isRowSelectable: PropTypes.bool,
  manualPagination: PropTypes.bool,
  onSelectChange: PropTypes.func,
  paginationProps: PropTypes.object,
  preSelectedRow: PropTypes.array,
  showPagination: PropTypes.bool,
};
export default Table;
