/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import EventThankYouImg from "../../assets/svgs/event_thankyou2.svg";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import ImageComponent from "../../stories/ImageComponent";
import AppSkeleton from "../AppSkeleton";
import { useWindowSize } from "../../hooks/useWindowSize";
import _ from "lodash";
import LoadingModal from "../Modals/LoadingModal";
import moment from "moment";
import { confettiAnimation } from "../../util/confettiAnimation";

const EventRegistrationThankyouScreen = (props) => {
  const navigate = useNavigate();
  const confettiCanvas = useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const [propData, setPropData] = useState({});

  useEffect(() => {
    if (_.isEmpty(props?.location?.state)) {
      navigate(-1);
      return;
    } else {
      setPropData(
        props?.location?.state ?? {
          eventDetails: {},
          formData: {},
        }
      );
    }
  }, []);

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  const ShowVenue = () => {
    if (!_.isEmpty(propData?.eventDetails?.ticketing)) {
      const venue =
        propData?.eventDetails?.ticketing?.filter(
          (e) => e.id === propData?.formData?.venue_id
        )[0]?.venue ?? {};
      return (
        <ShowDetails
          label={
            propData?.eventDetails?.event_type === "regional_event"
              ? "SUMMIT VENUE"
              : "EVENT VENUE"
          }
          value={`${venue?.name}, ${venue?.address?.line_1}, ${venue?.address?.city} ${venue?.address?.state} - ${venue?.address?.pin}`}
        />
      );
    }
    if (!_.isEmpty(propData?.eventDetails?.venue)) {
      const venue = propData?.eventDetails?.venue?.name;
      <ShowDetails
        label={
          propData?.eventDetails?.event_type === "regional_event"
            ? "SUMMIT VENUE"
            : "EVENT VENUE"
        }
        value={`${venue?.name}, ${venue?.address?.line_1}, ${venue?.address?.city} ${venue?.address?.state} - ${venue?.address?.pin}`}
      />;
    }
    return null;
  };

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      {_.isEmpty(props?.location?.state) && (
        <LoadingModal open={_.isEmpty(props?.location?.state)} />
      )}
      <div className="flex justify-center py-6 p x-4 lg:px-16 h-fit">
        <div className="relative flex flex-col items-center w-full max-w-5xl px-6 pb-10 bg-white lg:py-20">
          <div className="flex flex-col items-center w-full max-w-3xl">
            <ImageComponent
              src={EventThankYouImg}
              className="mt-8 mb-10 !bg-white"
            />
            <div className="flex justify-center w-full max-w-xl text-2xl font-bold leading-none tracking-tight text-center lg:text-4xl text-theme-black-color">
              Thank you! You&apos;re all Set!
            </div>
            {/* <div className="flex w-full text-lg font-semibold tracking-tight text-theme-black-color">
              {propData?.eventDetails?.title ?? ""}
            </div> */}
            <div className="flex mt-3 text-sm tracking-tight text-center text-theme-black-300">
              {propData?.eventDetails?.description ?? ""}
            </div>
            <div className="grid w-full grid-cols-1 gap-4 mt-10 lg:gap-8 lg:grid-cols-3">
              <ShowDetails
                label="ATTENDEE NAME"
                value={`${propData?.formData?.firstName} ${propData?.formData?.lastName}`}
              />
              <ShowDetails
                label={
                  propData?.eventDetails?.event_type === "regional_event"
                    ? "SUMMIT NAME"
                    : "EVENT NAME"
                }
                value={propData?.eventDetails?.title}
              />
              <ShowVenue />
              <ShowDetails
                label={
                  propData?.eventDetails?.event_type === "regional_event"
                    ? "SUMMIT DATE"
                    : "EVENT DATE & TIME"
                }
                value={
                  propData?.eventDetails?.event_type === "regional_event"
                    ? moment(
                        propData?.eventDetails?.ticketing?.filter(
                          (e) => e.id === propData?.formData?.venue_id
                        )[0]?.date,
                        "YYYY-MM-DD"
                      ).format("MMMM DD, YYYY")
                    : propData?.eventDetails?.start_from
                }
              />

              <div className="flex flex-col items-center justify-center w-full mx-auto col-span-full">
                <div className="w-full lg:w-1/2">
                  <Button
                    buttonStyle="red"
                    size="xl"
                    width="100%"
                    onClick={() => {
                      navigate("/", { replace: true });
                    }}
                  >
                    {propData?.isNewUser
                      ? "CONTINUE TO LOGIN"
                      : "CONTINUE TO DASHBOARD"}
                  </Button>
                </div>
                {/* {propData?.eventDetails?.event_type === "regional_event" && (
                  <span className="w-full mt-4 text-sm leading-5 text-center text-theme-black-300 -tracking-tight">
                    Access Free and Member Resources
                  </span>
                )} */}
                {propData?.isNewUser && (
                  <span className="w-full mt-4 text-sm leading-5 text-center text-theme-black-300 -tracking-tight">
                    Please check your email id{" "}
                    <span className="font-bold underline underline-offset-4 text-theme-red">
                      {propData?.formData?.email}
                    </span>
                    . We have sent an email to reset your password. Complete
                    your sign-up process to access your account.
                  </span>
                )}
              </div>
              <div className="mt-10 text-center col-span-full text-theme-gray-500">
                If you have any issues{" "}
                <a
                  href="mailto:contact@networkfp.com"
                  className="text-blue-600 hover:underline"
                >
                  contact us.
                </a>
              </div>
            </div>
          </div>
          <canvas
            ref={confettiCanvas}
            className="absolute z-50 w-full h-full"
          />
        </div>
      </div>
    </AppSkeleton>
  );
};

const ShowDetails = ({ label = "", value = "" }) => {
  return (
    <div className="flex flex-col gap-1.5 w-full">
      <div className="text-sm font-medium leading-4 text-left break-words text-theme-gray-600">
        {label}
      </div>
      <div className="text-lg font-medium leading-6 tracking-tight text-left break-words lg:text-xl text-theme-black-color">
        {value !== undefined && value !== null && value !== "" ? value : "-"}
      </div>
    </div>
  );
};

EventRegistrationThankyouScreen.propTypes = {
  eventDetails: PropTypes.shape({
    description: PropTypes.string,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whatNextContent: PropTypes.array,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
};

export default EventRegistrationThankyouScreen;
