/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Validations } from "../../../constants/validations";
import { AuthService } from "../../../services/AuthService";
import Button from "../../../stories/Button";
import FormBuilder from "../../../stories/FormBuilder";
import _ from "lodash";
import { PublicProfileService } from "../../../services/PublicProfileService";

const CompanyDetails = ({
  stepProps,
  publicProfileData,
  corporateProfileData,
  firmId,
  setFirmId,
}) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    website: "",
    address_attributes: {
      line_1: "",
      pin: "",
      city_id: "",
      city: "",
      state_id: "",
    },
    no_of_members: "",
    short_bio: "",
    public_name: "",
    public_email: "",
    public_mobile_number: "",
    whatsapp_number: "",
  });
  const submitRef = React.useRef();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState(
    !_.isEmpty(publicProfileData.firm)
      ? [
          {
            id: publicProfileData.firm?.address?.city_id,
            name: publicProfileData.firm?.address?.city ?? "",
          },
        ]
      : []
  );

  const fetchStates = async () => {
    const response1 = await AuthService.getCities("states");
    setStates(response1.locations);
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const textInputDetails = [
    {
      label: "Full Company Name",
      name: "name",
      placeholder: "Company Name",
      className: "lg:min-w-186",
      type: "text",
      isRequired: true,
    },
    {
      label: "Firm Website",
      name: "website",
      placeholder: "www.xyz.com",
      className: "lg:min-w-186",
      type: "text",
      isRequired: false,
    },
    {
      label: "Address Line",
      name: "address_attributes.line_1",
      placeholder: "12/14, Vaju Kotak Marg, Ballard Estate",
      className: "lg:min-w-186 col-span-full",
      type: "text",
      isRequired: true,
    },
    {
      label: "State",
      placeholder: "Select state",
      type: "dropdown",
      name: "address_attributes.state_id",
      displayCurrentOption: true,
      options: states,
      displayKey: "name",
      isRequired: true,
      idKey: "id",
      className: "lg:min-w-186",
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("address_attributes.state_id", value["id"]);
        formProps.setFieldValue("address_attributes.state", value["name"]);
        formProps.setFieldValue("address_attributes.city_id", "");
        const response2 = await AuthService.getCities("cities", value["id"]);
        setCities(response2.locations);
      },
    },
    {
      label: "City",
      placeholder: "Select city",
      type: "dropdown",
      name: "address_attributes.city_id",
      displayCurrentOption: true,
      options: cities,
      displayKey: "name",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: true,
    },
    {
      label: "Pincode",
      name: "address_attributes.pin",
      placeholder: "400001",
      className: "lg:min-w-186",
      maxLength: 6,
      type: "number",
      // onValueChange: async ({ event, formikProps }) => {
      //   formikProps.setFieldValue("address_attributes.pin", event.target.value);
      //   if (event.target.value.length === 6) {
      //     try {
      //       const response = await AuthService.fetchPincodeDetails(
      //         event.target.value
      //       );
      //       formikProps.setFieldValue("address_attributes.city", response.city);
      //       formikProps.setFieldValue(
      //         "address_attributes.city_id",
      //         response.city_id
      //       );
      //       formikProps.setFieldValue(
      //         "address_attributes.state",
      //         response.state
      //       );
      //     } catch (e) {
      //       CustomToast.error(`${event.target.value} is invalid zip code`);
      //     }
      //   }
      // },
    },
    {
      label: "No. of Team Members in Company",
      name: "no_of_members",
      placeholder: "3",
      className: "lg:min-w-186",
      type: "number",
    },
    {
      label: "Company Bio",
      name: "short_bio",
      placeholder: "Write down here . . .",
      className: "col-span-full",
      rows: 4,
      isRequired: true,
      maxLength: 1000,
      type: "textarea",
    },
    {
      label: "contact-info",
      name: "contact-info",
      isViewOnly: true,
      renderContent: () => (
        <div className="flex flex-col col-span-full">
          <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
            Contact Info
          </div>
          <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
            Update your contact information here
          </div>
        </div>
      ),
    },
    {
      label: "Corporate Account Contact Person Name",
      name: "public_name",
      placeholder: "Email Address",
      className: "min-w-186",
    },
    {
      label: "Email Address",
      name: "public_email",
      placeholder: "Email Address",
      className: "min-w-186",
    },
    {
      label: "Mobile No. (10 digits)",
      name: "public_mobile_number",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186",
      type: "number",
      isRequired: false,
    },
    {
      label: "Whatsapp No. (10 digits)",
      name: "whatsapp_number",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186",
      isRequired: false,
    },
  ];

  useEffect(() => {
    const firmData = corporateProfileData.firm ?? publicProfileData.firm ?? {};
    if (!_.isEmpty(firmData)) {
      setInitialValues({
        name: firmData.name ?? "",
        website: firmData.website ?? "",
        address_attributes: {
          line_1: firmData.address?.line_1 ?? "",
          pin: firmData.address?.pin ?? "",
          city_id: firmData.address?.city_id,
          city: firmData.address?.city ?? "",
          state_id: firmData.address?.state_id ?? "",
        },
        no_of_members: firmData.no_of_members ?? "",
        short_bio: firmData.short_bio ?? "",
        public_name: firmData.public_name ?? "",
        public_email: firmData.public_email ?? "",
        public_mobile_number: firmData.public_mobile_number ?? "",
        whatsapp_number: firmData.whatsapp_number ?? "",
      });
      setCities([
        {
          id: publicProfileData.firm?.address?.city_id,
          name: publicProfileData.firm?.address?.city ?? "",
        },
      ]);
    }
  }, [publicProfileData]);

  const onFormSubmit = async (value) => {
    try {
      const postData = { ...value };
      postData["firm_admin_id"] = publicProfileData?.id;
      postData["id"] = publicProfileData?.firm?.id;
      postData["address_attributes"] = {
        line_1: postData["address_attributes"]["line_1"],
        pin: postData["address_attributes"]["pin"],
        city_id: postData["address_attributes"]["city_id"],
      };
      const response = await PublicProfileService.updateCorporateInfo({
        data: postData,
        id: publicProfileData?.firm?.id,
      });
      setFirmId(response.firm_id);
      stepProps.handleNext();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classNames("flex")}>
      <div
        className={classNames(
          "max-w-3xl lg:min-w-[764px] w-full flex flex-col p-3 bg-white rounded-sm shadow-sm lg:p-4"
        )}
      >
        <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
          Company Information
        </div>
        <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
          Update your company information here
        </div>
        {initialValues && (
          <FormBuilder
            validationSchema={
              Validations.corporate.companyDetailsValidationSchema
            }
            enableReinitialize
            inputFieldsDetails={textInputDetails}
            initialValues={initialValues}
            submitButtonRef={submitRef}
            onFormSubmit={onFormSubmit}
            formClassName="grid w-full grid-cols-2 gap-x-3 gap-y-3 mt-3 lg:mt-5 lg:gap-y-5 lg:gap-x-6 items-end"
          />
        )}
        <div className="grid grid-cols-1 mt-3 lg:mt-6 lg:grid-cols-2 gap-y-3 lg:gap-x-6">
          <span></span>
          <Button
            buttonStyle="primary"
            height="40px"
            className="order-1 lg:order-2"
            onClick={async () => {
              try {
                await submitRef.current.click();
              } catch (e) {
                console.log("error while subitting form", e);
              }
            }}
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
};

CompanyDetails.propTypes = {
  corporateProfileData: PropTypes.any,
  firmData: PropTypes.any,
  firmId: PropTypes.any,
  publicProfileData: PropTypes.shape({
    firm: PropTypes.object,
    id: PropTypes.any,
  }),
  setFirmId: PropTypes.func,
  stepProps: PropTypes.shape({
    handleNext: PropTypes.func,
  }),
};

export default CompanyDetails;
