/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "@reach/router";
import { Formik, Form } from "formik";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import OnboardingHeader from "../../stories/OnboardingHeader";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import CustomLink from "../../stories/Link";
import { AuthService } from "../../services/AuthService";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { User } from "../../store";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";
import { Validations } from "../../constants/validations";
import _ from "lodash";
import { getTokenFromFirebase } from "../../firebase";
import ShowFormError from "../../stories/ShowFormError";
import OTPField from "../../stories/OTPField";
import mixpanel from "mixpanel-browser";
import moment from "moment";

const OTPLoginScreen = (props) => {
  useDocumentTitle("Login");
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState(props.location?.state?.email ?? "");
  const { updateUserData } = useProfile();
  const redirectTo =
    !_.isEmpty(props.location?.state?.pathname) &&
    props.location?.state.pathname !== "/login"
      ? props.location.state.pathname + props.location.state.search
      : "/?guide_user=true";

  const handleNotificationToken = async () => {
    try {
      getTokenFromFirebase();
      updateUserData();
    } catch (e) {
      console.log(e);
    }
  };

  const sendOtp = async (values) => {
    try {
      const key = values.email.includes("@") ? "email" : "phone_number";
      const result = await AuthService.sendOTP({
        [key]: values.email,
        type: key,
      });
      setEmail(values.email);
      setOtpSent(true);
      CustomToast.success(result.message);
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };
  const submitOtp = async (values, { setFieldError }) => {
    try {
      const otp = values.otp.join("");
      if (_.isEmpty(otp)) {
        CustomToast.error("Please enter a otp.");
        setFieldError("otp", "Please enter a otp.");
        return;
      }
      const key = email.includes("@") ? "email" : "phone_number";
      const result = await AuthService.login({
        [key]: email,
        code: otp,
        type: key,
        login_type: "otp",
      });
      if (result.token) {
        CustomToast.success(result.message);
        User.setToken(result.token);
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: email ?? null,
        });
        updateUserData(true).then((response) => {
          mixpanel.people.set({
            $email: response["email"],
            $name: `${response["first_name"]} ${response["last_name"]}`,
            "User ID": response["id"],
            "Phone number": response["phone_number"],
            "Profile Complete Percentage":
              response["profile_complete_percentage"],
            "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
          });
          mixpanel.identify(response["email"]);
          mixpanel.track("User Login", {
            distinct_id: response["email"],
            id: email,
            "Login Type": "OTP",
          });
        });
        await handleNotificationToken();
        setTimeout(() => navigate(redirectTo ?? "/?guide_user=true"), 1000);
      }
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0]);
      if (e.error_code === "signup_not_completed") {
        navigate("/complete-signup", {
          state: {
            token: e.payload?.token,
            email: email,
            user: e.payload?.user,
          },
        });
      } else if (e.error_code === "email_not_verified") {
        navigate("resend-email", { state: { email } });
      }
    }
  };

  const Form1 = () => {
    return (
      <div className="flex flex-col">
        <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
          Login
        </div>
        <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
          Welcome! Enter your registered email ID or mobile number and we will
          send an OTP verification to you.
        </div>
        <Formik
          initialValues={{
            email: email ?? "",
          }}
          validationSchema={() =>
            Validations.onBoarding.otpLoginValidationSchema
          }
          onSubmit={sendOtp}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <div className="w-full mt-8 md:mt-12">
                <AuthTextInput
                  name="email"
                  id="email"
                  type="text"
                  label="Email / Mobile number"
                  autoComplete="email"
                />
              </div>
              <Button
                type="submit"
                buttonStyle="primary"
                height="60px"
                width="100%"
                className="mt-8"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                REQUEST OTP
              </Button>
              <div className="flex items-center justify-start w-full mt-3 text-sm leading-5">
                New to NetworkFP? &nbsp;
                <CustomLink
                  href="/signup"
                  textColor="text-theme-red"
                  activeTextColor="text-theme-red"
                  fontSize="md"
                  showUnderLine={true}
                  className="leading-5"
                >
                  Sign Up for Free
                </CustomLink>
              </div>
              <div className="flex items-center gap-2 mt-10 mb-4">
                <div className="w-full border-t border-theme-gray-100" />
                <div className="italic font-light text-theme-gray-600">or</div>
                <div className="w-full border-t border-theme-gray-100" />
              </div>
              <div className="flex justify-center">
                <CustomLink
                  href="/login/email"
                  state={{
                    email: values["email"],
                    ...(props.location?.state ?? {}),
                  }}
                  textColor="text-theme-red"
                  activeTextColor="text-theme-red"
                  showUnderLine={false}
                  underLineOnlyOnHover={false}
                  className="leading-none"
                >
                  Login with Password
                </CustomLink>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          {!otpSent ? (
            <Form1 />
          ) : (
            <Form2
              email={email}
              goBack={() => setOtpSent(false)}
              onSubmit={submitOtp}
              sendOtp={sendOtp}
            />
          )}
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

const Form2 = ({ email, goBack, onSubmit, sendOtp }) => {
  let masked = "";
  if (email.includes("@")) {
    masked = `*****${email
      ?.split("@")[0]
      ?.substring(email?.split("@")[0]?.length - 3)}@${email.split("@")[1]}`;
  } else {
    masked = `*******${email.substring(6, email?.length)}`;
  }

  const [seconds, setSeconds] = useState(0);
  const timeOutCallback = useCallback(
    () => setSeconds((currTimer) => currTimer - 1),
    []
  );
  const resetTimer = function () {
    if (!seconds) {
      setSeconds(120);
    }
  };
  useEffect(() => {
    seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [seconds, timeOutCallback]);

  useEffect(() => {
    resetTimer();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
        OTP Verification
      </div>
      <div className="block mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300">
        Enter OTP sent to &nbsp;
        <b>{masked}</b>.
      </div>
      <div className="flex justify-start">
        <CustomLink
          textColor="text-theme-red"
          activeTextColor="text-theme-red"
          fontSize="md"
          showUnderLine={false}
          underLineOnlyOnHover={false}
          className="leading-5"
          onClick={() => {
            resetTimer();
            goBack();
          }}
        >
          Edit
        </CustomLink>
      </div>
      <Formik
        initialValues={{ otp: Array(6).fill("") }}
        validationSchema={Validations.onBoarding.otpVerification}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col items-start w-full mt-4">
            <div className="flex flex-row items-center justify-start w-full gap-2">
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={0} />
              </div>
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={1} />
              </div>
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={2} />
              </div>
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={3} />
              </div>
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={4} />
              </div>
              <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                <OTPField index={5} />
              </div>
            </div>
            <ShowFormError name="otp" />
            <div className="flex justify-end w-full mt-3">
              <Button
                disabled={seconds > 0}
                buttonStyle="transparent-textonly-primary"
                onClick={(e) => {
                  resetTimer();
                  sendOtp({ email: email });
                }}
                type="button"
              >
                {seconds === 0 ? (
                  "Resend OTP"
                ) : (
                  <>
                    Resend OTP in{" "}
                    {_.toInteger(seconds / 60) < 10
                      ? `0${_.toInteger(seconds / 60)}`
                      : _.toInteger(seconds / 60)}{" "}
                    : {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                  </>
                )}
              </Button>
            </div>
            <Button
              type="submit"
              buttonStyle="primary"
              height="60px"
              width="100%"
              className="mt-6"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              VERIFY OTP
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OTPLoginScreen;
