/* eslint-disable new-cap */
import PropTypes from "prop-types";
import React from "react";
import Table from "../../stories/Table";
import { MyAceQuizColumn } from "../../Constants";
import AceQuizAccordian from "../../stories/AceQuizAccordian";
import classNames from "classnames";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";

const AceDetailBox = ({ label, value, isDark = false }) => {
  return (
    <div
      className={classNames(
        "p-2.5 flex flex-col justify-between items-start rounded",
        isDark ? "bg-slate-200" : "bg-white"
      )}
    >
      <div className="text-xs font-semibold text-theme-gray-600">{label}</div>
      <div className="text-3xl font-bold text-theme-black-color">{value}</div>
    </div>
  );
};

AceDetailBox.propTypes = {
  isDark: PropTypes.bool,
  label: PropTypes.any,
  value: PropTypes.any,
};

const MyAcePoints = ({
  quizzes = [],
  aceData = {},
  showNFPAceDetails = false,
}) => {
  return (
    <div className="flex flex-col">
      <h3 className="mb-4 font-medium text-lg">Your ACE Points Summary</h3>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-5 lg:gap-6">
        <AceDetailBox
          label="ACE Points Required for QPFPs"
          value={aceData?.ace_points_required ?? 0}
          isDark={true}
        />
        <AceDetailBox
          label="ACE Points Earned"
          value={aceData?.ace_points_earned ?? 0}
          isDark={true}
        />
        <AceDetailBox
          label="Your ACE Points"
          value={aceData?.ace_points_remaining ?? 0}
          isDark={true}
        />
        <AceDetailBox
          label="ACE Opportunities"
          value={aceData?.ace_opportunities ?? 0}
        />
      </div>
      <h3 className="mt-10 mb-4 font-medium text-lg">ACE Session Quizzes</h3>
      <Table
        isLoading={false}
        data={quizzes}
        columns={MyAceQuizColumn(showNFPAceDetails)}
        showPagination={true}
        paginationProps={{
          perPage: 10,
        }}
        emptyScreen={
          <EmptyState
            heading="No Quizzes Yet!"
            subHeading=""
            image={
              <img
                alt="emptyStatePlaceHolder"
                className="mb-4 max-h-44"
                src={EmptyStatePlaceHolder}
              />
            }
            className="my-10"
          />
        }
        MobileViewCard={({ data, col, i }) => (
          <AceQuizAccordian item={data} key={i} index={i} />
        )}
      />
    </div>
  );
};

MyAcePoints.propTypes = {
  aceData: PropTypes.object,
  quizzes: PropTypes.array,
  showNFPAceDetails: PropTypes.bool,
};

export default MyAcePoints;
