/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import {
  CaretDown,
  CaretUp,
  Check,
  MinusCircle,
  PlusCircle,
} from "@phosphor-icons/react";
import React, { Fragment, useEffect, useState } from "react";
import promember_logo from "../../assets/svgs/promember_logo.svg";
import qpfp_logo from "../../assets/svgs/qpfp_logo.svg";
import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import Tooltip from "../../stories/Tooltip";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import { useProfile } from "../../context/userProvider";
import { User } from "../../store";

const Pricing = ({ content = [], programs = {}, description = {} }) => {
  const navigate = useNavigate();
  const [badgeDetails, setBadgeDetails] = useState({});
  const { userDetails } = useProfile();
  const [showQPFPbenefits, setShowQPFPBenefits] = useState(false);
  const star_text = "plus GST";

  const qpfpBenefits = [
    "100+ Hours of Training",
    "50+ ProTools / Calculators",
    "50+ Tests & Exams",
    "Proctored Final Exam",
    "Digital Courseware / Handouts",
    "Coaching Calls with Coaches",
  ];

  const data = content ?? [];
  useEffect(() => {
    document.addEventListener("scroll", handleShadow);
    decideLevel();
    return () => {
      document.removeEventListener("scroll", handleShadow);
    };
  }, []);

  const decideLevel = () => {
    if (!User.getToken()) {
      setBadgeDetails({});
      return;
    }
    const status_arr = [
      "not_registered",
      "incomplete_registration",
      "not_paid",
      "overdue",
    ]; // expires_soon // paid // approved // provisional

    const community = !status_arr.includes(
      userDetails.provisonal_member_signup_status
    );
    const pro_member = !status_arr.includes(userDetails.pm_signup_status);
    const qpfp = !status_arr.includes(userDetails.qpfp_signup_status);

    if (!community && !pro_member && !qpfp) {
      setBadgeDetails({
        current_level: "basic",
        recommended: "pro_membership",
      });
      return;
    }
    if (community && pro_member && qpfp) {
      setBadgeDetails({ current_level: "pro_membership" });
      return;
    }

    // community => recommeded- qpfp, current- community
    // pro => recommeded- qpfp, current- pro
    // community & pro => recommeded- qpfp, current- pro

    // qpfp => recommeded- pro, current- qpfp
    // community & qpfp => recommeded- pro, current- qpfp

    // pro & qpfp => current- pro

    if ((community || pro_member) && !qpfp) {
      setBadgeDetails({
        recommended: "qpfp",
        current_level: pro_member ? "pro_membership" : "community",
      });
      return;
    }
    if (pro_member && qpfp && !community) {
      setBadgeDetails({
        current_level: "pro_membership",
      });
      return;
    }
    if ((qpfp || community) && !pro_member) {
      setBadgeDetails({
        recommended: "pro_membership",
        current_level: qpfp ? "qpfp" : "community",
      });
      return;
    }
  };

  const handleShadow = () => {
    const ele = document.querySelector(
      "table#pricing-table > thead > tr.sticky"
    );
    const rect = ele.getBoundingClientRect();
    if (rect.y === 0) ele.classList.add("shadow-md");
    else ele.classList.remove("shadow-md");
  };

  const redirectToRegister = (key) => {
    let course_id;
    let href = "";
    switch (key) {
      case "basic":
        href = "/signup";
        break;
      case "community":
        course_id = _.head(programs.provisional_member_courses ?? []).id;
        href = `/get-started?ref=community_member__${course_id}`;
        break;
      case "qpfp":
        course_id = _.head(programs.qpfp_courses ?? []).id;
        href = `/get-started?ref=qpfp__${course_id}`;
        break;
      case "pro_membership":
        course_id = _.head(programs.pro_member_courses ?? []).id;
        href = `/get-started?ref=pro_member__${course_id}`;
        break;
      default:
        break;
    }
    openLinkInNewTab(`https://learn.networkfp.com/${href}`);
  };

  const redirectToKnowMore = (key) => {
    openLinkInNewTab(
      `http://www.networkfp.com/${key === "qpfp" ? "qpfp" : "promember"}`
    );
  };

  const openLinkInNewTab = (href) => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = href;
    a.target = "_blank";
    a.click();
  };

  const getButtons = (key) => {
    const isLoggedIn = !!User.getToken();
    const status_arr = [
      "not_registered",
      "incomplete_registration",
      "not_paid",
      "overdue",
    ];
    if (key === "basic" && isLoggedIn) return <div className="h-4 lg:hidden" />;
    if (
      isLoggedIn &&
      ((key === "community" &&
        !status_arr.includes(userDetails.provisonal_member_signup_status)) ||
        (key === "qpfp" &&
          !status_arr.includes(userDetails.qpfp_signup_status)) ||
        (key === "pro_membership" &&
          !status_arr.includes(userDetails.pm_signup_status)))
    )
      return (
        <React.Fragment>
          <div className="flex items-center justify-center px-5 mx-auto mt-8 text-base capitalize bg-white w-fit h-11 text-theme-orange-400">
            <Check
              className="w-5 mr-2 h-5 min-w-[20px] text-theme-orange-400"
              weight="bold"
            />{" "}
            Already registered
          </div>
          {["community", "basic"].includes(key) ? (
            <div className="lg:h-11"></div>
          ) : (
            <div
              className="flex items-center justify-center px-5 mx-auto mt-1 text-base capitalize bg-white cursor-pointer hover:underline w-fit underline-offset-1 h-11 text-theme-orange-400"
              onClick={() => redirectToKnowMore(key)}
            >
              know more
            </div>
          )}
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div
          className="flex items-center justify-center w-full mx-auto mt-8 text-base font-bold text-white uppercase rounded cursor-pointer h-11 lg:w-56 bg-theme-orange-400"
          onClick={() => redirectToRegister(key)}
        >
          register
        </div>
        {["community", "basic"].includes(key) ? (
          <div className="h-4 mt-1 lg:h-11"></div>
        ) : (
          <div
            className="flex items-center justify-center px-5 mx-auto mt-1 text-base capitalize bg-white cursor-pointer hover:underline w-fit underline-offset-1 h-11 text-theme-orange-400"
            onClick={() => redirectToKnowMore(key)}
          >
            know more
          </div>
        )}
      </React.Fragment>
    );
  };

  const getBadge = (key) => {
    if (badgeDetails["recommended"] === key)
      return (
        <div className="w-fit lg:mx-auto mt-3 bg-blue-900 text-xs py-0.5 px-3 rounded-full text-white">
          Recommended
        </div>
      );
    if (badgeDetails["current_level"] === key)
      return (
        <div className="w-fit lg:mx-auto mt-3 bg-emerald-500 text-xs py-0.5 px-3 rounded-full text-white">
          You Are Here
        </div>
      );
    return <div className="h-5 mt-3" />;
  };

  return (
    <div className="flex justify-center p-5 lg:px-28 lg:pb-14 bg-theme-gray-200">
      <table
        id="pricing-table"
        className="hidden w-full -mt-64 bg-white rounded lg:table overflow-clip"
      >
        <thead>
          <tr className="sticky top-0 text-base font-bold bg-white text-theme-black-color">
            <th className="py-6"></th>
            <th className="py-6 align-bottom">
              Free Account
              <div className="text-sm mt-1.5 font-light">
                {description["basic"]}
              </div>
              {getBadge("basic")}
            </th>
            <th className="py-6 align-bottom">
              Community Membership
              <div className="text-sm mt-1.5 font-light">
                {description["community"]}
              </div>
              {getBadge("community")}
            </th>
            <th className="py-6">
              <img
                src={qpfp_logo}
                className="w-auto h-10 m-auto"
                alt="qpfp course"
              />
              QPFP Certification
              <div className="text-sm mt-1.5 font-light">
                {description["qpfp"]}
              </div>
              {getBadge("qpfp")}
            </th>
            <th className="py-6">
              <img
                src={promember_logo}
                className="w-auto h-10 m-auto"
                alt="qpfp course"
              />
              Pro-Member
              <div className="text-sm mt-1.5 font-light">
                {description["pro_membership"]}
              </div>
              {getBadge("pro_membership")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((section) => (
            <React.Fragment key={section.heading}>
              <tr>
                <td
                  colSpan={5}
                  className="text-black font-semibold text-base bg-theme-orange-400/20 py-3.5 pl-6"
                >
                  {section.heading}
                </td>
              </tr>
              {section.data.map((row, r_index) => (
                <tr key={row.label}>
                  <td
                    className={classNames(
                      "pl-6 py-3.5 flex items-start text-base font-semibold leading-4 text-neutral-800",
                      r_index === 0
                        ? "pt-8"
                        : r_index === section?.data?.length - 1
                        ? "!pb-8"
                        : ""
                    )}
                  >
                    {row.label}{" "}
                    {row.tooltip && <Tooltip infoContent={row.tooltip} />}
                  </td>
                  {[row.basic, row.community, row.qpfp, row.pro_membership].map(
                    (cellData, cellIndex) => {
                      if (row.type === "checkbox")
                        return (
                          <td
                            key={`row.label-${cellIndex}`}
                            className={classNames(
                              "text-base py-3.5 text-center text-gray-500",
                              r_index === 0
                                ? "pt-8"
                                : r_index === section?.data?.length - 1
                                ? "!pb-8"
                                : ""
                            )}
                          >
                            {cellData ? (
                              <Check
                                weight="fill"
                                className="rounded-full mx-auto w-5 h-5 text-green-600 bg-green-400/30 px-0.5"
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        );
                      return (
                        <td
                          className={classNames(
                            "text-base py-3.5 text-center text-gray-500",
                            r_index === 0
                              ? "pt-8"
                              : r_index === section?.data?.length - 1
                              ? "!pb-8"
                              : ""
                          )}
                          key={`row.label-${cellIndex}`}
                        >
                          {cellData}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
          <tr>
            <td
              colSpan={5}
              className="text-black  font-semibold text-base bg-theme-orange-400/20 py-3.5 pl-6"
            >
              {showQPFPbenefits ? (
                <MinusCircle
                  size={24}
                  className="inline-block mr-2.5 cursor-pointer text-theme-orange-400"
                  weight="fill"
                  onClick={() => setShowQPFPBenefits(false)}
                />
              ) : (
                <PlusCircle
                  size={24}
                  className="inline-block mr-2.5 cursor-pointer text-theme-orange-400"
                  weight="fill"
                  onClick={() => setShowQPFPBenefits(true)}
                />
              )}
              QPFP Program Benefits
            </td>
          </tr>
          {showQPFPbenefits &&
            qpfpBenefits.map((benefit, b_index) => (
              <tr key={b_index}>
                <td
                  className={classNames(
                    "pl-6 py-3.5 flex items-start text-base font-semibold leading-4 text-neutral-800",
                    b_index === 0
                      ? "pt-8"
                      : b_index === qpfpBenefits?.length - 1
                      ? "!pb-8"
                      : ""
                  )}
                >
                  {benefit}
                </td>
                {[false, false, true, false].map((val, vIndex) => (
                  <td
                    key={`row.label-${vIndex}`}
                    className={classNames(
                      "text-base py-3.5 text-center text-gray-500",
                      b_index === 0
                        ? "pt-8"
                        : b_index === qpfpBenefits?.length - 1
                        ? "!pb-8"
                        : ""
                    )}
                  >
                    {val ? (
                      <Check
                        weight="fill"
                        className="rounded-full mx-auto w-5 h-5 text-green-600 bg-green-400/30 px-0.5"
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                ))}
              </tr>
            ))}
          <tr>
            <td className="text-black font-semibold text-base bg-white py-3.5 pl-6"></td>
            <td className="text-black font-semibold text-base bg-white py-3.5 pl-6">
              {getButtons("basic")}
            </td>
            <td className="text-black font-semibold text-base bg-white py-3.5 pl-6">
              {getButtons("community")}
            </td>
            <td className="text-black font-semibold text-base bg-white py-3.5 pl-6">
              {getButtons("qpfp")}
            </td>
            <td className="text-black font-semibold text-base bg-white py-3.5 pl-6">
              {getButtons("pro_membership")}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="pb-3 pl-6 italic">
              *{star_text}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex flex-col w-full gap-5 lg:hidden">
        {["basic", "community", "qpfp", "pro_membership"].map((key) => (
          <div
            key={key}
            className="w-full pt-4 bg-white border rounded-lg border-black/10"
          >
            <Disclosure defaultOpen={false}>
              <Disclosure.Button className="py-2" as={Fragment}>
                {({ open }) => (
                  <Fragment>
                    <div className="flex flex-col items-start px-4">
                      {getBadge(key)}
                      <div className="text-xl font-semibold">
                        {key === "basic"
                          ? "Free account"
                          : key === "community"
                          ? "Community Membership"
                          : key === "qpfp"
                          ? "QPFP Certification"
                          : "Pro-Member"}
                      </div>
                      <div className="text-sm mt-1.5 font-light">
                        {description[key]}
                      </div>
                    </div>
                    {!open && (
                      <div className="flex flex-col items-center px-4 border-b border-black/10">
                        {getButtons(key)}
                      </div>
                    )}
                    {!open && (
                      <Disclosure.Button className="flex items-center px-5 mx-auto text-base capitalize bg-white w-fit h-11 text-theme-red">
                        <CaretDown
                          size={14}
                          weight="bold"
                          className="inline-block mr-1.5 my-auto"
                        />
                        VIEW DETAILS
                      </Disclosure.Button>
                    )}
                  </Fragment>
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                {({ open, close }) => (
                  <Fragment>
                    <div className="flex flex-col gap-6 px-4 mt-4">
                      {data.map((section) => {
                        if (
                          section.data.filter(
                            (row) => row[key] !== "-" && row[key] === true
                          ).length === 0
                        )
                          return null;
                        return (
                          <div
                            key={section.heading}
                            className="flex flex-col gap-2"
                          >
                            <div className="text-xs font-medium uppercase text-slate-400">
                              {section.heading}
                            </div>
                            <div className="flex flex-col gap-3">
                              {section.data.map((row, r_index) => {
                                if (row[key] === "-" || row[key] === false)
                                  return null;
                                return (
                                  <div
                                    className="flex items-start gap-1 text-xs text-neutral-800"
                                    key={r_index}
                                  >
                                    <Check className="w-4 h-4 font-bold text-green-500" />
                                    {row[key] === true ? row.label : row[key]}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      {key === "qpfp" && (
                        <div className="flex flex-col gap-2">
                          <div className="text-xs font-medium uppercase text-slate-400">
                            QPFP Program Benefits
                          </div>
                          <div className="flex flex-col gap-3">
                            {qpfpBenefits.map((benefits, b_index) => (
                              <div
                                className="flex items-start gap-1 text-xs text-neutral-800"
                                key={b_index}
                              >
                                <Check className="w-4 h-4 font-bold text-green-500" />
                                {benefits}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    {open && (
                      <div className="flex flex-col items-center px-4 mt-4 border-b border-black/10">
                        {getButtons(key)}
                      </div>
                    )}
                    {open && (
                      <button
                        onClick={() => close()}
                        className="flex items-center px-5 mx-auto text-base capitalize bg-white w-fit h-11 text-theme-red"
                      >
                        <CaretUp
                          size={14}
                          weight="bold"
                          className="inline-block mr-1.5 my-auto"
                        />
                        HIDE DETAILS
                      </button>
                    )}
                  </Fragment>
                )}
              </Disclosure.Panel>
            </Disclosure>
          </div>
        ))}
        <div className="flex italic lg:hidden">*{star_text}</div>
      </div>
    </div>
  );
};

Pricing.propTypes = {
  content: PropTypes.array,
  description: PropTypes.object,
  programs: PropTypes.object,
};

export default Pricing;
