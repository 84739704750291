/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import onboarding_bg from "../assets/svgs/onboarding_bg.svg";
import { Check } from "@phosphor-icons/react";
import promember_logo from "../assets/svgs/promember_logo.svg";
import qpfp_logo from "../assets/svgs/qpfp_logo.svg";

const GetStartedRightContainer = ({ className, content = {} }) => {
  const qpfpBenefits = [
    "100+ Hours of Training",
    "50+ ProTools / Calculators",
    "50+ Tests & Exams",
    "Proctored Final Exam",
    "Digital Courseware / Handouts",
    "Coaching Calls with Coaches",
  ];
  return (
    <div className={classNames("", className)}>
      <div className="relative flex flex-col items-center justify-between w-full pb-10 pt-14 h-fit bg-sky-50 px-7 overflow-clip">
        <img
          src={onboarding_bg}
          className="absolute z-0 object-cover w-full h-full left-2/3 bottom-2/3"
          alt="bg"
        />
        <img
          src={onboarding_bg}
          className="absolute z-0 object-cover w-full h-full right-2/3 top-2/3"
          alt="bg"
        />
        <div className="z-10 flex flex-col items-center w-full h-full">
          <div className="leading-[48px] text-center max-w-[810px] tracking-tight mx-auto text-4xl font-bold text-theme-black-color">
            Learning never stops, Growing never ends.
          </div>
          <div className="grid w-full grid-cols-1 gap-6 mt-10 xl:grid-cols-3">
            {["community", "qpfp", "pro_membership"].map((key) => (
              <div key={key} className="px-4 py-6 bg-white ">
                <div className="text-xl font-semibold">
                  {key === "community" && (
                    <div className="hidden h-5 mb-3 xl:flex" />
                  )}
                  {key !== "community" && (
                    <img
                      src={key === "qpfp" ? qpfp_logo : promember_logo}
                      className="w-auto h-5 mb-3"
                      alt="qpfp course"
                    />
                  )}
                  {key === "basic"
                    ? "Basic Membership"
                    : key === "community"
                    ? "Community Membership"
                    : key === "qpfp"
                    ? "QPFP Certification"
                    : "ProMembership"}
                </div>
                <div className="text-gray-500">
                  <div className="grid grid-cols-2 gap-6 mt-4 xl:grid-cols-1">
                    {content?.data?.map((section) => {
                      if (
                        section.heading.toLowerCase().includes("eligibility") ||
                        section.heading.toLowerCase().includes("fees") ||
                        (section.data ?? []).filter(
                          (r) => r[key] !== "-" && r[key] !== false
                        ).length === 0
                      )
                        return null;
                      return (
                        <div
                          key={section.heading}
                          className="flex flex-col gap-2"
                        >
                          <div className="text-xs font-medium uppercase text-slate-400">
                            {section.heading}
                          </div>
                          <div className="flex flex-col gap-3">
                            {section.data.map((row, r_index) => {
                              if (row[key] === "-" || row[key] === false)
                                return null;
                              return (
                                <div
                                  className="flex items-start gap-1 text-xs text-neutral-800"
                                  key={r_index}
                                >
                                  <Check className="rounded-full mr-2 font-bold w-4 h-4 text-green-500 bg-green-500/20 px-0.5" />
                                  {row[key] === true ? row.label : row[key]}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                    {key === "qpfp" && (
                      <div className="flex flex-col gap-2">
                        <div className="text-xs font-medium uppercase text-slate-400">
                          QPFP PROGRAM BENEFITS
                        </div>
                        <div className="flex flex-col gap-3">
                          {qpfpBenefits.map((benefits, b_index) => (
                            <div
                              className="flex items-start gap-1 text-xs text-neutral-800"
                              key={b_index}
                            >
                              <Check className="rounded-full mr-2 font-bold w-4 h-4 text-green-500 bg-green-500/20 px-0.5" />
                              {benefits}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
GetStartedRightContainer.defaultProps = { className: "" };
GetStartedRightContainer.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
};

export default GetStartedRightContainer;
