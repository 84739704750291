/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import AppSkeleton from "../AppSkeleton";
import { useNavigate } from "@reach/router";

const QPFPCertification = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // An api call will happen here to get user status on QPFP Certification and based on that we will redirect to respective screen

    const isRegistered = false;
    if (isRegistered) {
      navigate("/qpfp-certificant/dashboard");
      return;
    }
    navigate("/qpfp-certificant/registration");
    return;
  }, []);
  return <AppSkeleton isLoading={true} hideHeader hideSidebar></AppSkeleton>;
};

export default QPFPCertification;
