import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import Button from "../../../stories/Button";
import { BulletList } from "../../../stories/BulletList";
import { useProfile } from "../../../context/userProvider";
import moment from "moment";
import { useNavigate } from "@reach/router";
import { UserService } from "../../../services/UserService";

const QPFPCertificationRenewal = () => {
  const { userDetails } = useProfile();
  const [status, setStatus] = useState({});

  const fetchData = async () => {
    const response = await UserService.fetchQPFPCertificationStatus();
    setStatus(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <QPFPSkeleton>
      <div className="flex flex-col w-full py-10 relative z-0 px-6">
        <div className="font-bold text-2xl">QPFP Certification Renewal</div>
        <RenewUnavailable
          renewalDate={moment(status.valid_upto).format("DD MMMM YYYY")}
          status={userDetails.qpfp_certification_status}
          acePointsRequired={status?.ace_points_data?.ace_points_remaining ?? 0}
        />
        <RenewAvailable status={userDetails.qpfp_certification_status} />
      </div>
    </QPFPSkeleton>
  );
};

export const RenewUnavailable = ({
  hideLabel = false,
  renewalDate,
  status,
  acePointsRequired = 0,
}) => {
  const navigate = useNavigate();
  const isDisabled =
    !["expires_soon", "overdue"].includes(status) || acePointsRequired > 0;
  return (
    <>
      {!hideLabel && (
        <div className="text-base font-bold mt-8">Your QPFP Certificate</div>
      )}
      <div className="flex flex-col lg:flex-row justify-between mt-5 items-center bg-[#FFBE5C33] border border-theme-orange/20 rounded p-4 gap-2">
        <div className="flex flex-col text-theme-black-color items-start">
          <div className="text-base font-bold">
            Your Renewal is Due on :{" "}
            <span className="text-sm font-medium text-theme-black-300">
              {renewalDate}
            </span>
          </div>
          <div className="text-sm text-theme-black-300 italic">
            Renewal payment will open one month prior to renewal date
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-fit lg:items-end gap-1">
          <Button
            buttonStyle={isDisabled ? "disabled" : "primary"}
            disabled={isDisabled}
            className="h-10 w-fit"
            onClick={() => navigate("/qpfp-certificant/registration")}
          >
            Renew your QPFP Certification
          </Button>
          <div className="text-theme-red-300 text-sm italic font-semibold">
            Ace required to renew: {acePointsRequired}
          </div>
        </div>
      </div>
    </>
  );
};

RenewUnavailable.propTypes = {
  acePointsRequired: PropTypes.number,
  hideLabel: PropTypes.bool,
  renewalDate: PropTypes.string,
  status: PropTypes.string,
};
const RenewAvailable = ({ status }) => {
  return (
    <>
      <div className="text-base font-bold mt-8">QPFP Renewal Benefits</div>
      <div className="text-sm text-theme-black-300 my-3">
        Download and share the digital certificate of your QPFP Certificate with
        latest validity.
      </div>

      <div className="flex flex-col bg-white rounded py-3 px-4 gap-3">
        <div className="text-sm font-medium">QPFP Renewal Benefits</div>
        <BulletList
          list={[
            "Continue using QPFP Certificates, Badges, Marks & Designation",
            "Access QPFP Latest Courseware and ProTools",
            "Pursue ACE Programs (Advanced Continuous Education)",
            "Use NFP PROfiles",
          ]}
        />
      </div>
      <div className="flex justify-between mt-5 items-center bg-[#FFDFAE33] border border-theme-orange/20 rounded p-4 gap-2">
        <div className="flex flex-col text-theme-black-color items-start gap-3">
          <div className="text-sm font-medium">Non-Renewal Actions</div>
          <BulletList
            list={[
              "Stop using QPFP designation, marks and designation ",
              "Revoke access to all QPFP Certifcation benefits",
              "Give QPFP Final Exams again upon non-renewal for 3 years",
            ]}
          />
        </div>
      </div>
    </>
  );
};

RenewAvailable.propTypes = {
  status: PropTypes.string,
};

export default QPFPCertificationRenewal;
