/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";
import { Circle } from "@phosphor-icons/react";
import CalendarSessionDetails from "../../stories/CalendarSessionDetails";
import { getCourseColor } from "../../constants/courseColor";

const WEEK_ARR = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

const WeekView = ({ setEventToDisplay, eventToDisplay, events, dateRange }) => {
  const thClassName =
    "sticky h-10 top-20 bg-zinc-50 border border-theme-gray-300 border-collapse";
  const trClassName = "border border-theme-gray-300 border-collapse";
  const tdClassName =
    "bg-white min-w-[110px] max-w-[14%] px-2 w-[14%] h-24 text-center text-xs border border-theme-gray-300 border-collapse";
  const dayLabel =
    "min-w-[110px] text-center h-24 w-[14%] border border-theme-gray-300 border-collapse";

  return (
    <div className="bg-zinc-50">
      <div className="grid h-16 grid-cols-7 lg:hidden">
        {dateRange.map((date, date_index) => {
          const dateObj = moment(date, "DD-MM-YYYY");
          const isSelected =
            moment(
              eventToDisplay?.[0]?.date?.split("T")[0],
              "YYYY-MM-DD"
            ).format("DD-MM-YYYY") === date;
          return (
            <div
              key={`${date}_${date_index}`}
              className="flex justify-center items-center px-2 py-2.5"
            >
              <div
                className={classNames(
                  "gap-0.5 rounded-full px-2 py-1.5 flex flex-col items-center",
                  isSelected && "border border-theme-orange bg-theme-orange/10"
                )}
                onClick={() => {
                  setEventToDisplay(events[date]);
                }}
              >
                <span className="text-xs font-semibold">{dateObj.date()}</span>
                <span className="text-xxs font-regular">
                  {WEEK_ARR[dateObj.day()]}
                </span>
                {!isSelected && events[date]?.length > 0 && (
                  <Circle weight="fill" className="w-1 h-1 text-theme-orange" />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col w-full gap-4 lg:hidden">
        {!_.isEmpty(eventToDisplay) && (
          <CalendarSessionDetails
            setEventToDisplay={setEventToDisplay}
            eventToDisplay={eventToDisplay}
          />
        )}
      </div>
      <table className="hidden w-full lg:table">
        <thead className="">
          <tr>
            <th className={thClassName}></th>
            <th className={thClassName}>Program</th>
            <th className={thClassName}>Topic</th>
            <th className={thClassName}>Trainer</th>
            <th className={thClassName}>Timing</th>
            <th className={thClassName}>For</th>
            <th className={thClassName}>Joining</th>
          </tr>
        </thead>
        <tbody>
          {dateRange.map((date, date_index) => {
            const eventsInADay = events[date] ?? [];
            const dateObj = moment(date, "DD-MM-YYYY");
            if (eventsInADay.length === 0)
              return (
                <tr key={`${date}_${0}`} className={trClassName}>
                  <td className={dayLabel}>
                    {dateObj.date()}
                    <br></br> {WEEK_ARR[dateObj.day()]}
                  </td>
                  <td className={tdClassName}></td>
                  <td className={tdClassName}></td>
                  <td className={tdClassName}></td>
                  <td className={tdClassName}></td>
                  <td className={tdClassName}></td>
                  <td className={tdClassName}></td>
                </tr>
              );
            return (
              <>
                {eventsInADay.map((event, e_index) => {
                  const color = getCourseColor(event.course_type);
                  return (
                    <tr key={`${date}_${e_index}`} className={trClassName}>
                      {e_index === 0 && (
                        <td
                          rowSpan={eventsInADay.length ?? 1}
                          className={dayLabel}
                        >
                          <span className="font-semibold">
                            {dateObj.date()}
                          </span>
                          <br></br> {dateObj.format("ddd")}
                        </td>
                      )}
                      <td
                        className={classNames(tdClassName, "cursor-pointer")}
                        onClick={() => {
                          setEventToDisplay([event]);
                        }}
                      >
                        <span
                          className={`border flex w-fit text-ellipsis break-all rounded-full text-${color} border-${color} bg-${color}/10 py-1 px-2.5`}
                        >
                          {event.course_short_title}
                        </span>
                      </td>
                      <td className={tdClassName}>{event.title}</td>
                      <td className={tdClassName}>{event.trainer}</td>
                      <td className={tdClassName}>
                        {moment(event.start_time).format("LT")} to{" "}
                        {moment(event.end_time).format("LT")}
                      </td>
                      <td className={tdClassName}>{event.for}</td>
                      <td className={tdClassName}>
                        <button
                          className="m-auto text-white hover:bg-theme-red bg-theme-red-300 px-1.5 py-1 rounded"
                          onClick={() => {
                            setEventToDisplay([event]);
                          }}
                        >
                          Click here
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

WeekView.propTypes = {
  dateRange: PropTypes.array,
  eventToDisplay: PropTypes.any,
  events: PropTypes.object,
  setEventToDisplay: PropTypes.func,
};

export default WeekView;
