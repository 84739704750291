/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import TextInput from "../../../stories/TextInput";
import { Validations } from "../../../constants/validations";
import Button from "../../../stories/Button";
import Datepicker from "../../../stories/DatePicker";
import Dropdown from "../../../stories/Dropdown";
import { genderOptions } from "../../../Constants";
import Loader from "../../Shimmer/Loader";
import _ from "lodash";
import { AuthService } from "../../../services/AuthService";
import { CustomToast } from "../../../stories/Toast";
import { PromemberService } from "../../../services/PromemberService";
import moment from "moment";

const RegistrationDetails = ({
  currentMembershipDetails,
  loading,
  onSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    city_id: "",
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCities = async () => {
    try {
      const result = await AuthService.getCities("cities");
      if (result.locations) {
        setCities(result.locations);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchStates = async () => {
    try {
      const result = await AuthService.getCities("states");
      if (result.locations) {
        setStates(result.locations);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCities();
    fetchStates();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentMembershipDetails)) {
      setInitialValues({
        first_name: currentMembershipDetails?.first_name ?? "",
        last_name: currentMembershipDetails?.last_name ?? "",
        email: currentMembershipDetails?.email ?? "",
        mobile_number: currentMembershipDetails?.mobile_number ?? "",
        gender: currentMembershipDetails?.gender ?? "",
        dob:
          currentMembershipDetails?.dob &&
          moment(currentMembershipDetails?.dob, "DD/MM/YYYY"),
        firm_name: currentMembershipDetails?.firm_name ?? "",
        designation: currentMembershipDetails?.designation ?? "",
        address_attributes: {
          line_1: currentMembershipDetails?.address?.line_1 ?? "",
          pin: currentMembershipDetails?.address?.pin ?? "",
          city_id: currentMembershipDetails?.address?.city_id ?? "",
          state_id: currentMembershipDetails?.address?.state_id ?? "",
        },
      });
    }
  }, [currentMembershipDetails]);

  if (loading) {
    return (
      <div className="w-full max-w-3xl bg-white p-4 shadow-card-shadow -mt-20 flex mx-auto flex-col rounded z-10">
        <Loader />
      </div>
    );
  }
  return (
    <div className="w-full max-w-3xl bg-white p-4 shadow-card-shadow -mt-20 flex mx-auto flex-col rounded z-10">
      <div className={classNames("flex flex-col w-full items-start")}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            Validations.qpfpCertification.registrationValidationSchema
          }
          onSubmit={async (values) => {
            try {
              const result = await PromemberService.updatePromember(
                {
                  ...values,
                  dob: moment(values.dob).toLocaleString(),
                  address_attributes: {
                    ...values.address_attributes,
                    id: currentMembershipDetails?.address?.id,
                  },
                },
                "qpfp",
                currentMembershipDetails.entity_id
              );
              if (result.message) {
                CustomToast.success(result.message);
                onSubmit();
                window.scroll(0, 0);
              }
            } catch (e) {
              CustomToast.error(e.error_messages[0]);
            }
          }}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full">
                <div className="flex flex-col w-full gap-6 mt-2 bg-white rounded-sm shadow-sm lg:shadow-none">
                  <div className="flex flex-col w-full items-start">
                    <div className="text-lg lg:text-xl font-medium leading-5 tracking-tight text-theme-black-color">
                      Registration Details
                    </div>
                    <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
                      Fill your information here please.
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <TextInput
                      name="first_name"
                      label="First Name"
                      placeholder="Rahul"
                      id="first_name"
                      type="text"
                      disable={true}
                      isRequired
                    />
                    <TextInput
                      name="last_name"
                      label="Last Name"
                      placeholder="Subramanian"
                      id="last_name"
                      type="text"
                      disable={true}
                      isRequired
                    />
                    <TextInput
                      name="email"
                      label="Email Address"
                      placeholder="rahul@xyz.com"
                      id="email"
                      type="text"
                      disable={true}
                      isRequired
                    />
                    <TextInput
                      name="mobile_number"
                      label="Mobile No. (10 digits)"
                      placeholder="9876543210"
                      id="mobile_number"
                      type="number"
                      disable={true}
                      leadingComponent={
                        <span className="inline-flex items-center px-4 text-base text-gray-500 border-r border-gray-200 rounded rounded-r-none bg-gray-50">
                          +91
                        </span>
                      }
                      isRequired
                    />
                    <Datepicker
                      name="dob"
                      placeholder="Birth Date"
                      label="Date of Birth"
                      filterDate={(d) => d < new Date()}
                      isRequired
                    />
                    <Dropdown
                      name="gender"
                      id="gender"
                      type="text"
                      label="Gender"
                      placeholder="Select Gender"
                      options={genderOptions}
                      displayCurrentOption={true}
                      displayKey="gender"
                      idKey="id"
                      isRequired
                    />
                    <TextInput
                      name="firm_name"
                      label="Firm Name"
                      placeholder="XYZ Company"
                      id="firm_name"
                      type="text"
                    />
                    <TextInput
                      name="designation"
                      label="Designation"
                      placeholder="Personal Finance Professional"
                      id="designation"
                      type="text"
                    />
                    <div className="col-span-full text-sm font-medium text-theme-black-300">
                      Your QPFP Certificate & Kit will be delivered here
                    </div>
                    <TextInput
                      name="address_attributes.line_1"
                      label="Communication Address"
                      placeholder="12/14, Vaju Kotak Marg, Ballard Estate"
                      id="line_1"
                      type="text"
                      isRequired
                    />
                    <TextInput
                      name="address_attributes.pin"
                      label="Pincode"
                      placeholder="Pincode"
                      id="pin"
                      type="text"
                      isRequired
                    />
                    <Dropdown
                      name="address_attributes.state_id"
                      id="state"
                      type="text"
                      label="State"
                      placeholder="Select State"
                      options={states ?? []}
                      displayCurrentOption={true}
                      displayKey="name"
                      idKey="id"
                      isRequired
                    />
                    <Dropdown
                      name="address_attributes.city_id"
                      id="city"
                      type="text"
                      label="City"
                      placeholder="Select City"
                      options={cities ?? []}
                      displayCurrentOption={true}
                      displayKey="name"
                      idKey="id"
                      isRequired
                    />
                    <div className="hidden lg:block" />
                    <Button
                      buttonStyle="primary"
                      height="40px"
                      width="100%"
                      className="order-1 lg:order-2"
                      disabled={formProps.isSubmitting}
                      type="submit"
                    >
                      NEXT
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

RegistrationDetails.propTypes = {
  currentMembershipDetails: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default RegistrationDetails;
