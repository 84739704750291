/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import classNames from "classnames";
import { Form, Formik } from "formik";
import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import { EventsService } from "../../services/EventsService";
import Dropdown from "../../stories/Dropdown";
import TextInput from "../../stories/TextInput";
import PaymentForm from "../Payments/PaymentForm";
import { PaymentServices } from "../../services/PaymentServices";
import { CustomToast } from "../../stories/Toast";
import LoadingModal from "../Modals/LoadingModal";
import { Validations } from "../../constants/validations";
import { LockKey, UserCircle } from "@phosphor-icons/react";
import LoginUserModal from "../Modals/LoginUserModal";
import { useOptions } from "../../services/BasicDetails";
import _ from "lodash";
import Button from "../../stories/Button";
import PaymentRequestSentModal from "../Modals/PaymentRequestSentModal";
import moment from "moment";

const EventForm = (props) => {
  const submitRef = React.useRef();
  const [cityOptions, setCityOptions] = useState([]);
  const [priceDetails, setPriceDetails] = useState({});
  const [entityProps, setEntityProps] = useState({});
  const [forceLoginUser, setForceLoginUser] = useState(false);
  const [newLoginUser, setNewLoginUser] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [rsvpOptions, setRsvpOptions] = useState([]);
  const [openPaymentRequestedModal, setOpenPaymentRequestedModal] =
    useState(false);
  const userTempToken = React.useRef(null);
  const { options } = useOptions(["growth_partner", "community_supporter"], {
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });
  const [isSeatAvailable, setIsSeatAvailable] = useState(true);

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    try {
      const result = await AuthService.getCities("cities");
      if (result.locations) {
        setCityOptions(result.locations);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRSVPOptions = async (daywise_labels = []) => {
    if (daywise_labels.length < 1) return;
    try {
      const promisesToAwait = [];
      daywise_labels?.forEach((label, index) => {
        promisesToAwait.push(
          EventsService.fetchRSVPOptions({
            entity_type: "ListItem",
            entity_id: label.id,
          })
        );
      });
      const responses = await Promise.all(promisesToAwait);
      setRsvpOptions(responses.map((e) => e.values));
    } catch (e) {
      console.log(e);
    }
  };

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    city: "",
    firmName: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState: "",
    venue_id: props.prefillData.selected_venue ?? "",
    venue_availability: true,
    referrer_name: "",
    referrer_contact: "",
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!props.entity_type || !props.entity_id) {
          return false;
        }
        const response = await PaymentServices.fetchDefaultPricing(
          props.entity_id,
          {
            entity_type: props.entity_type,
            entity_id: props.entity_id,
          }
        );
        await fetchGSTDetails();
        setPriceDetails(response);
        setEntityProps({
          entity_type: props.entity_type,
          entity_id: props.entity_id,
        });
      } catch (e) {
        console.log(e.error_messages?.[0] ?? "Something went wrong");
        CustomToast.error(e.error_messages?.[0] ?? "Something went wrong");
      }
    };
    fetchInitialData();
  }, [props.entity_type, props.entity_id]);

  const fetchGSTDetails = async () => {
    if (props.isNewUser) return null;
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false) return prevState;
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };

  const setInitialData = (data) => {
    if (_.isEmpty(data)) return;
    setInitialValues((prev) => ({
      ...prev,
      firstName: data?.first_name ?? "",
      lastName: data?.last_name ?? "",
      email: data?.email ?? "",
      mobileNumber: data?.phone_number ?? "",
      city: data?.city?.id ?? "",
      firmName: data?.firm?.name ?? "",
    }));
  };

  useEffect(() => {
    setInitialData(props.publicProfileData);
  }, [props.publicProfileData]);

  useEffect(() => {
    setInitialData(props.prefillData);
  }, [props.prefillData]);

  useEffect(() => {
    fetchRSVPOptions(props.eventDetails?.daywise_label ?? []);
    fetchRSVPOptions(props.eventDetails?.daywise_label ?? []);
    setInitialValues((prev) => ({
      ...prev,
      enable_rsvp: props.eventDetails?.enable_rsvp,
      rsvp: Array(props.eventDetails?.daywise_label?.length ?? 0).fill(null),
    }));
  }, [props.eventDetails]);

  const registerAndPayForEvent = async (values, paymentType) => {
    if (forceLoginUser) {
      CustomToast.error(
        "Please login with the existing email Id to continue registration."
      );
      setOpenLoginPopup(true);
      return;
    }
    const postBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.mobileNumber,
      firm_name: values.firmName,
      city_id: values.city,
      event_id: parseInt(props.entity_id),
      referrer_name: values.referrer_name ?? "",
      referrer_contact: values.referrer_contact ?? "",
    };
    if (!isSeatAvailable) {
      CustomToast.error("Tickets Sold Out. Please select different venue");
      return;
    }
    if ((props.eventDetails?.ticketing ?? []).length > 0) {
      postBody["venue_ticket_id"] = values.venue_id;
    }
    if (
      props.eventDetails?.show_growth_partner &&
      _.isNumber(values.growth_partner_id)
    ) {
      postBody["growth_partner_id"] = values.growth_partner_id;
    }
    let isRegistered = false;
    let isPaymentCompleted = false;
    let userEventId;
    try {
      const result = props.isNewUser
        ? await EventsService.directRegister(
            postBody,
            priceDetails.to_be_paid === 0 && priceDetails.coupons.length === 0
          )
        : await EventsService.register(
            postBody,
            priceDetails.to_be_paid === 0 && priceDetails.coupons.length === 0
          );
      userEventId = result?.user_event_id;
      if (props.isNewUser) {
        userTempToken.current = result.token;
      }
      isRegistered = true;
    } catch (e) {
      if (e.error_code === "event_already_registered") {
        isRegistered = true;
        userEventId = e.payload.entity_id;
        if (e.payload?.payment_status === "paid") {
          isPaymentCompleted = true;
          CustomToast.info("You have already registered for this event.");
          props.onSubmitSuccess(values);
        }
      } else if (
        e.error_code === "growth_partner_limit_exceeded" ||
        e.error_code === "no_ticket_available"
      ) {
        CustomToast.error(e.error_messages[0]);
        return;
      } else {
        isRegistered = false;
      }
    }
    if (!isRegistered) {
      CustomToast.error("Something went wrong. Please try again.");
    }
    // register rsvp if needed.
    if (props.eventDetails.enable_rsvp) {
      try {
        const body = {
          event_id: entityProps.entity_id,
          attendances_attributes: props.eventDetails?.daywise_label.map(
            (e, index) => ({
              list_item_id: e.id,
              rsvp_option_id: values.rsvp[index],
            })
          ),
        };
        if (!props.isNewUser) {
          body["user_id"] = props.publicProfileData?.id;
        }
        await EventsService.createRSVP(
          body,
          props.isNewUser ? userTempToken.current : undefined
        );
      } catch (e) {
        console.log(e);
      }
    }
    if (
      paymentType === 1 &&
      userEventId !== undefined &&
      userEventId !== null
    ) {
      try {
        const response = await PaymentServices.sendPaymentRequestToAdmin({
          entity_type: "UserEvent",
          entity_id: userEventId,
        });
        CustomToast.success(response.message);
      } catch (e) {
        CustomToast.error(e.error_messages);
      }
    } else {
      if (!isPaymentCompleted) {
        try {
          await makePayment(values, userTempToken.current);
        } catch (e) {
          console.log(e);
        } finally {
          isPaymentCompleted = true;
        }
      }
    }
    if (isRegistered && isPaymentCompleted) {
      console.log("registered for event and paid");
    }
  };

  const sendPaymentRequestToAdmin = async (values) => {
    if (
      (props.eventDetails?.ticketing ?? []).length > 0 &&
      (values.venue_id === null ||
        values.venue_id === undefined ||
        values.venue_id === "")
    ) {
      CustomToast.error("Please select a venue to proceed.");
      return;
    }
    registerAndPayForEvent(values, 1);
  };

  const makePayment = async (values, userTempToken) => {
    let postBody = {
      pricing_id: priceDetails.pricing_id,
      coupon_ids: priceDetails.coupons?.map((e) => e.code),
      payment_gateway: "razorpay",
      course_fees: priceDetails.course_fees,
      gst: priceDetails.gst,
      to_be_paid: priceDetails.to_be_paid,
    };
    if (values.wantGST) {
      postBody = {
        ...postBody,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }

    const orderDetails = await PaymentServices.createOrder(
      {
        purchase: postBody,
        ...entityProps,
      },
      userTempToken
    );

    if (orderDetails && orderDetails.payment_status === "initiated") {
      await PaymentServices.makeRazorpayPayment(
        {
          name: values.firstName + " " + values.lastName,
          email: values.email,
          contact: values.mobileNumber,
        },
        orderDetails,
        () => {
          CustomToast.success("Payment is successful" ?? "");
          props.onSubmitSuccess(values);
        },
        userTempToken
      );
    } else if (orderDetails && orderDetails.payment_status === "success") {
      if (orderDetails.amount !== 0) {
        CustomToast.info(orderDetails.message);
      }
      props.onSubmitSuccess(values);
    } else {
      CustomToast.error(orderDetails.message);
    }
  };

  return (
    <div className={props.className}>
      <div className={classNames("flex flex-col w-full items-start")}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validations.eventFormvalidationSchema}
          onSubmit={async (values) => {
            if (
              (props.eventDetails?.ticketing ?? []).length > 0 &&
              (values.venue_id === null ||
                values.venue_id === undefined ||
                values.venue_id === "")
            ) {
              CustomToast.error("Please select a venue to proceed.");
              return;
            }
            await registerAndPayForEvent(values, 0);
          }}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full">
                <div className="p-4 bg-white rounded-sm shadow-sm lg:shadow-none">
                  <div className="flex text-lg font-semibold tracking-tight text-theme-black-color">
                    Registration Form
                  </div>
                  <div className="flex text-sm tracking-tight text-theme-black-300">
                    Fill your personal details here.
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-6">
                    {(props.eventDetails?.ticketing ?? []).length > 0 && (
                      <>
                        <div className="flex flex-col w-full col-span-1">
                          <Dropdown
                            name="venue_id"
                            id="venue_id"
                            type="text"
                            label="Venue"
                            placeholder="Select City / Date"
                            options={(props.eventDetails?.ticketing ?? []).map(
                              (e) => ({
                                id: e.id,
                                value: `${e.venue?.address?.city} - ${moment(
                                  e.date,
                                  "YYYY-MM-DD"
                                ).format("MMM DD")} - ${e.venue?.name}`,
                              })
                            )}
                            isRequired={true}
                            displayCurrentOption={true}
                            displayKey="value"
                            idKey="id"
                            onChange={async (val) => {
                              const response =
                                await EventsService.fetchTicketAvlStatus(
                                  val,
                                  props.eventDetails.id
                                );
                              setIsSeatAvailable(
                                response?.ticketing_status?.total_sold_tickets +
                                  response?.ticketing_status?.buffer_tickets <
                                  response?.ticketing_status?.total_tickets
                              );
                              props.onVenueChange(
                                val,
                                response?.ticketing_status
                              );
                            }}
                            // isClearable={true}
                          />
                          {!isSeatAvailable && (
                            <div
                              className={classNames(
                                "flex w-full mt-1 text-sm text-left text-red-700"
                              )}
                            >
                              Tickets Sold Out. Please select different venue
                            </div>
                          )}
                        </div>
                        <div className="hidden lg:block" />
                      </>
                    )}
                    <div className="">
                      <TextInput
                        name="firstName"
                        label="First Name"
                        placeholder="Rahul"
                        id="firstName"
                        type="text"
                        disable={!props.isNewUser}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        placeholder="Subramanian"
                        id="experience"
                        type="text"
                        disable={!props.isNewUser}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="email"
                        label="Email Address"
                        placeholder="rahul@xyz.com"
                        id="email"
                        type="text"
                        disable={!props.isNewUser}
                        isRequired
                        onBlur={async (event) => {
                          const currentEmail = event.target.value;
                          setUpdatedEmail(currentEmail);
                          // formikProps.setFieldValue("email", currentEmail, true);
                          setForceLoginUser(false);
                          try {
                            await AuthService.validateEmail({
                              email: currentEmail,
                              phone_number: values.mobileNumber.toString(),
                            });
                            setForceLoginUser(false);
                            setNewLoginUser(true);
                          } catch (e) {
                            // Email already present
                            // ask user to login
                            setNewLoginUser(false);
                            setForceLoginUser(true);
                          }
                        }}
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="mobileNumber"
                        label="Mobile No. (10 digits)"
                        placeholder="9876543210"
                        id="mobileNumber"
                        type="number"
                        disable={!props.isNewUser}
                        leadingComponent={
                          <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                            +91
                          </span>
                        }
                        isRequired
                        onBlur={async (event) => {
                          const currentPhoneNumber = event.target.value;
                          // formikProps.setFieldValue("email", currentEmail, true);
                          setForceLoginUser(false);
                          try {
                            await AuthService.validateEmail({
                              email: values.email,
                              phone_number: currentPhoneNumber,
                            });
                            setForceLoginUser(false);
                            setNewLoginUser(true);
                          } catch (e) {
                            // Email already present
                            // ask user to login
                            setNewLoginUser(false);
                            setForceLoginUser(true);
                          }
                        }}
                      />
                    </div>
                    {props.isNewUser && forceLoginUser && (
                      <div className="flex items-start text-sm text-gray-900 col-span-full">
                        <LockKey
                          size={16}
                          className="my-1 mr-2 text-theme-red-300"
                        />
                        <div className="block">
                          Your Network FP account already exists.&nbsp;
                          <span
                            onClick={() => setOpenLoginPopup(true)}
                            className="underline cursor-pointer text-theme-red-300"
                          >
                            Please login
                          </span>
                        </div>
                      </div>
                    )}
                    {props.isNewUser && newLoginUser ? (
                      <div className="flex items-start text-sm text-gray-900 col-span-full">
                        <UserCircle
                          size={16}
                          className="mt-0.5 mr-2 text-theme-red-300"
                        />
                        <div className="block">
                          New Login will be created on this ID.&nbsp;
                        </div>
                      </div>
                    ) : null}
                    <div className="">
                      <TextInput
                        name="firmName"
                        label="Firm Name"
                        placeholder="XYZ Company"
                        id="firmName"
                        type="text"
                        isRequired
                      />
                    </div>
                    <div className="w-full ">
                      <Dropdown
                        name="city"
                        id="city"
                        type="text"
                        label="City"
                        placeholder="Select City"
                        options={cityOptions}
                        displayCurrentOption={true}
                        displayKey="name"
                        idKey="id"
                        isRequired
                      />
                    </div>
                    {props.eventDetails.show_growth_partner && (
                      <div className="grid w-full grid-cols-2 gap-x-6 col-span-full">
                        <div className="mb-4 text-base font-semibold tracking-tight col-span-full text-theme-black-color">
                          Select NFP Partner or Supporter who has invited you to
                          the {props.eventDetails.title} to get FREE ticket.
                        </div>
                        <div className="w-full">
                          <Dropdown
                            name="growth_partner_id"
                            id="growth_partner_id"
                            type="text"
                            label="Growth Partner / Community Supporter"
                            placeholder="Select Partner/Supporter"
                            options={options["growth_partner"] ?? []}
                            displayCurrentOption={true}
                            displayKey="value"
                            idKey="id"
                            onChange={async (val) => {
                              const postBody = {
                                entity_type: props.entity_type,
                                entity_id: props.entity_id,
                                part_payment_order: 0,
                              };
                              if (_.isNumber(val)) {
                                postBody["discount_codes"] = props.eventDetails
                                  .coupon_code
                                  ? [props.eventDetails.coupon_code]
                                  : [];
                              } else {
                                postBody["discount_codes"] = [];
                              }
                              const response = props.isNewUser
                                ? await PaymentServices.fetchDefaultPricing(
                                    props.entity_id,
                                    postBody
                                  )
                                : await PaymentServices.fetchPricingDetails(
                                    postBody
                                  );
                              formProps.setFieldValue(
                                "couponInput",
                                _.isNumber(val)
                                  ? [props.eventDetails.coupon_code ?? ""]
                                  : [""]
                              );
                              setPriceDetails(response);
                            }}
                            isClearable={true}
                          />
                        </div>
                        <div className="mt-1 text-sm tracking-tight col-span-full text-theme-black-color">
                          Please Note: Your Registration details will be shared
                          with the selected partner/supporter.
                        </div>
                      </div>
                    )}
                  </div>
                  {props.eventDetails.enable_rsvp &&
                    props.eventDetails?.daywise_label?.length > 0 && (
                      <FieldArray
                        name="rsvp"
                        render={(fieldArrayProps) => {
                          return (
                            <div className="grid grid-cols-2 gap-6 mt-6">
                              <div className="flex flex-col items-start mt-6 col-span-full">
                                <div className="text-base font-semibold tracking-tight text-theme-black-color">
                                  Mode of Attendance -{" "}
                                  {props.eventDetails.title}
                                </div>
                              </div>
                              {props.eventDetails?.daywise_label?.map(
                                (rsvp, index) => (
                                  <div key={`${rsvp.text_content}-${index}`}>
                                    <Dropdown
                                      name={`rsvp.${index}`}
                                      id={`rsvp.${index}`}
                                      type="text"
                                      label={rsvp.text_content}
                                      placeholder="Confirm your Attendance"
                                      options={rsvpOptions[index] ?? []}
                                      displayCurrentOption={true}
                                      isSearchable={false}
                                      displayKey="value"
                                      idKey="id"
                                      isRequired
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          );
                        }}
                      ></FieldArray>
                    )}
                </div>
                <div className="grid grid-cols-2 px-4 mt-4 gap-x-6">
                  <div className="mb-2 text-base font-semibold tracking-tight text-theme-black-color col-span-full">
                    Who referred you to this program?
                  </div>
                  <TextInput
                    name="referrer_name"
                    label="Referrer Name"
                    placeholder="Full Name of Referrer"
                    id="referrer_name"
                    type="text"
                    isRequired
                  />
                  <TextInput
                    name="referrer_contact"
                    label="Mobile No. or Email ID. of Referer"
                    placeholder="Referrer Mobile or Email"
                    id="referrer_contact"
                    type="text"
                    isRequired
                  />
                </div>
                <PaymentForm
                  entityProps={entityProps}
                  setPriceDetails={setPriceDetails}
                  fetchPricingDetails={async (data) => {
                    const response = await PaymentServices.fetchDefaultPricing(
                      props.entity_id,
                      data
                    );
                    return response;
                  }}
                  priceDetails={priceDetails}
                  formikProps={{ values, ...formProps }}
                  submitRef={submitRef}
                  className="p-4 pt-2 bg-white rounded-sm shadow-sm lg:shadow-none"
                  programTitle={
                    props.eventDetails?.short_title ??
                    props.eventDetails?.title ??
                    ""
                  }
                  cta={
                    <React.Fragment>
                      <Button
                        buttonStyle="primary"
                        height="40px"
                        width="100%"
                        className="mt-3"
                        disabled={formProps.isSubmitting}
                        onClick={async () => {
                          submitRef.current?.click();
                        }}
                      >
                        PAY NOW
                      </Button>
                      {props.publicProfileData?.firm?.status === "approved" && (
                        <Button
                          buttonStyle="secondary"
                          height="40px"
                          width="100%"
                          className="mt-3"
                          disabled={formProps.isSubmitting}
                          onClick={async () => {
                            const response = await formProps.validateForm(
                              values
                            );
                            if (_.isEmpty(response)) {
                              await sendPaymentRequestToAdmin(values);
                              setOpenPaymentRequestedModal(true);
                            } else {
                              formProps.setTouched({
                                firstName: true,
                                lastName: true,
                                email: true,
                                mobileNumber: true,
                                city: true,
                                firmName: true,
                                wantGST: true,
                                gstin: true,
                                gstFirmName: true,
                                gstCommunicationAddress: true,
                                gstPincode: true,
                                gstCity_id: true,
                                gstState: true,
                                venue_id: true,
                                venue_availability: true,
                              });
                            }
                          }}
                        >
                          SEND PAYMENT DETAILS TO ADMIN
                        </Button>
                      )}
                    </React.Fragment>
                  }
                />
                <button
                  disabled={formProps.isSubmitting}
                  className="hidden"
                  ref={submitRef}
                  type="submit"
                  onClick={() => {}}
                >
                  submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
      <LoginUserModal
        prefill_data={{ email: updatedEmail }}
        className=""
        open={openLoginPopup}
        onClose={() => setOpenLoginPopup(false)}
      />
      {openPaymentRequestedModal && (
        <PaymentRequestSentModal
          open={openPaymentRequestedModal}
          onClose={() => setOpenPaymentRequestedModal(false)}
        />
      )}
      {props.loading && <LoadingModal open={props.loading} />}
    </div>
  );
};

export default EventForm;
