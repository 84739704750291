/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import Button from "../../../stories/Button";
import { Form, Formik } from "formik";
import { PublicProfileService } from "../../../services/PublicProfileService";
import _ from "lodash";
import { Validations } from "../../../constants/validations";
import TextInput from "../../../stories/TextInput";
import { AuthService } from "../../../services/AuthService";
import { CustomToast } from "../../../stories/Toast";

const InviteUserModal = ({ open, onClose, firmId }) => {
  const [inviteEmails, setInviteEmails] = useState([
    { email: "", id: "id-" + Math.random().toString(16).slice(2) },
    { email: "", id: "id-" + Math.random().toString(16).slice(2) },
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white text-theme-black-color focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center text-xl font-medium leading-6 tracking-tight text-theme-black-color"
              >
                Invite New Users
              </Dialog.Title>
              <div className="flex w-full text-sm tracking-tight text-theme-black-300">
                You can invite your team members to create a free account on
                Network FP. A email will be sent to them with account creation
                link. They can access free content and also register for NFP
                programs and membership.
              </div>

              <Formik
                validationSchema={
                  Validations.corporate.inviteUserValidationSchema
                }
                initialValues={{ users: inviteEmails }}
                enableReinitialize
                onSubmit={async (values) => {
                  try {
                    setInviteEmails(values.users);
                    const emails = values.users.filter(
                      (u) => !_.isEmpty(u.email)
                    );
                    const response = await PublicProfileService.inviteNewUsers({
                      data: {
                        id: firmId,
                        email: emails.map((u) => u.email),
                      },
                    });
                    CustomToast.success(response.message);
                    onClose();
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {({ values, ...formProps }) => (
                  <Form className="mt-6">
                    <div className="grid grid-cols-1 gap-4">
                      {values.users.map((user, index) => (
                        <TextInput
                          key={user.id}
                          name={`users.${index}.email`}
                          label="Enter Email of Team Member"
                          placeholder="Email Address"
                          id={`users.${index}.email`}
                          type="text"
                          isRequired
                          onBlur={async () => {
                            formProps.setFieldTouched(
                              `users.${index}.email`,
                              true
                            );
                            if (
                              !_.isEmpty(values.users[index].email) &&
                              _.isEmpty(
                                formProps?.errors?.users?.[index]?.email
                              )
                            ) {
                              await AuthService.validateEmail({
                                email: values.users[index].email,
                              })
                                .then(async (res) => {
                                  formProps.setFieldValue(
                                    `users.${index}.isValid`,
                                    true,
                                    true
                                  );
                                })
                                .catch((e) => {
                                  formProps.setFieldError(
                                    `users.${index}.email`,
                                    "Email address already present"
                                  );
                                });
                            }
                          }}
                        />
                      ))}
                    </div>
                    <div
                      className="my-4 text-sm font-medium tracking-tight uppercase cursor-pointer w-fit col-span-full text-theme-red"
                      onClick={() => {
                        formProps.setFieldValue(
                          "users",
                          [
                            ...values.users,
                            {
                              email: "",
                              id: "id-" + Math.random().toString(16).slice(2),
                            },
                          ],
                          true
                        );
                      }}
                    >
                      + Invite More
                    </div>
                    <Button
                      buttonStyle="primary"
                      type="submit"
                      size="md"
                      height="40px"
                      width="100%"
                      disabled={
                        formProps.isSubmitting ||
                        values.users.filter((u) => !_.isEmpty(u.email))
                          .length === 0 ||
                        values.users.filter(
                          (u) => !_.isEmpty(u.email) && u.isValid === true
                        ).length !==
                          values.users.filter((u) => !_.isEmpty(u.email))
                            .length ||
                        formProps.errors?.users?.length > 0
                      }
                    >
                      INVITE
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

InviteUserModal.defaultProps = {
  open: false,
  className: "",
};

InviteUserModal.propTypes = {
  firmId: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default InviteUserModal;
