/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import React from "react";
import { Validations } from "../../constants/validations";
import TextArea from "../../stories/TextArea";
import TextInput from "../../stories/TextInput";
import { CustomToast } from "../../stories/Toast";
import landing_call_icon from "../../assets/svgs/landing_call_icon.svg";
import landing_email_icon from "../../assets/svgs/landing_email_icon.svg";
import landing_whatsapp_icon from "../../assets/svgs/landing_whatsapp_icon.svg";
import landing_video_icon from "../../assets/svgs/landing_video_icon.svg";
import { EventsService } from "../../services/EventsService";
import Text from "../../stories/Text";

const ContactForm = ({ content = {} }) => {
  const data = [
    {
      heading: "Attend Live Demo",
      icon: (
        <img
          src={landing_video_icon}
          alt="video"
          className="w-5 h-5 min-w-[20px]"
        />
      ),
      data: content.live_demo_link ?? [],
    },
    {
      heading: "Call NFP Team",
      icon: (
        <img
          src={landing_call_icon}
          alt="phone"
          className="w-5 h-5 min-w-[20px]"
        />
      ),
      data: content.nfp_contact,
    },
    {
      heading: "chat with NFP Team",
      icon: (
        <img
          src={landing_whatsapp_icon}
          alt="whatsapp"
          className="w-5 h-5 min-w-[20px]"
        />
      ),
      data: content.nfp_chat,
    },
    {
      heading: "Email NFP Team",
      icon: (
        <img
          src={landing_email_icon}
          alt="email"
          className="w-5 h-5 min-w-[20px]"
        />
      ),
      data: content.nfp_email,
    },
  ];

  return (
    <div className="flex flex-col items-center pt-10 pb-0 lg:py-16 bg-theme-orange-400 lg:px-28">
      <div className="flex px-5 mb-12 text-3xl font-semibold text-center text-white lg:text-6xl">
        Contact for Assistance and Demos
      </div>
      <div className="flex flex-col justify-center w-full gap-14 lg:flex-row">
        <div
          id="contact-form-container"
          className="flex flex-col order-2 w-full p-6 text-white bg-black lg:order-1 lg:max-w-xl xl:max-w-3xl"
        >
          <div className="mb-6 text-2xl font-medium">Contact Form</div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              message: "",
            }}
            validationSchema={
              Validations.landingPageContactFormValidationSchema
            }
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const response = {};
                await EventsService.submitLandingContactForm({
                  first_name: values.firstName.trim(),
                  last_name: values.lastName.trim(),
                  mobile_number: values.mobileNumber,
                  email: values.email,
                  query: values.message,
                });

                if (response.data !== null) {
                  CustomToast.success(
                    "Your request has been sent! will get back to you shortly"
                  );
                  resetForm();
                }
                if (response?.error?.message[0]) {
                  CustomToast.error("Something went wrong");
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col gap-5">
                <div className="grid grid-cols-2 gap-4">
                  <TextInput
                    id="firstName"
                    isRequired={true}
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder=""
                    labelClassName="text-white/70"
                  />
                  <TextInput
                    id="lastName"
                    isRequired={true}
                    label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder=""
                    labelClassName="text-white/70"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <TextInput
                    id="mobileNumber"
                    isRequired={true}
                    label="Mobile No. (10 digits)"
                    name="mobileNumber"
                    type="tel"
                    placeholder=""
                    labelClassName="text-white/70"
                  />
                  <TextInput
                    id="email"
                    isRequired={true}
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder=""
                    labelClassName="text-white/70"
                  />
                </div>
                <div>
                  <TextArea
                    id="message"
                    rows={7}
                    label="Your message or Questions"
                    name="message"
                    type="text"
                    placeholder=""
                    labelClassname="text-white/70"
                  />
                </div>
                <div className="flex justify-end col-span-full">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full h-10 text-base font-bold text-white uppercase lg:w-32 bg-theme-orange-400"
                  >
                    submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex flex-col order-1 gap-6 px-10 text-white lg:order-2 lg:px-0">
          <div className="flex items-start gap-2">
            <img
              src={landing_video_icon}
              alt="video"
              className="w-5 h-5 min-w-[20px]"
            />
            <div className="flex flex-col gap-2">
              <div className="text-lg font-bold leading-5">
                Attend Live Demo
              </div>
              {content.live_demo_link?.map((link, index) => (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center w-40 py-2 text-base font-bold leading-5 bg-white rounded text-theme-orange-400"
                >
                  Click here
                </a>
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img
              src={landing_call_icon}
              alt="video"
              className="w-5 h-5 min-w-[20px]"
            />
            <div className="flex flex-col gap-2">
              <div className="text-lg font-bold leading-5">Call NFP Team</div>
              {content.nfp_contact?.map((contact, index) => (
                <span key={index} className="text-base leading-5 text-white ">
                  {contact}
                </span>
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img
              src={landing_whatsapp_icon}
              alt="video"
              className="w-5 h-5 min-w-[20px]"
            />
            <div className="flex flex-col gap-2">
              <div className="text-lg font-bold leading-5">
                Chat with NFP Team
              </div>
              {content.nfp_chat?.map((number, index) => (
                <span key={index}>
                  Whatsapp -{" "}
                  <a
                    href={`https://wa.me/${number}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-base leading-5 text-white hover:underline "
                  >
                    {number}
                  </a>
                </span>
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <img
              src={landing_email_icon}
              alt="video"
              className="w-5 h-5 min-w-[20px]"
            />
            <div className="flex flex-col gap-2">
              <div className="text-lg font-bold leading-5">Email NFP Team</div>
              {content.nfp_email?.map((email, index) => (
                <span key={index}>
                  Email Address -
                  <a
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-base leading-5 text-white hover:underline "
                  >
                    {email}
                  </a>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  content: PropTypes.object,
};

export default ContactForm;
