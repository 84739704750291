/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import { PaymentServices } from "../../services/PaymentServices";
import CheckBox from "../../stories/CheckBox";
import CouponInput from "../../stories/CouponInput";
import Dropdown from "../../stories/Dropdown";
import TextInput from "../../stories/TextInput";
import Checkout from "./Checkout";
import _ from "lodash";
import LoadingModal from "../Modals/LoadingModal";
import Button from "../../stories/Button";
import Text from "../../stories/Text";
import { CustomToast } from "../../stories/Toast";
const PaymentForm = ({
  programTitle,
  subTitle = "",
  entityProps,
  priceDetails,
  setPriceDetails = {},
  hideCouponField = false,
  formikProps,
  className,
  fetchPricingDetails = undefined,
  allowPartPayment = false,
  availablePartPaymentOptions = [],
  submitRef,
  isReadOnly,
  amountLabel,
  cta,
}) => {
  const initialValues = {};
  const [isLoading, setIsLoading] = useState(false);
  const { values, ...formProps } = formikProps;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (_.isEmpty(cities) && !_.isEmpty(values.gstCity_id?.toString())) {
      setCities([
        {
          id: values?.gstCity_id,
          name: values?.gstCity,
        },
      ]);
    }
  }, [values?.gstCity_id]);

  const fetchStates = async () => {
    const response1 = await AuthService.getCities("states");
    setStates(response1.locations);
  };
  useEffect(() => {
    fetchStates();
  }, []);

  const ViewOnlyField = ({ label, value, className }) => {
    return (
      <div className={classNames("flex w-full flex-col", className)}>
        <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
          {label}
        </div>
        <div className="mt-0.5 break-words text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
          {value !== undefined && value !== null && value !== "" ? value : "-"}
        </div>
      </div>
    );
  };
  ViewOnlyField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  };

  if (priceDetails.to_be_paid === 0 && priceDetails.coupons?.length === 0) {
    return (
      <div
        className={classNames(
          "grid grid-cols-2 gap-6 w-full mt-3 lg:mt-6 lg:flex-row",
          className
        )}
      >
        <div></div>
        <Button
          buttonStyle="primary"
          height="40px"
          width="100%"
          className="mt-3 col-span-full"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await submitRef.current?.click();
            setIsLoading(false);
          }}
        >
          REGISTER
        </Button>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "flex flex-col w-full mt-3 lg:mt-6 lg:flex-row",
        className
      )}
    >
      <div className="flex flex-col items-start w-full">
        <div className="flex text-base font-semibold leading-5 tracking-tight text-left text-theme-black-color">
          {programTitle}
        </div>
        {subTitle && (
          <div className="flex text-sm leading-5 tracking-tight text-left text-theme-black-300">
            {subTitle}
          </div>
        )}
        {priceDetails.course_inclusion && (
          <div className="flex flex-col mt-2 text-theme-black-300">
            {/* <div className="text-sm font-bold">Program fee inclusive of:</div> */}
            <Text
              className="text-sm font-medium"
              isMultiline={true}
              content={priceDetails.course_inclusion}
            />
          </div>
        )}
        {allowPartPayment &&
          (isReadOnly ? (
            <ViewOnlyField
              label="Payment for"
              value={
                _.head(
                  _.filter(
                    availablePartPaymentOptions,
                    (e) => e.part_payment_order === values["part_payment_order"]
                  ) ?? []
                )?.value ?? ""
              }
              className="mt-5"
            />
          ) : (
            <Dropdown
              size="xl"
              name="part_payment_order"
              displayCurrentOption={true}
              className="w-full mt-5"
              id="quiz_dropdown"
              label="Payment for"
              type="text"
              placeholder="Payment for"
              isSearchable={false}
              isClearable={false}
              displayKey="label"
              idKey="part_payment_order"
              placeholderColor="bg-white"
              options={availablePartPaymentOptions}
              onChange={async (option) => {
                setIsLoading(true);
                const postBody = {
                  entity_id: entityProps.entity_id,
                  entity_type: entityProps.entity_type,
                  part_payment_order: option,
                  discount_codes: values["couponInput"],
                };
                try {
                  const response = fetchPricingDetails
                    ? await fetchPricingDetails(postBody)
                    : await PaymentServices.fetchPricingDetails(postBody);
                  setPriceDetails(response);

                  return { success: true };
                } catch (e) {
                  return { success: false, message: e.error_messages[0] };
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          ))}
        {!hideCouponField &&
          (isReadOnly ? (
            <ViewOnlyField
              label="Discount Code"
              value={(priceDetails?.coupons ?? [])[0]?.code ?? ""}
              className="mt-5"
            />
          ) : (
            <CouponInput
              className="w-full mt-5"
              placeholder="Add a Coupon"
              defaultValue={values?.couponInput?.[0] ?? ""}
              name="couponInput"
              onClick={async (value) => {
                setIsLoading(true);
                const postBody = {
                  entity_id: entityProps.entity_id,
                  entity_type: entityProps.entity_type,
                  part_payment_order: values.part_payment_order,
                };
                if (value !== "") {
                  postBody["discount_codes"] = [value];
                }
                try {
                  const response = fetchPricingDetails
                    ? await fetchPricingDetails(postBody)
                    : await PaymentServices.fetchPricingDetails(postBody);
                  setPriceDetails(response);
                  formProps.setFieldValue("couponInput", [value]);
                  return { success: true };
                } catch (e) {
                  console.log(e);
                  return { success: false, message: e.error_messages[0] };
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          ))}
        {isReadOnly ? (
          values.wantGST === true ? (
            <div className="mt-5 text-sm font-medium tracking-tight text-left text-theme-black-300">
              GST Details
            </div>
          ) : (
            ""
          )
        ) : (
          <CheckBox
            name="wantGST"
            errorMessage={""}
            className="pb-6 mt-5"
            errorColor="text-red-600"
            inputLabel="GST Invoice Details"
            labelClassName="text-theme-black-300"
          />
        )}
        {values.wantGST && !isReadOnly && (
          <div className="flex w-full p-2 text-sm tracking-tight text-blue-500 border border-blue-500 rounded-md leading-1 bg-blue-50 md:flex">
            Please review your GST details carefully, as once submitted, changes
            cannot be made.
          </div>
        )}
        {values.wantGST && (
          <div className="flex flex-col items-start w-full">
            <div className={classNames("w-full", isReadOnly ? "" : "mt-3")}>
              {isReadOnly ? (
                <ViewOnlyField
                  label="GSTIN No."
                  value={values.gstin}
                  className="mt-1.5"
                />
              ) : (
                <TextInput
                  name="gstin"
                  label="GSTIN No."
                  placeholder="07AAGFF2194N1Z1"
                  id="gstin"
                  type="text"
                  isRequired
                />
              )}
            </div>
            <div className="w-full mt-3">
              {isReadOnly ? (
                <ViewOnlyField
                  label="Firm Name"
                  value={values.gstFirmName}
                  className="mt-1.5"
                />
              ) : (
                <TextInput
                  name="gstFirmName"
                  label="Firm Name"
                  placeholder="XYZ Company"
                  id="gstFirmName"
                  type="text"
                  isRequired
                />
              )}
            </div>
            <div className="w-full mt-3">
              {isReadOnly ? (
                <ViewOnlyField
                  label="Communication Address"
                  value={values.gstCommunicationAddress}
                  className="mt-1.5"
                />
              ) : (
                <TextInput
                  name="gstCommunicationAddress"
                  label="Communication Address"
                  placeholder="12/14, Vaju Kotak Marg, Ballard Estate"
                  id="gstCommunicationAddress"
                  type="text"
                  isRequired
                />
              )}
            </div>
            <div className="grid w-full grid-cols-2 mt-3 gap-x-3">
              {isReadOnly ? (
                <ViewOnlyField
                  label="State"
                  value={values.gstState}
                  className="mt-1.5"
                />
              ) : (
                <Dropdown
                  name="gstState_id"
                  displayCurrentOption={true}
                  id="gstState_id"
                  label="State"
                  type="text"
                  placeholder="Select state"
                  isSearchable={true}
                  isClearable={false}
                  displayKey="name"
                  idKey="id"
                  options={states}
                  onChange={async (option) => {
                    setIsLoading(true);
                    const response2 = await AuthService.getCities(
                      "cities",
                      option
                    );
                    setCities(response2.locations);
                    setIsLoading(false);
                  }}
                />
              )}
              {isReadOnly ? (
                <ViewOnlyField
                  label="City"
                  value={values.gstCity}
                  className="mt-1.5"
                />
              ) : (
                <Dropdown
                  name="gstCity_id"
                  displayCurrentOption={true}
                  id="gstCity_id"
                  label="City"
                  type="text"
                  placeholder="Select city"
                  isSearchable={true}
                  isClearable={false}
                  displayKey="name"
                  idKey="id"
                  options={cities}
                />
              )}
            </div>
            <div className="w-full mt-3">
              {isReadOnly ? (
                <ViewOnlyField
                  label="Pincode"
                  value={values.gstPincode}
                  className="mt-1.5"
                />
              ) : (
                <TextInput
                  name="gstPincode"
                  label="Pincode"
                  placeholder="Pincode"
                  id="gstPincode"
                  type="number"
                  // onChange={async (e) => {
                  //   formProps.setFieldValue("gstPincode", e.target.value, true);
                  //   if (e.target.value.length === 6) {
                  //     try {
                  //       const response = await AuthService.fetchPincodeDetails(
                  //         e.target.value
                  //       );
                  //       formProps.setFieldValue("gstCity", response.city);
                  //       formProps.setFieldValue("gstCity_id", response.city_id);
                  //       formProps.setFieldValue("gstState", response.state);
                  //     } catch (e) {
                  //       CustomToast.error("Pincode not valid.");
                  //       formProps.setFieldValue("gstCity", "");
                  //       formProps.setFieldValue("gstCity_id", "");
                  //       formProps.setFieldValue("gstState", "");
                  //     }
                  //   }
                  // }}
                  isRequired
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="hidden w-px h-full mx-6 lg:flex bg-theme-gray-300" />
      <div className="flex w-full h-px my-6 lg:hidden bg-theme-gray-300" />
      <Checkout
        key={initialValues}
        submitRef={submitRef}
        isReadOnly={isReadOnly}
        paymentBreakUp={[
          {
            label: amountLabel
              ? amountLabel
              : entityProps.entity_type === "course"
              ? "Certificate Fee"
              : entityProps.entity_type === "quiz"
              ? "Quiz Attempt Fee"
              : "Program Fee",
            amount: priceDetails.course_fees ?? priceDetails.amount ?? 0,
          },
          {
            type: "discount",
            label: "Discount",
            amount: priceDetails.discount ?? 0,
            color: "green",
            prefix: "-",
            hide: (priceDetails.discount ?? 0) === 0,
          },
          {
            label: "Discounted Amount",
            amount: priceDetails.discounted_fees ?? 0,
            hide: (priceDetails.discount ?? 0) === 0,
          },
          { label: "GST", amount: priceDetails.gst ?? 0, prefix: "+" },
        ]}
        totalAmount={priceDetails.to_be_paid ?? 0}
        discount_details={
          _.size(priceDetails?.coupons) > 0
            ? _.merge(_.head(priceDetails?.coupons), {
                discount: priceDetails.discount,
              })
            : null
        }
        cta={cta}
      />
      {isLoading && <LoadingModal open={isLoading} />}
    </div>
  );
};

PaymentForm.propTypes = {
  allowPartPayment: PropTypes.bool,
  availablePartPaymentOptions: PropTypes.array,
  className: PropTypes.string,
  cta: PropTypes.element,
  entityProps: PropTypes.shape({
    entity_id: PropTypes.any,
    entity_type: PropTypes.any,
  }),
  fetchPricingDetails: PropTypes.func,
  formikProps: PropTypes.object,
  isReadOnly: PropTypes.any,
  priceDetails: PropTypes.shape({
    coupons: PropTypes.array,
    course_fees: PropTypes.number,
    discount: PropTypes.number,
    discounted_fees: PropTypes.number,
    gst: PropTypes.number,
    to_be_paid: PropTypes.number,
  }),
  programTitle: PropTypes.any,
  setPriceDetails: PropTypes.func,
  submitRef: PropTypes.object,
};

export default PaymentForm;
