/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import forum_left_bg from "../../assets/svgs/forum_left_bg.svg";
import forum_right_bg from "../../assets/svgs/forum_right_bg.svg";
import forumBannerLeft from "../../assets/svgs/forum_banner_left.svg";
import forumBannerRight from "../../assets/svgs/forum_banner_right.svg";
import SearchBar from "../../stories/SearchBar";
import { useNavigate } from "@reach/router";
import classNames from "classnames";
import { User } from "../../store";
import LoginUserModal from "../Modals/LoginUserModal";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";

const Header = ({
  categories,
  searchQuery,
  onSearchChange,
  hideControls = false,
  className,
}) => {
  const navigate = useNavigate();
  const isLoggedIn = !!User.getToken();
  const [showLoginPopup, setShowLoginPopup] = useState();
  const { userDetails } = useProfile();
  const isProMember =
    (userDetails.is_pro_member &&
      ["provisional", "approved", "expires_soon"].includes(
        userDetails.pm_signup_status
      )) ||
    (!userDetails.is_pro_member &&
      ["provisional"].includes(userDetails.pm_signup_status));

  const validateIsPromember = () => {
    if (!isProMember) {
      CustomToast.info("Become a promember to enjoy exclusive benefits");
      return false;
    }
    return true;
  };
  return (
    <>
      <div
        className={classNames(
          "relative flex flex-col items-center justify-center w-full gap-10 px-4 bg-white md:px-20 h-96",
          className
        )}
      >
        <img
          alt={"forum"}
          src={forumBannerLeft}
          className="absolute left-0 hidden object-cover object-left w-1/2 h-full lg:block"
        />
        <img
          alt={"forum"}
          src={forumBannerRight}
          className="absolute right-0 hidden object-cover object-right w-1/2 h-full lg:block"
        />
        <div className="flex z-10 lg:max-w-[938px] flex-col gap-4">
          <div className="text-4xl font-extrabold text-center lg:text-5xl text-theme-black-color">
            Exchange Forum
          </div>
          <div className="text-base tracking-tight text-center lg:text-lg text-theme-black-300">
            Ask your questions on practice, client servicing, financial
            products, softwares & technologies, regulations, taxes etc.{" "}
            <br></br>Or simply share useful resources, updates, files etc that
            may be of interest to other members.
          </div>
        </div>
        {!hideControls && (
          <div className="flex z-10 gap-x-6 gap-y-3 flex-col lg:flex-row items-center lg:max-w-[938px] w-full mx-auto justify-center">
            <SearchBar
              className="!max-w-xl w-full mt-0 !h-10"
              size=""
              placeholder={"Search Exchange"}
              searchTerm={searchQuery}
              backgroundColor="bg-neutral-100 lg:bg-white"
              onSearch={(val) => onSearchChange(val)}
            />
            <button
              className="w-full h-10 text-white uppercase rounded lg:w-64 bg-theme-orange"
              onClick={() => {
                if (!isLoggedIn) {
                  setShowLoginPopup(true);
                  return;
                }
                if (!validateIsPromember()) {
                  return;
                }
                navigate("/exchange/ask-question", {
                  state: { categories: categories },
                });
              }}
            >
              Ask & Share Anything
            </button>
          </div>
        )}
      </div>
      {showLoginPopup && (
        <LoginUserModal
          className=""
          open={showLoginPopup}
          prefill_data={{}}
          onLoginSuccess={() => {
            window.location.reload();
          }}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </>
  );
};

Header.propTypes = {
  categories: PropTypes.array,
  className: PropTypes.any,
  hideControls: PropTypes.bool,
  onClickCreateForum: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchQuery: PropTypes.any,
};

export default Header;
