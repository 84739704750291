import React from "react";
import PropTypes from "prop-types";

import Button from "../../../stories/Button";
import { BulletList } from "../../../stories/BulletList";
import { useNavigate } from "@reach/router";

/**
 * Component for ProMembership Dashboard Element
 * @return {React.ReactElement} ProMembership Dashboard Element
 */
function QPFPProMembership({ heading, btnText, proMemberStatus }) {
  const navigate = useNavigate();
  const benefits = [
    "Weekly UpSkill Sessions",
    "Advanced ProTools & Resources",
    "Client Connect & IAP Decks",
    "Networking Events",
    "Community Q & A Forum",
  ];

  return (
    <div className="flex flex-col items-start space-y-4">
      <p className="font-normal text-xs">{heading}</p>

      <div className="self-stretch px-3 py-4 bg-[#F9F9F9]">
        <p className="mb-2 font-medium text-sm">Additional Benefits</p>
        <div className="flex flex-col space-y-3">
          <BulletList list={benefits} />
        </div>
      </div>

      <Button
        onClick={() =>
          navigate(
            proMemberStatus === "overdue"
              ? "/payments"
              : "/members/pro_member/register"
          )
        }
        className="!rounded-md"
      >
        {btnText}
      </Button>
    </div>
  );
}

QPFPProMembership.propTypes = {
  btnText: PropTypes.string,
  courseId: PropTypes.number,
  heading: PropTypes.string,
  proMemberStatus: PropTypes.string,
};

export default QPFPProMembership;
