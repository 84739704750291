/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "../../stories/Card";
import AppSkeleton from "../AppSkeleton";
import {
  useAllPrograms,
  useAllVoluntary,
  useSubscriptions,
} from "../../services/BasicDetails";
import ShowEventCard from "./ShowEventCard";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import _ from "lodash";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import { useNavigate } from "@reach/router";
import Button from "../../stories/Button";
import SubscriptionAccordian from "../../stories/SubscriptionAccordian";
import { useWindowSize } from "../../hooks/useWindowSize";
import PaymentModal from "../Modals/PaymentModal";
import Text from "../../stories/Text";
import SearchBar from "../../stories/SearchBar";
import { useProfile } from "../../context/userProvider";
import { useTourContext } from "../../context/tourProvider";

const Register = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isDesktop = width >= 728;
  const { userDetails } = useProfile();
  const isCorporateAccount = userDetails.is_corporate_account;
  const isPromember =
    (userDetails.is_pro_member &&
      ["provisional", "approved", "expires_soon"].includes(
        userDetails.pm_signup_status
      )) ||
    userDetails.pm_signup_status === "provisional";
  const [paymentPopupIndex, setPaymentPopupIndex] = useState(-1);
  const {
    loading: isProgramsLoading,
    programs,
    error,
  } = useAllPrograms({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const { loading: isVoluntaryLoading, voluntary_contributions } =
    useAllVoluntary({
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });
  const {
    loading: isSubscriptionsLoading,
    subscriptions,
    error: subscriptionError,
    mutate: mutateSubscriptions,
  } = useSubscriptions({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [searchQuery, setSearchQuery] = useState("");
  const isLoading =
    isProgramsLoading && isSubscriptionsLoading && isVoluntaryLoading;
  const { setState } = useTourContext();

  useEffect(() => {
    if (!isLoading) {
      setState((prev) => ({
        ...prev,
        run: true,
      }));
    }
  }, [isLoading]);

  if (isProgramsLoading && isSubscriptionsLoading && isVoluntaryLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }
  if (error || subscriptionError) {
    return (
      <AppSkeleton
        pageLabel="Register for NFP Programs"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          {
            name: "Register for NFP Programs",
            to: "/register",
            current: true,
          },
        ]}
      >
        <ErrorScreen />
      </AppSkeleton>
    );
  }

  if (
    _.isEmpty(subscriptions?.filter((e) => e.show_pay_now_btn)) &&
    _.isEmpty(programs.pro_member_courses) &&
    _.isEmpty(programs.qpfp_courses) &&
    _.isEmpty(programs.event_courses) &&
    _.isEmpty(programs.demo_courses_as_free_training) &&
    _.isEmpty(programs.provisional_member_courses) &&
    _.isEmpty(programs.free_courses) &&
    _.isEmpty(voluntary_contributions) &&
    (isCorporateAccount || !isPromember)
  ) {
    return (
      <AppSkeleton
        pageLabel="Register for NFP Programs"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          {
            name: "Register for NFP Programs",
            to: "/register",
            current: true,
          },
        ]}
      >
        <EmptyState
          heading={"No active events"}
          subHeading={
            <div>
              We don&apos;t have any active events. Please come back after
              sometime
            </div>
          }
          className="my-auto"
          cta={
            <Button className="!mt-4" onClick={() => navigate("/")}>
              <div className="flex items-center px-3 py-1">
                <span>GO TO DASHBOARD</span>
              </div>
            </Button>
          }
          image={
            <img
              alt="emptyStatePlaceHolder"
              className="mb-8 max-h-72"
              src={EmptyStatePlaceHolder}
            />
          }
        />
      </AppSkeleton>
    );
  }

  return (
    <AppSkeleton
      pageLabel="Register for NFP Programs"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        {
          name: "Register for NFP Programs",
          to: "/register",
          current: true,
        },
      ]}
    >
      <div className="flex flex-col justify-between p-6 md:items-center md:flex-row">
        <Text
          content="My Programs"
          type="title"
          size="text-xl"
          className="mb-1 leading-7 tracking-tighter line-clamp-1 text-theme-black-color"
        />
        <div className="flex w-full md:max-w-lg">
          <SearchBar
            className="w-full "
            backgroundColor="bg-white"
            placeholder={"Search course or event..."}
            searchTerm={searchQuery}
            onSearch={(val) => setSearchQuery(val)}
          />
        </div>
      </div>
      <div className="flex flex-col w-full p-5">
        {!(
          _.isEmpty(programs.pro_member_courses) &&
          _.isEmpty(programs.qpfp_courses) &&
          _.isEmpty(programs.event_courses) &&
          _.isEmpty(programs.demo_courses_as_free_training) &&
          _.isEmpty(programs.provisional_member_courses) &&
          _.isEmpty(programs.free_courses) &&
          _.isEmpty(voluntary_contributions) &&
          (isCorporateAccount || !isPromember)
        ) && (
          <div className="grid w-full grid-cols-1 gap-6 p-6 bg-white lg:grid-cols-2 xl:grid-cols-3">
            <Card
              className="min-w-full bg-white lg:bg-theme-gray-300"
              cardType="filled"
              header={
                <div className="flex items-center text-2xl font-bold leading-none tracking-tighter text-black-color">
                  Register for NFP Programs
                </div>
              }
              cta={<div></div>}
              cardBody={
                <div className="flex flex-col mt-4 text-sm tracking-tight text-black-color-300">
                  You can register for any of the Network FP Programs, Events,
                  Trainings here. Click on NFP Program that you wish to join,
                  fill the form and make payment.
                </div>
              }
            />

            {programs.pro_member_courses
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="pro_member"
                    program={program}
                    index={index}
                  />
                );
              })}
            {programs.provisional_member_courses
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="provisional_member"
                    program={program}
                    index={index}
                  />
                );
              })}
            {programs.qpfp_courses
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="qpfp"
                    program={program}
                    index={index}
                  />
                );
              })}
            {programs.event_courses
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="event"
                    program={program}
                    index={index}
                  />
                );
              })}
            {programs.demo_courses_as_free_training
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="free_training"
                    program={program}
                    index={index}
                  />
                );
              })}
            {programs.free_courses
              ?.filter((p) =>
                `${p.actual_name} ${p.course_duration} ${p.course_type} ${p.description} ${p.session_mode} ${p.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="free_training"
                    program={program}
                    index={index}
                  />
                );
              })}
            {(voluntary_contributions ?? [])
              ?.filter((p) =>
                `${p.title} ${p.purpose} ${p.end_date} ${p.description}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              ?.map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="voluntary"
                    program={program}
                    index={index}
                  />
                );
              })}
            {!isCorporateAccount &&
              isPromember &&
              [
                {
                  title: "NFP Corporate Membership",
                  course_type_str: "Program",
                  description:
                    "NFP Corporate Membership allows organizations to stand out and create a defined identity for your company. Any organization having atleast two ProMembers can opt for Corporate Membership.  ",
                },
              ].map((program, index) => {
                return (
                  <ShowEventCard
                    key={program.id}
                    program_type="corporate_account"
                    program={program}
                    index={index}
                  />
                );
              })}
          </div>
        )}
        {subscriptions?.filter((e) => e.show_pay_now_btn).length > 0 && (
          <div className="grid w-full grid-cols-1 gap-6 p-6 pt-4 mt-4 bg-white md:pt-2 lg:grid-cols-2 xl:grid-cols-3">
            <Card
              className="min-w-full mt-2 bg-white md:mt-4 lg:bg-theme-gray-300"
              cardType="filled"
              header={
                <div className="flex items-center text-2xl font-bold leading-none tracking-tighter text-black-color">
                  Pay for Overdue Programs
                </div>
              }
              cta={<div></div>}
              cardBody={
                <div className="flex flex-col mt-4 text-sm tracking-tight text-black-color-300">
                  You can renew your existing Network FP Programs, Events,
                  Trainings here. Click on NFP Program that you wish to renew,
                  and make payment.
                </div>
              }
            />
            {subscriptions
              ?.filter(
                (e) =>
                  e.show_pay_now_btn &&
                  `${e.name} ${e.course_type} ${e.payment_status} ${e.subscription_status}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              )
              .map((item, i) => (
                <SubscriptionAccordian
                  item={item}
                  key={item.course_id}
                  isDesktop={isDesktop}
                  className="h-fit"
                  onPayNowClick={() => {
                    setPaymentPopupIndex(i);
                  }}
                />
              ))}
          </div>
        )}
        {paymentPopupIndex !== -1 && (
          <PaymentModal
            open={paymentPopupIndex !== -1}
            title={
              subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                paymentPopupIndex
              ].name
            }
            entityProps={{
              entity_type: subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                paymentPopupIndex
              ].course_type,
              entity_id: subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                paymentPopupIndex
              ].course_id,
              part_payment_order:
                _.head(
                  subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                    paymentPopupIndex
                  ]?.available_part_payment_option ?? []
                )?.part_payment_order ?? 0,
            }}
            paymentEntityProps={{
              entity_type: subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                paymentPopupIndex
              ].course_type,
              entity_id:
                subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                  paymentPopupIndex
                ].entity_details?.entity_id ??
                subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                  paymentPopupIndex
                ].entity_id,
            }}
            availablePartPaymentOptions={
              subscriptions?.filter((e) => e.show_pay_now_btn)?.[
                paymentPopupIndex
              ]?.available_part_payment_option ?? [
                { label: "", part_payment_order: 0 },
              ]
            }
            onClose={() => {
              mutateSubscriptions();
              setPaymentPopupIndex(-1);
            }}
          />
        )}
      </div>
    </AppSkeleton>
  );
};

export default Register;
