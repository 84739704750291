/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState } from "react";
import RazorPayLogo from "../../assets/svgs/RazorPayLogo.svg";
import Link from "../../stories/Link";
import Button from "../../stories/Button";
import { RadioButton, CheckCircle } from "@phosphor-icons/react";
import ImageComponent from "../../stories/ImageComponent";

const Checkout = ({
  submitRef,
  isReadOnly,
  paymentBreakUp = [],
  totalAmount = 0,
  discount_details = null,
  cta,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const ViewOnlyField = ({ label, value, className }) => {
    return (
      <div className={classNames("flex w-full flex-col", className)}>
        <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
          {label}
        </div>
        <div className="mt-0.5 text-base leading-6 text-theme-black-color tracking-tight text-left">
          {value !== null && value !== "" ? value : "-"}
        </div>
      </div>
    );
  };

  ViewOnlyField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  };

  const ShowPaymentModes = () => {
    if (isReadOnly)
      return (
        <ViewOnlyField label="Payment Mode" value="Razorpay" className="mt-6" />
      );
    return (
      <React.Fragment>
        <div className="flex mt-6 text-base font-semibold leading-5 tracking-tight text-theme-black-color">
          Payment Mode
        </div>
        <div className="flex items-center w-full h-10 px-3 mt-2 border border-gray-200 rounded-md shadow-sm">
          <RadioButton className="w-4 h-4" weight="fill" color="#f36c04" />
          <ImageComponent
            src={RazorPayLogo}
            className="w-24 h-6 ml-3 !bg-white"
          />
        </div>
      </React.Fragment>
    );
  };

  const ShowTermsCondition = () => {
    if (isReadOnly) return null;
    return (
      <div className="w-full mt-6 text-sm tracking-tight text-left text-theme-black-300">
        By clicking Pay Now, you agree to our &nbsp;
        <Link
          href="https://networkfp.com/promember/member-rules-and-guidelines/"
          fontSize="sm"
          target="_blank"
          className="font-semibold tracking-tight text-theme-black-color"
        >
          Terms and Conditions
        </Link>
        &nbsp; and that you&apos;ve read our&nbsp;
        <Link
          href="https://networkfp.com/promember/privacy-policy/"
          target="_blank"
          fontSize="sm"
          className="font-semibold tracking-tight text-theme-black-color"
        >
          Privacy Policy
        </Link>
        .
      </div>
    );
  };

  const DisplayAmount = ({ charge, index }) => (
    <div key={index} className="flex justify-between w-full">
      <div
        className={classNames(
          "flex text-base leading-none flex-wrap gap-x-2 gap-y-2",
          charge.color === "green" && charge.amount > 0
            ? "text-green-600"
            : "text-theme-black-color"
        )}
      >
        <div className="">{charge.label}</div>
        {charge.type === "discount" && discount_details && (
          <div className="discount_badge">
            {discount_details?.discount_type === "percentage"
              ? `${discount_details?.discount_value}% off`
              : `Flat ₹ ${discount_details?.discount_value}`}
          </div>
        )}
      </div>
      <div
        className={classNames(
          "flex text-base leading-5 font-semibold tracking-tight",
          charge.color === "green" && charge.amount > 0
            ? "text-green-600"
            : "text-theme-black-color"
        )}
      >
        <span>
          {charge.prefix ?? ""} ₹ {parseAmount(charge.amount)}
        </span>
      </div>
    </div>
  );

  const parseAmount = (amount) =>
    amount.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      minimumIntegerDigits: 2,
    });

  const ShowDiscountDetails = () => {
    if (!discount_details) return null;
    return (
      <div className="flex flex-col w-full p-3 mt-6 rounded-sm bg-green-600/20">
        <div className="flex items-center text-green-600">
          <CheckCircle
            size={18}
            weight="fill"
            className="mr-2 text-theme-orange"
          />
          COUPON APPLIED
        </div>
        <div className="flex text-green-600">
          Congratulations You have saved ₹&nbsp;{discount_details.discount} with{" "}
          {discount_details.code}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start w-full bg-white">
      <div className="text-base font-semibold leading-5 tracking-tight text-theme-black-color">
        Order Summary
      </div>
      <div className="grid w-full grid-cols-1 mt-5 gap-y-4">
        {paymentBreakUp.map((charge, index) =>
          charge.hide ? null : (
            <DisplayAmount
              key={`${charge}-${index}`}
              charge={charge}
              index={`${charge}-${index}`}
            />
          )
        )}
      </div>
      <div className="h-0.5 my-6 w-full bg-theme-gray-300"></div>
      <div className="flex justify-between w-full">
        <div className="flex text-base font-semibold leading-5 tracking-tight text-theme-black-color">
          Total Amount
        </div>
        <div className="flex text-base font-semibold leading-5 tracking-tight text-theme-black-color">
          <span>₹ {parseAmount(totalAmount)}</span>
        </div>
      </div>
      <ShowDiscountDetails />
      <ShowPaymentModes />
      <ShowTermsCondition />
      {cta ?? (
        <Button
          buttonStyle="primary"
          height="40px"
          width="100%"
          className="mt-3"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            submitRef.current?.click();
            setIsLoading(false);
          }}
        >
          PAY NOW
        </Button>
      )}
    </div>
  );
};

Checkout.propTypes = {
  cta: PropTypes.any,
  discount_details: PropTypes.shape({
    code: PropTypes.any,
    discount: PropTypes.any,
    discount_type: PropTypes.string,
    discount_value: PropTypes.any,
  }),
  isReadOnly: PropTypes.any,
  paymentBreakUp: PropTypes.array,
  submitRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    }),
  }),
  totalAmount: PropTypes.number,
};

export default Checkout;
