/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import nfpLogo from "../../assets/images/nfp.png";
import QuizReportSummary from "../../stories/QuizReportSummary";
import QuizReport from "../../stories/QuizReport";
import Dropdown from "../../stories/Dropdown.js";
import { Formik } from "formik";
import Button from "../../stories/Button";
import { CourseService } from "../../services/CourseService";
import { useProfile } from "../../context/userProvider";
import { useNavigate } from "@reach/router";
import QuizIntroModal from "../../stories/QuizIntroModal";
import { CustomToast } from "../../stories/Toast";
import Loader from "../Shimmer/Loader";
import _ from "lodash";
import { useWindowSize } from "../../hooks/useWindowSize";
import classNames from "classnames";
import PaymentModal from "../Modals/PaymentModal";
import { PaymentServices } from "../../services/PaymentServices";
// import classNames from "classnames";

const QuizSummary = ({
  quizId,
  course_id,
  session_content_id,
  course_name,
  startNextSessionContent,
  onClickShowCurriculum = () => {},
}) => {
  const [quizSummary, setQuizSummary] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openReExampPaymentModal, setOpenReExampPaymentModal] = useState(-1);
  const [isError, setIsError] = useState(false);
  const [quizQuestionIndex, setQuizQuestionIndex] = React.useState(0);
  const [showQuizIntroPopup, setShowQuizIntroPopup] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("*");
  const [quizWindowRef, setQuizWindowRef] = useState();
  const { userDetails } = useProfile();
  const [entityId, setEntityId] = useState(null);
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const filters = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Unanswered",
      value: "unanswered",
    },
    {
      label: "Attempted",
      value: "attempted",
    },
    {
      label: "Correct Answers",
      value: "correct",
    },
    {
      label: "Incorrect Answers",
      value: "incorrect",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    if (quizId) {
      fetchQuizSummary();
    } else {
      setIsError(true);
      setIsLoading(false);
      CustomToast.error("Quiz ID is null");
    }
  }, []);

  useEffect(() => {
    if (quizWindowRef) {
      let intervalId;
      intervalId = setInterval(async () => {
        if (quizWindowRef?.closed) {
          const submitStatus = localStorage.getItem(
            `quiz_${quizId}_submit_status`
          );
          if (submitStatus) {
            if (submitStatus === "not_submitted") {
              if (localStorage.getItem(`quiz_time_spent_${quizId}`)) {
                const responseData = {
                  quiz_id: quizId,
                  is_submit: true,
                  seconds_spent:
                    localStorage.getItem(`quiz_time_spent_${quizId}`) ?? 0,
                };
                await CourseService.submitQuizQuestionResponse(responseData);
              }
            }
          }
          localStorage.removeItem(`quiz_time_spent_${quizId}`);
          localStorage.removeItem(`quiz_${quizId}_submit_status`);
          setShowQuizIntroPopup(false);
          fetchQuizSummary();
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }, [quizWindowRef]);

  useEffect(() => {
    if (currentFilter === "*") {
      setFilteredQuestions(quizSummary?.questions ?? []);
    } else {
      const tempQuestions = quizSummary?.questions.filter((question, index) => {
        if (currentFilter === "unanswered")
          return (question.attempted_answer_ids ?? []).length === 0;
        if ((question.attempted_answer_ids ?? []).length > 0) {
          if (currentFilter === "attempted") return true;
          if (currentFilter === "correct")
            return _.isEqual(
              question.attempted_answer_ids ?? [],
              question.answers
                .filter((ans) => ans.is_right === true)
                .map((ans) => ans.id)
            );
          if (currentFilter === "incorrect")
            return !_.isEqual(
              question.attempted_answer_ids ?? [],
              question.answers
                .filter((ans) => ans.is_right === true)
                .map((ans) => ans.id)
            );
        }

        return false;
      });
      setFilteredQuestions(tempQuestions ?? []);
    }
    setQuizQuestionIndex(0);
  }, [currentFilter]);

  const fetchQuizSummary = async () => {
    const response = await CourseService.fetchQuizSummary({ quiz_id: quizId });
    setQuizSummary({
      ...response,
      questions: _.isEmpty(response.questions)
        ? []
        : [
            ...response.questions.map((ques, index) => ({
              ...ques,
              dropdown_text: `Question ${index + 1}`,
            })),
          ],
    });
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="w-full h-full bg-white ">Something went wrong.</div>;
  }
  return (
    <React.Fragment>
      <div className="flex m-4 mb-0 lg:hidden">
        <Button
          buttonStyle="transparent-textonly-primary"
          className="!bg-white"
          width="100%"
          onClick={() => onClickShowCurriculum(true)}
        >
          VIEW CURRICULUM
        </Button>
      </div>
      <div className="w-full h-full lg:bg-white">
        <div className="m-4 lg:m-0 lg:p-0">
          <div className="flex flex-col p-3 pb-0 bg-white lg:pb-3 ">
            <div className="flex items-start justify-between">
              <div>
                <div className="text-base font-bold md:text-2xl text-theme-black-color">
                  {course_name}
                </div>
                <div className="flex items-center pt-2">
                  <img alt="NFP" className="w-8 h-8 mr-2.5" src={nfpLogo} />
                  <div className="text-sm font-semibold text-theme-black-color ">
                    Network FP
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center space-x-6">
               <button
                className="flex items-center space-x-1.5 hover:cursor-pointer"
                onClick={() => downloadRef.current.click()}
              >
                <a
                  ref={downloadRef}
                  rel="noreferrer"
                  href={
                    config.baseUrl +
                    "quizzes/" +
                    quizSummary.quiz_id +
                    "/download_report"
                  }
                  download
                  target="_blank"
                  className="hidden"
                >
                  Click here
                </a>
                <DownloadSimple
                  className="text-theme-red"
                  size={20}
                  weight="fill"
                />
                <div className="hidden text-sm font-semibold text-theme-red md:flex">
                  MY REPORT
                </div>
              </button>
            </div> */}
            </div>
            <div className="my-6 text-base font-bold md:p-3 md:text-xl md:border">
              <div className="flex flex-col justify-between md:items-center md:flex-row">
                {quizSummary["has_cleared"] ? (
                  <div className="text-green-500">
                    Congratulations! You&apos;ve successfully cleared the quiz.
                  </div>
                ) : (
                  <div>Better luck next time!!</div>
                )}
                {!quizSummary["has_cleared"] && (
                  <div className="flex">
                    {quizSummary.show_pay_now_btn && (
                      <Button
                        onClick={async () => {
                          try {
                            const response =
                              await PaymentServices.addUserExternalPurchaseDetails(
                                {
                                  user_external_purchase_detail: {
                                    entity_type: "Quiz",
                                    entity_id: quizSummary["id"],
                                  },
                                }
                              );
                            setEntityId(response["entity_id"]);
                            setOpenReExampPaymentModal(response.entity_id);
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                        height="36px"
                        buttonStyle="primary"
                        className="mt-2 md:mt-0"
                      >
                        Pay for Re-Exam
                      </Button>
                    )}
                    {quizSummary.can_retry && (
                      <Button
                        onClick={() => {
                          if (!_.isEmpty(quizSummary["quiz_link"])) {
                            window.open(quizSummary["quiz_link"], "_blank");
                            return;
                          }
                          setShowQuizIntroPopup(true);
                        }}
                        height="36px"
                        buttonStyle="primary"
                      >
                        Retry Quiz
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="pt-6 md:pt-8">
                <QuizReportSummary
                  quizTitle={quizSummary["title"]}
                  quizType={quizSummary["quiz_type"]}
                  name={
                    userDetails["first_name"] + " " + userDetails["last_name"]
                  }
                  percentage={quizSummary["obtained_score"]}
                  passingScore={quizSummary["eligible_score"]}
                  timeTaken={quizSummary["time_taken"]}
                  totalQuestions={quizSummary["total_questions"]}
                  correctAnswers={quizSummary["correct_answers"]}
                  incorrectAnswers={quizSummary["incorrect_answers"]}
                  unAttended={quizSummary["unanswered_questions"]}
                  scoreObtained={quizSummary["obtained_points"]}
                  totalScore={quizSummary["total_points"]}
                  acePoints={quizSummary["ace_points"]}
                  date={quizSummary["date"]}
                />
              </div>
            </div>
          </div>
          <div className="hidden border-t lg:flex" />
          {!quizSummary["show_correct_answer"] && (
            <div className="w-full h-full bg-white"></div>
          )}
          {quizSummary["show_correct_answer"] && (
            <div className="flex flex-col p-3 pt-0 mt-3 bg-white lg:mt-0 lg:pt-3 ">
              <div className="flex-col py-6">
                <div className="flex flex-col justify-between pb-6 md:flex-row">
                  <div className="text-sm pb-3.5">YOUR ANSWERS</div>
                  <div className="grid grid-cols-2 gap-3">
                    <Formik
                      enableReinitialize
                      initialValues={{ name: currentFilter }}
                    >
                      <Dropdown
                        size="xl"
                        name="name"
                        displayCurrentOption={true}
                        id="quiz_dropdown"
                        label="Filter"
                        type="text"
                        placeholder="Select Question"
                        displayKey="label"
                        idKey="value"
                        placeholderColor="bg-white"
                        isClearable={false}
                        isSearchable={false}
                        options={filters}
                        onChange={(e) => {
                          setCurrentFilter(e);
                        }}
                      />
                    </Formik>
                    <Formik
                      enableReinitialize
                      initialValues={{ name: quizQuestionIndex }}
                    >
                      <Dropdown
                        size="xl"
                        name="name"
                        displayCurrentOption={true}
                        id="quiz_dropdown"
                        label="Select Question"
                        type="text"
                        placeholder="Select Question"
                        displayKey="text"
                        idKey="value"
                        placeholderColor="bg-white"
                        isClearable={false}
                        isSearchable={false}
                        options={(currentFilter === "*"
                          ? quizSummary.questions
                          : filteredQuestions
                        )?.map((e, index) => {
                          return {
                            text: e["dropdown_text"],
                            value: index,
                          };
                        })}
                        onChange={(e) => {
                          setQuizQuestionIndex(e);
                        }}
                      />
                    </Formik>
                  </div>
                </div>
                {(currentFilter === "*"
                  ? quizSummary?.questions
                  : filteredQuestions)?.[quizQuestionIndex] ? (
                  <QuizReport
                    question={
                      (currentFilter === "*"
                        ? quizSummary?.questions ?? []
                        : filteredQuestions)?.[quizQuestionIndex]
                    }
                  />
                ) : (
                  <div className="flex min-h-[150px]">
                    0 {currentFilter} questions
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  "flex w-full md:justify-end",
                  quizQuestionIndex > 0 ? "justify-between" : "justify-center"
                )}
              >
                <div
                  className={classNames(
                    "flex justify-center space-x-3 w-full",
                    (currentFilter === "*"
                      ? quizSummary?.questions ?? []
                      : filteredQuestions
                    ).length === 0 && "!hidden"
                  )}
                >
                  {quizQuestionIndex > 0 && (
                    <Button
                      onClick={() =>
                        setQuizQuestionIndex((val) => {
                          if (val > 0) {
                            return val - 1;
                          } else {
                            return val;
                          }
                        })
                      }
                      buttonStyle="secondary"
                      height="40px"
                      className="px-0.5 w-full tracking-tighter uppercase md:px-4"
                    >
                      Previous Question
                    </Button>
                  )}
                  {quizQuestionIndex !==
                    (currentFilter === "*"
                      ? quizSummary?.questions
                      : filteredQuestions
                    )?.length -
                      1 && (
                    <Button
                      onClick={() =>
                        setQuizQuestionIndex((val) => {
                          if (
                            val <
                            (currentFilter === "*"
                              ? quizSummary?.questions
                              : filteredQuestions
                            )?.length -
                              1
                          ) {
                            return val + 1;
                          } else {
                            return val;
                          }
                        })
                      }
                      height="40px"
                      className="w-full px-0.5 tracking-tighter md:px-4"
                    >
                      NEXT QUESTION
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {showQuizIntroPopup && (
          <QuizIntroModal
            open={showQuizIntroPopup}
            quiz_id={quizId}
            dismissButtonOnClick={() => setShowQuizIntroPopup(false)}
            secondaryOnClick={() => {
              setShowQuizIntroPopup(false);
              startNextSessionContent();
            }}
            primaryOnClick={async () => {
              // navigate(`/programs/${course_id}/quiz/${quizId}/start`, {
              //   state: {
              //     session_content_id: session_content_id,
              //     course_name: course_name,
              //   },
              // });
              setShowQuizIntroPopup(false);
              if (
                quizWindowRef === null ||
                quizWindowRef === undefined ||
                quizWindowRef?.closed
              ) {
                setQuizWindowRef(
                  window.open(
                    `/programs/${course_id}/quiz/${quizId}/start?id=${session_content_id}&course_name=${course_name}`,
                    "quiz_window",
                    `height=${height},width=${width},left=0,top=0,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,directories=no,status=yes,fullscreen=true`
                  )
                );
              } else {
                quizWindowRef?.focus();
              }
            }}
          />
        )}
        {openReExampPaymentModal !== -1 && (
          <PaymentModal
            open={openReExampPaymentModal !== -1}
            title={`Re-Exam Fee for ${quizSummary["title"]}`}
            entityProps={{
              entity_type: "quiz",
              entity_id: quizSummary["id"],
              part_payment_order: 0,
            }}
            paymentEntityProps={{
              entity_type: "user_external_purchase_detail",
              entity_id: entityId,
            }}
            availablePartPaymentOptions={[{ label: "", part_payment_order: 0 }]}
            onClose={() => {
              setIsLoading(true);
              setEntityId(null);
              if (quizId) {
                fetchQuizSummary();
              } else {
                setIsError(true);
                setIsLoading(false);
                CustomToast.error("Quiz ID is null");
              }
              setOpenReExampPaymentModal(-1);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

QuizSummary.propTypes = {
  course_id: PropTypes.any,
  course_name: PropTypes.string,
  onClickShowCurriculum: PropTypes.func,
  quizId: PropTypes.any,
  session_content_id: PropTypes.any,
  startNextSessionContent: PropTypes.func,
};
export default QuizSummary;
