/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { CorporateMembersColumn } from "../../../Constants";
import EmptyState from "../../../stories/EmptyState";
import Table from "../../../stories/Table";
import AppSkeleton from "../../AppSkeleton";
import EmptyStatePlaceHolder from "../../../assets/svgs/empty_state_bg.svg";
import Button from "../../../stories/Button";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import { useCorporateProfile } from "../../../services/BasicDetails";
import SearchBar from "../../../stories/SearchBar";
import Loader from "../../Shimmer/Loader";
import InviteUserModal from "./InviteUserModal";
import ConfirmRemoveMemberModal from "./ConfirmRemoveMemberModal";
import { CustomToast } from "../../../stories/Toast";
import { useProfile } from "../../../context/userProvider";
import { PublicProfileService } from "../../../services/PublicProfileService";
import SearchUserModal from "../CorporateFlowRegistration/SearchUserModal";

const Users = () => {
  const [openInviteMembers, setOpenInviteMembers] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSearchUserModal, setOpenSearchUserModal] = useState(false);
  const [openConfirmRemoveMemberModal, setOpenConfirmRemoveMemberModal] =
    useState(-1);
  const { userDetails } = useProfile();
  const navigate = useNavigate();
  const {
    loading: corporateProfileLoading,
    corporateProfileData,
    mutate: mutateCorporateProfile,
    corporateError,
  } = useCorporateProfile({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  useEffect(() => {
    if (
      !_.isEmpty(userDetails) &&
      userDetails.firm_signup_status === "not_registered"
    ) {
      CustomToast.error("Please become a corporate admin to access this page");
      navigate("/");
    }
  }, [userDetails]);

  const removeFirmUser = async ({ rowValue }) => {
    try {
      await PublicProfileService.deleteFirmUsers({
        firm_user_list: [rowValue.id],
      });
      CustomToast.success("Member removed from firm successfully");
    } catch (e) {
      console.log(e);
    }
    mutateCorporateProfile();
    setOpenConfirmRemoveMemberModal(-1);
  };

  const addExitingUsers = async (users) => {
    try {
      const response = await PublicProfileService.inviteNewUsers({
        data: {
          id: userDetails.firm_id,
          user_id: users.map((u) => u.id),
        },
      });
      if (
        response.firm_user_data &&
        _.values(response.firm_user_data ?? {}).includes("not_created")
      ) {
        CustomToast.error(
          `${users
            .filter((u) => response.firm_user_data[u.id] !== "created")
            .map((u) => `${u.first_name} ${u.last_name}`)
            .join(", ")} was not invited`
        );
      } else {
        CustomToast.success(response.message);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    } finally {
      mutateCorporateProfile();
    }
  };

  if (corporateProfileLoading) {
    return (
      <AppSkeleton
        pageLabel="Manage Users"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          { name: "Corporate Account", to: "/corporate/profile" },
          { name: "Manage Users", to: "/corporate/users", current: true },
        ]}
      >
        <div className="flex flex-col w-full px-6 py-4 h-fit">
          <Loader />
        </div>
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel="Manage Users"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "Corporate Account", to: "/corporate/profile" },
        { name: "Manage Users", to: "/corporate/users", current: true },
      ]}
    >
      <div className="flex flex-col w-full px-6 py-4 h-fit">
        <div className="flex flex-col rounded h-fit">
          <div className="flex flex-col justify-between p-0 mb-4 lg:p-6 lg:flex-row">
            <div className="flex items-center text-2xl font-bold text-theme-black-color">
              Members
            </div>
            <div className="flex flex-col justify-end w-full gap-3 lg:flex-row">
              <div className="w-full max-w-sm">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder="Search member..."
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
              <div className="flex flex-col gap-2 lg:hidden">
                <Button
                  buttonStyle="secondary"
                  onClick={() => setOpenSearchUserModal(true)}
                >
                  INVITE EXISTING MEMBERS
                </Button>
                <Button onClick={() => setOpenInviteMembers(true)}>
                  INVITE NEW MEMBERS
                </Button>
              </div>
              <div className="hidden gap-2 lg:flex">
                <Button
                  width="220px"
                  buttonStyle="secondary"
                  onClick={() => setOpenSearchUserModal(true)}
                >
                  INVITE EXISTING MEMBERS
                </Button>
                <Button
                  width="220px"
                  onClick={() => setOpenInviteMembers(true)}
                >
                  INVITE NEW MEMBERS
                </Button>
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            <Table
              isLoading={corporateProfileLoading}
              data={corporateProfileData.firm_users?.filter((u) =>
                `${u.user.first_name} ${u.user.last_name} ${u.user.email} ${u.user.mobile_number} ${u.user.city} ${u.status}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )}
              showPagination={true}
              paginationProps={{
                perPage: 10,
              }}
              columns={CorporateMembersColumn({
                admin_id: userDetails.id,
                onRemoveClick: ({ rowIndex }) =>
                  setOpenConfirmRemoveMemberModal(rowIndex),
              })}
              emptyScreen={
                <EmptyState
                  heading="No Contacts"
                  subHeading={`You have not recieved any enquiries till now.`}
                  cta={<></>}
                  image={
                    <img
                      alt="emptyStatePlaceHolder"
                      className="py-9 max-h-80"
                      src={EmptyStatePlaceHolder}
                    />
                  }
                />
              }
            />
          </div>
        </div>
      </div>
      {openInviteMembers && (
        <InviteUserModal
          open={openInviteMembers}
          firmId={corporateProfileData?.firm?.id}
          onClose={() => {
            setOpenInviteMembers(false);
          }}
        />
      )}
      {openConfirmRemoveMemberModal !== -1 && (
        <ConfirmRemoveMemberModal
          open={openConfirmRemoveMemberModal !== -1}
          onConfirm={() => {
            removeFirmUser({
              rowValue: corporateProfileData.firm_users?.filter((u) =>
                `${u.user.first_name} ${u.user.last_name} ${u.user.email} ${u.user.mobile_number} ${u.user.city} ${u.status}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )[openConfirmRemoveMemberModal],
            });
          }}
          onClose={() => {
            setOpenConfirmRemoveMemberModal(-1);
          }}
        />
      )}
      {openSearchUserModal && (
        <SearchUserModal
          open={openSearchUserModal}
          showPreselectedUsers={false}
          preSelectedUsers={corporateProfileData?.firm_users
            ?.filter(
              (e) => e.status !== "new_user_pending" && e.status !== "rejected"
            )
            ?.map((e) => e.user)}
          onAdd={addExitingUsers}
          onClose={() => setOpenSearchUserModal(false)}
        />
      )}
    </AppSkeleton>
  );
};

export default Users;
