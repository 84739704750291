/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../stories/Button";
import Card from "../../../stories/Card";
import ProToolView from "../../../stories/ProToolView";
import VideoIllustration from "../../../assets/svgs/VideoIllustration.svg";
import { CourseService } from "../../../services/CourseService";
import { useNavigate } from "@reach/router";
import QuizIntroModal from "../../../stories/QuizIntroModal";
import ReviewModal from "../../../stories/ReviewModal";
import { CustomToast } from "../../../stories/Toast";
import _ from "lodash";
import VideoPlayer from "../../../stories/VideoPlayer";
import clock_img from "../../../assets/svgs/Clock.svg";
import Loader from "../../Shimmer/Loader";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { User } from "@phosphor-icons/react";
import mixpanel, { get_distinct_id } from "mixpanel-browser";
import { useProfile } from "../../../context/userProvider";

const LockedContent = ({ leftTime, currentSessionContent }) => {
  if (_.isEmpty(currentSessionContent)) return null;
  return (
    <div className="md:w-[261px] lg:w-[560px]">
      <Card
        className="min-w-full mb-6 mr-0 lg:mr-6 lg:mb-0"
        cardType="outline"
        backgroundColor="bg-white lg:bg-theme-gray-300"
        header={
          <div className="flex items-center mb-3 text-2xl font-medium leading-6 tracking-tight text-theme-black-color">
            <div className="flex items-center justify-center mr-4 rounded-full min-w-10 bg-theme-orange bg-opacity-30">
              <img
                src={clock_img}
                className="w-6 h-6 m-2 min-w-[24px]"
                alt="reload_icon"
              />
            </div>
            Coming Soon
          </div>
        }
        cta={<></>}
        cardBody={
          <>
            {!_.isEmpty(currentSessionContent?.session_venue) && (
              <div className="flex flex-col items-start justify-between w-full pl-2 mb-4 ml-12">
                <div className="flex text-sm font-medium tracking-tight text-theme-gray-500">
                  Venue Details
                </div>
                <div className="flex mt-1 text-base font-medium tracking-tight text-theme-black">
                  {currentSessionContent?.session_venue?.name},{" "}
                  {currentSessionContent?.session_venue?.address?.line_1},{" "}
                  {currentSessionContent?.session_venue?.address?.city},{" "}
                  {currentSessionContent?.session_venue?.address?.state} -{" "}
                  {currentSessionContent?.session_venue?.address?.pin}
                </div>
              </div>
            )}
            {leftTime.loading && (
              <div className="flex justify-center w-full">
                <Loader />
              </div>
            )}
            {!leftTime.loading && (
              <>
                <div className="flex justify-start w-full pl-2 ml-12 space-x-6 text-base">
                  {leftTime.days !== 0 && (
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-semibold leading-7">
                        {leftTime.days?.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          minimumIntegerDigits: 2,
                        }) ?? "00"}
                      </div>
                      <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                        DAYS
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col items-center">
                    <div className="text-2xl font-semibold leading-7">
                      {leftTime.hours?.toLocaleString("en-IN", {
                        minimumFractionDigits: 0,
                        minimumIntegerDigits: 2,
                      }) ?? "00"}
                    </div>
                    <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                      HOURS
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-2xl font-semibold leading-7">
                      {leftTime.minutes?.toLocaleString("en-IN", {
                        minimumFractionDigits: 0,
                        minimumIntegerDigits: 2,
                      }) ?? "00"}
                    </div>
                    <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                      MINUTES
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-2xl font-semibold leading-7">
                      {leftTime.seconds?.toLocaleString("en-IN", {
                        minimumFractionDigits: 0,
                        minimumIntegerDigits: 2,
                      }) ?? "00"}
                    </div>
                    <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                      SECONDS
                    </div>
                  </div>
                </div>
                <div className="flex pl-2 ml-12 text-base">
                  Please come back again once content gets unlocked.
                </div>
              </>
            )}
          </>
        }
      />
    </div>
  );
};

const StartQuizCard = ({ currentSessionContent, setShowQuizIntroPopup }) => {
  return (
    <div className="p-4 md:w-72 lg:w-96">
      <Card
        className="min-w-[250px]"
        cardType="outline"
        backgroundColor="bg-white lg:bg-theme-gray-300"
        header={
          <div className="flex items-center text-base font-medium leading-none tracking-tighter text-theme-black-color">
            {currentSessionContent.title}
          </div>
        }
        cta={
          <Button
            buttonStyle="primary"
            size="xl"
            width="100%"
            className="transition duration-100 hover:shadow-md"
            onClick={() => {
              if (currentSessionContent.quiz_type === "final_exam") {
                window.open(
                  currentSessionContent.external_link_details.details.link,
                  "_blank"
                );
                return;
              }
              if (currentSessionContent.quiz_id) {
                setShowQuizIntroPopup(true);
              } else {
                CustomToast.error("Quiz ID not present");
              }
            }}
          >
            START QUIZ
          </Button>
        }
        buttonLabel="START QUIZ"
        buttonStyle="primary"
        cardBody={<div className="p-2"></div>}
      />
    </div>
  );
};

const StartZoomSessionCard = ({ currentSessionContent }) => {
  const navigate = useNavigate();
  const dateString = (
    <React.Fragment>
      {moment(
        currentSessionContent.external_link_details.details.start_time
      ).format("hh:mm A")}{" "}
      -{" "}
      {moment(
        currentSessionContent.external_link_details.details.end_time
      ).format("hh:mm A")}{" "}
      <br />
      {moment(
        currentSessionContent.external_link_details.details.start_time
      ).format("dddd, DD MMMM")}
    </React.Fragment>
  );

  return (
    <div className="w-full md:w-[261px] lg:w-[328px]">
      <Card
        className="min-w-full mb-6 mr-0 lg:mr-6 lg:mb-0"
        cardType="outline"
        backgroundColor="bg-white lg:bg-theme-gray-300"
        header={
          <div className="flex items-center text-base font-medium leading-none tracking-tighter text-theme-black-color">
            {currentSessionContent.title}
          </div>
        }
        cta={
          <Button
            buttonStyle="primary"
            size="xl"
            width="100%"
            className="transition duration-100 hover:shadow-md"
            onClick={() => {
              if (currentSessionContent.has_zoom_sesssion) {
                navigate("/watch-live-session", {
                  state: {
                    course_session_content_id: currentSessionContent.id,
                    leaveURL: window.location.href,
                  },
                });
                return;
              }
              window.open(
                currentSessionContent.external_link_details.details.link,
                "_blank"
              );
            }}
          >
            JOIN ZOOM SESSION
          </Button>
        }
        buttonLabel="VIEW ALL PROGRAMS"
        buttonStyle="primary"
        cardBody={
          <>
            <div className="flex flex-col mt-3 mb-5 text-sm leading-5 tracking-tight text-theme-black-300">
              {dateString}
            </div>
            <div className="flex items-center flex-shrink-0 mb-5">
              <div className="flex items-center justify-center rounded-full bg-theme-red-200/25 h-7 w-7 lg:h-10 lg:w-10">
                <div className="w-5 h-5 min-w-[20px] text-xl leading-none text-theme-red-300 flex rounded-full justify-center items-center ">
                  {currentSessionContent?.author_profile_picture?.url ? (
                    <img
                      src={currentSessionContent?.author_profile_picture?.url}
                      alt="author_img"
                    />
                  ) : (
                    <User
                      weight="bold"
                      className="w-6 h-6 text-theme-red-300"
                    />
                  )}
                </div>
              </div>
              <span className="pr-2.5 font-medium text-sm text-theme-black-color ml-2">
                {currentSessionContent.author_name}
              </span>
            </div>
          </>
        }
      />
    </div>
  );
};

const PostEventCard = () => {
  return (
    <div className="md:w-[261px] lg:w-[450px]">
      <Card
        className="min-w-full mb-6 mr-0 lg:mr-6 lg:mb-0"
        cardType="outline"
        backgroundColor="bg-white lg:bg-theme-gray-300"
        header={
          <div className="flex items-center mb-3 text-2xl font-medium leading-6 tracking-tight text-theme-black-color">
            <div className="flex items-center justify-center mr-4 rounded-full min-w-10 bg-theme-orange bg-opacity-30">
              <img
                src={clock_img}
                className="w-6 h-6 m-2 min-w-[24px]"
                alt="reload_icon"
              />
            </div>
            Uploading Soon
          </div>
        }
        cta={<></>}
        cardBody={
          <>
            <div className="flex pl-2 ml-12 text-base">
              You can go through the recordings once its uploaded after event
              ends.
            </div>
          </>
        }
      />
    </div>
  );
};

const VideoNotUploadedComponent = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full space-y-6 bg-gray-200">
      <img className="" src={VideoIllustration} alt="course thumbnail" />
      <div className="flex text-lg font-semibold tracking-tight text-theme-black-color">
        {message}
      </div>
    </div>
  );
};

const SessionContentComponent = ({
  course_id,
  session_content_id,
  currentSessionContent,
  onPostReview,
  courseName,
  eventDetails,
  courseDataRef,
  currentPlayingState = {},
  startNextSessionContent,
  mutateCourseStats,
  hasRatedBefore,
}) => {
  const { width, height } = useWindowSize();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showQuizIntroPopup, setShowQuizIntroPopup] = useState(false);
  const [duration, setDuration] = useState(0);
  const [quizWindowRef, setQuizWindowRef] = useState();
  const [shouldReload, setShouldReload] = useState(false);
  const { userDetails } = useProfile();

  useEffect(() => {
    if (shouldReload) {
      const error_message =
        localStorage.getItem(
          `quiz_${currentSessionContent.quiz_id}_error_message`
        ) ?? null;
      if (error_message) {
        localStorage.removeItem(
          `quiz_${currentSessionContent.quiz_id}_error_message`
        );
        CustomToast.error(error_message);
        setTimeout(() => window.location.reload(), 2000);
      } else {
        setTimeout(() => window.location.reload(), 1000);
      }
    }
  }, [shouldReload]);

  useEffect(() => {
    if (quizWindowRef) {
      let intervalId;
      intervalId = setInterval(async () => {
        if (quizWindowRef?.closed) {
          const isQuizOpened = localStorage.getItem(
            `quiz_${currentSessionContent.quiz_id}_opened`
          );
          const submitStatus = localStorage.getItem(
            `quiz_${currentSessionContent.quiz_id}_submit_status`
          );
          if (isQuizOpened) {
            if (submitStatus === "not_submitted") {
              if (
                localStorage.getItem(
                  `quiz_time_spent_${currentSessionContent.quiz_id}`
                )
              ) {
                const responseData = {
                  quiz_id: currentSessionContent.quiz_id,
                  is_submit: true,
                  seconds_spent:
                    localStorage.getItem(
                      `quiz_time_spent_${currentSessionContent.quiz_id}`
                    ) ?? 0,
                };
                await CourseService.submitQuizQuestionResponse(responseData);
              }
            }
          }
          localStorage.removeItem(
            `quiz_${currentSessionContent.quiz_id}_opened`
          );
          localStorage.removeItem(
            `quiz_time_spent_${currentSessionContent.quiz_id}`
          );
          localStorage.removeItem(
            `quiz_${currentSessionContent.quiz_id}_submit_status`
          );
          setShowQuizIntroPopup(false);
          setShouldReload(true);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }, [quizWindowRef]);

  const updateCourseProgress = async (status, duration = 0, id) => {
    await CourseService.updateCourseProgress({
      course_id: course_id,
      last_viewed_content: {
        course_session_content_id: id,
        last_seen_in_sec: duration,
      },
      progress: {
        course_content_id: `${parseInt(id)}`,
        value: { progress_in_sec: parseInt(duration), status: status },
      },
    });
    if (!currentSessionContent.progress?.status) {
      mixpanel.track("Session Content View", {
        distinct_id: userDetails.email,
        "Course Session Content ID": currentSessionContent.id,
        "Course Session Content Name": currentSessionContent.title,
        "User ID": userDetails.id,
        $email: userDetails.email,
      });
    }
  };

  const updateVideoProgress = _.debounce(updateCourseProgress, 60000, {
    maxWait: 60000,
  });

  const VideoComponent = ({ currentSessionContent }) => {
    const [lastUpdateTime, setLastTimeUpdate] = useState(0);
    const videoURL = currentSessionContent.attachment?.url;

    if (_.isEmpty(videoURL))
      return (
        <VideoNotUploadedComponent message="Please wait. Video will be uploaded shortly" />
      );

    return (
      <VideoPlayer
        src={currentSessionContent.attachment?.url}
        className="w-full h-full"
        startTime={currentSessionContent?.progress?.progress_in_sec ?? 0}
        onProgress={(duration) => {
          if (_.toNumber(duration.playedSeconds) > lastUpdateTime) {
            updateVideoProgress(
              "in_progress",
              duration.playedSeconds,
              currentSessionContent.id
            )?.then((res) =>
              setLastTimeUpdate(_.toNumber(duration.playedSeconds))
            );
          }
        }}
        onDuration={(duration) => {
          setDuration(duration);
        }}
        onEnded={(e) => {
          if (currentSessionContent?.progress?.status !== "completed") {
            if (!hasRatedBefore) {
              setShowReviewModal(true);
            }
            updateVideoProgress(
              "completed",
              duration,
              currentSessionContent.id
            )?.then((res) => {
              mutateCourseStats();
              setLastTimeUpdate(_.toNumber(duration));
            });
          }
        }}
      />
    );
  };

  const SessionVideoComponent = () => {
    if (
      !_.isEmpty(currentSessionContent.external_link_details?.status) ||
      currentSessionContent.has_zoom_sesssion
    ) {
      if (currentSessionContent.external_link_details.status === "completed")
        return (
          <VideoNotUploadedComponent
            message={currentSessionContent.external_link_details.message ?? ""}
          />
        );

      return (
        <StartZoomSessionCard currentSessionContent={currentSessionContent} />
      );
    }

    if (currentSessionContent.attachment) {
      return <VideoComponent currentSessionContent={currentSessionContent} />;
    } else {
      return <div>No Session Details Found</div>;
    }
  };

  const SessionMediaComponent = () => {
    const [leftTime, setLeftTime] = useState({ loading: true });
    const startTime = useRef();
    const endTime = useRef();
    useEffect(() => {
      let intervalId;
      if (
        eventDetails &&
        new Date(eventDetails.start_time) > new Date() &&
        !courseDataRef.current.course.modules[
          currentPlayingState.currentModuleIndex
        ]?.sessions[currentPlayingState.currentSessionIndex]
          ?.is_past_recording_session
      ) {
        startTime.current = eventDetails?.start_time;
      } else {
        startTime.current =
          currentSessionContent?.external_link_details?.details?.start_time;
        endTime.current =
          currentSessionContent?.external_link_details?.details?.end_time;
      }

      if (startTime.current) {
        intervalId = setInterval(() => {
          let delta = Math.abs(new Date() - new Date(startTime.current)) / 1000;

          // calculate (and subtract) whole days
          const days = Math.floor(delta / 60 / 60 / 24);
          delta -= days * 3600 * 24;
          // calculate (and subtract) whole hours
          let hours = Math.floor(delta / 60 / 60);
          delta -= hours * 3600;

          // calculate (and subtract) whole minutes
          const minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;

          // what's left is seconds
          const seconds = Math.floor(delta % 60);
          if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            setLeftTime({ stop: true });
            clearInterval(intervalId);
          } else {
            setLeftTime({ days, hours, minutes, seconds });
          }
        }, 1000);
      }

      return () => clearInterval(intervalId);
    }, []);
    if (
      !leftTime.stop &&
      startTime.current &&
      new Date(startTime.current) > new Date()
    ) {
      return (
        <LockedContent
          leftTime={leftTime}
          currentSessionContent={currentSessionContent}
        />
      );
    }
    if (
      currentSessionContent.session_venue &&
      endTime.current &&
      new Date(endTime.current) < new Date() &&
      !currentSessionContent.attachment
    ) {
      return <PostEventCard />;
    }
    switch (currentSessionContent.kind) {
      case "video":
        return <SessionVideoComponent />;
      case "quiz":
        return (
          <StartQuizCard
            currentSessionContent={currentSessionContent}
            setShowQuizIntroPopup={setShowQuizIntroPopup}
          />
        );
      case "protool":
        return (
          <ProToolView
            sessionID={currentSessionContent.id}
            url={currentSessionContent?.attachment?.url}
            updateCourseProgress={updateCourseProgress}
            isDownloadable={currentSessionContent.is_downloadable ?? true}
            className="h-[175px] sm:h-[220px] lg:h-[450px]"
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="flex mt-0 relative lg:mt-8 mb-3.5 lg:mb-6 w-full items-center justify-center bg-gray-200 min-h-[175px] lg:min-h-[450px] sm:min-h-[220px]">
      <SessionMediaComponent />
      {showQuizIntroPopup && (
        <QuizIntroModal
          open={showQuizIntroPopup}
          quiz_id={currentSessionContent.quiz_id}
          dismissButtonOnClick={() => setShowQuizIntroPopup(false)}
          secondaryOnClick={() => {
            setShowQuizIntroPopup(false);
            startNextSessionContent();
          }}
          primaryOnClick={async () => {
            if (currentSessionContent.quiz_id) {
              if (
                quizWindowRef === null ||
                quizWindowRef === undefined ||
                quizWindowRef?.closed
              ) {
                setQuizWindowRef(
                  window.open(
                    `/programs/${course_id}/quiz/${currentSessionContent.quiz_id}/start?id=${session_content_id}&course_name=${courseName}`,
                    "quiz_window",
                    `height=${height},width=${width},left=0,top=0,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,directories=no, status=yes`
                  )
                );
              } else {
                quizWindowRef?.focus();
              }
              // navigate(
              //   `/programs/${course_id}/quiz/${currentSessionContent.quiz_id}/start`,
              //   {
              //     state: {
              //       session_content_id: session_content_id,
              //       course_name: courseName,
              //     },
              //   }
              // );
            } else {
              CustomToast.error("Quiz ID not present");
            }
          }}
        />
      )}
      {showReviewModal && (
        <ReviewModal
          open={showReviewModal}
          onPostReview={onPostReview}
          authorName={currentSessionContent.author_name}
          dismissButtonOnClick={(isSubmitted) => {
            setShowReviewModal(false);
            if (isSubmitted !== true) {
              startNextSessionContent();
            }
          }}
        />
      )}
    </div>
  );
};

SessionContentComponent.propTypes = {
  courseDataRef: PropTypes.shape({
    current: PropTypes.shape({
      course: PropTypes.shape({
        modules: PropTypes.any,
      }),
    }),
  }),
  courseName: PropTypes.string,
  course_id: PropTypes.any,
  currentSessionContent: PropTypes.shape({
    attachment: PropTypes.shape({
      url: PropTypes.string,
    }),
    author_name: PropTypes.string,
    author_profile_picture: PropTypes.shape({
      url: PropTypes.string,
    }),
    external_link_details: PropTypes.shape({
      details: PropTypes.shape({
        end_time: PropTypes.string,
        link: PropTypes.string,
        start_time: PropTypes.string,
      }),
      message: PropTypes.string,
      status: PropTypes.string,
    }),
    id: PropTypes.any,
    is_downloadable: PropTypes.bool,
    kind: PropTypes.string,
    progress: PropTypes.shape({
      progress_in_sec: PropTypes.number,
      status: PropTypes.string,
    }),
    quiz_id: PropTypes.any,
    session_venue: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.any,
        line_1: PropTypes.string,
        pin: PropTypes.any,
        state: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
    title: PropTypes.any,
  }),
  currentPlayingState: PropTypes.object,
  eventDetails: PropTypes.shape({
    start_time: PropTypes.any,
  }),
  onPostReview: PropTypes.func,
  session_content_id: PropTypes.any,
  startNextSessionContent: PropTypes.func,
  hasRatedBefore: PropTypes.bool,
};

export default SessionContentComponent;
