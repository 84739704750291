/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles/App.scss";
import { Router, globalHistory, useNavigate } from "@reach/router";
import SignUpScreen from "./components/Onboarding/SignUpScreen";
import CompleteSignUpScreen from "./components/Onboarding/CompleteSignUpScreen";
import ForgetPasswordScreen from "./components/Onboarding/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/Onboarding/ResetPassword";
import DashboardScreen from "./components/Dashboard";
import DefaultScreen from "./components/DefaultScreen";
import PublicProfile from "./components/PublicProfile";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import ResendVerification from "./components/Onboarding/ResendVerification";
import { Toaster } from "react-hot-toast";
import EventRegistration from "./components/Events";
import MyPrograms from "./components/Programs";
import CirriculumPage from "./components/Programs/CirriculumPage";
import ViewCourse from "./components/Programs/ViewCourse";
import { User } from "./store";
import Quiz from "./components/Quiz";
import MyPayments from "./components/MyPayments";
import NestedRoute from "./components/Routes/NestedRoute";
import { ProfileProvider } from "./context/userProvider";
import { UserService } from "./services/UserService";
import Register from "./components/Register";
import Certificates from "./components/Certificates";
import ClientConnect from "./components/ClientConnect";
import NFPProtools from "./components/NFPProtools";
import NFPResources from "./components/NFPResources";
import GetStartedScreen from "./components/Onboarding/GetStartedScreen";
import EventRSVP from "./components/Events/RSVP";
import GuestList from "./components/GuestList";
import VoluntaryContribution from "./components/VoluntaryContribution";
import CorporateFlow from "./components/CorporateFlow";
import CorporateFlowRegistration from "./components/CorporateFlow/CorporateFlowRegistration";
import FirmInviteUpdate from "./components/CorporateFlow/FirmInviteUpdate";
import CorporateProfile from "./components/CorporateFlow/Profile";
import Users from "./components/CorporateFlow/Users";
import CorporatePayments from "./components/CorporateFlow/Payments";
import LandingPage from "./components/LandingPage";
import RedirectToSessionContent from "./components/Programs/ViewCourse/RedirectToSessionContent";
import NFPCalendar from "./components/NFPCalendar";
import WatchLiveSession from "./stories/WatchLiveSession";
import GoogleOAuth from "./stories/GoogleOAuth";
import RedirectTo from "./stories/RedirectTo";
import ExchangeForum from "./components/ExchangeForum";
import CreateForumModal from "./components/Modals/CreateForumModal";
import ViewForum from "./components/ExchangeForum/ViewForum";
import { getTokenFromFirebase } from "./firebase";
import Notifications from "./components/Notifications";
import UnbounceOnboarding from "./components/Onboarding/UnbounceOnboarding";
import UnbounceOnboardingThankyou from "./components/Onboarding/UnbounceOnboardingThankyou";
import EventRegistrationThankyouScreen from "./components/Events/EventRegistrationThankyouScreen";
import CoroprateGuestList from "./components/CorporateFlow/GuestList";
import MembershipRegistration from "./components/MembershipRegistration";
import AppPayment from "./components/AppPayment";
import EmailVerification from "./components/Onboarding/EmailVerification";
import { Redirect } from "@reach/router";
import EmailLoginScreen from "./components/Onboarding/EmailLoginScreen";
import OTPLoginScreen from "./components/Onboarding/OTPLoginScreen";
import { TourProvider } from "./context/tourProvider";
import mixpanel from "mixpanel-browser";
import { initTrackers, trackPageView } from "./util/Tracker";
import moment from "moment";
import QPFPCertificateBadges from "./components/Programs/QPFPCertificateBadges";
import { Leaderboard } from "./components/Programs/Leaderboard";
import QPFPCertification from "./components/QPFPCertification";
import QPFPCertificationRegistration from "./components/QPFPCertification/Registration";
import QPFPCertificationDashboard from "./components/QPFPCertification/Dashboard";
import QPFPBrandGuidlines from "./components/QPFPCertification/BrandGuidlines";
import QPFPCourseware from "./components/QPFPCertification/QPFPCourseware";
import QPFPCertificationRenewal from "./components/QPFPCertification/Renewal";
import ACEPointsTracker from "./components/QPFPCertification/ACEPointsTracker";
import ACEProgramAndTests from "./components/QPFPCertification/ACEProgramAndTests";
import ACEProgramDetail from "./components/QPFPCertification/ACEProgramAndTests/ACEProgramDetail";
import CertificateAndBadges from "./components/QPFPCertification/CertificateAndBadges";

const App = () => {
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    initTrackers();
    globalHistory.listen((_) => {
      trackPageView();
    });
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    if (!!User.getToken()) {
      getTokenFromFirebase();
      const tempData = {};
      try {
        const result = await UserService.fetchUserDetails();
        tempData["userDetails"] = result ?? {};
        mixpanel.identify(result["email"]);
        mixpanel.people.set({
          $email: result["email"],
          $name: `${result["first_name"]} ${result["last_name"]}`,
          "User ID": result["id"],
          "Phone number": result["phone_number"],
          "Profile Complete Percentage": result["profile_complete_percentage"],
          "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
        });
      } catch (e) {
        console.log(e);
      }
      setInitialData(tempData);
    }
  };

  useEffect(() => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [window?.location?.href]);

  return (
    <div className="App">
      <TourProvider>
        <ProfileProvider initialValue={initialData}>
          <Router>
            {/* Onboarding routes */}
            <LandingPage path="/pricing" />
            <GetStartedScreen path="get-started" />
            <UnbounceOnboarding path="onboarding" />
            <UnbounceOnboardingThankyou path="onboarding/thank_you" />
            <Redirect from="/login" to="/login/otp" noThrow />
            <PublicRoute component={OTPLoginScreen} path="login/otp" />
            <PublicRoute component={EmailLoginScreen} path="login/email" />
            <PublicRoute component={SignUpScreen} path="signup" />
            <PublicRoute component={EmailVerification} path="verify-email" />
            <PublicRoute
              component={CompleteSignUpScreen}
              path="complete-signup"
            />
            <PublicRoute
              component={ForgetPasswordScreen}
              path="forgot-password"
            />
            <PublicRoute
              component={ResetPasswordScreen}
              path="reset-password"
            />
            {/* <PublicRoute component={VerifyEmail} path="verify-email" /> */}
            <PublicRoute component={ResendVerification} path="resend-email" />

            <ProtectedRoute component={PublicProfile} path="public-profile" />
            <ProtectedRoute component={DashboardScreen} path="/" />

            {/* Courses routes */}
            <NestedRoute path="programs">
              <ProtectedRoute component={MyPrograms} path="/" />
              <NestedRoute path=":course_id">
                <ProtectedRoute component={CirriculumPage} path="/" />
                <ProtectedRoute
                  component={QPFPCertificateBadges}
                  path="/certificates"
                />
                <ProtectedRoute component={Leaderboard} path="/leaderboard" />
                <NestedRoute path="learn">
                  <ProtectedRoute
                    component={RedirectToSessionContent}
                    path="/"
                  />
                  <ProtectedRoute
                    component={ViewCourse}
                    path=":session_content_id"
                  />
                </NestedRoute>
                <ProtectedRoute component={Quiz} path="quiz/:quiz_id/start" />
              </NestedRoute>
            </NestedRoute>
            <ProtectedRoute
              component={ClientConnect}
              path="nfp-clientconnect"
            />
            <ProtectedRoute component={NFPProtools} path="nfp-protools" />
            <ProtectedRoute component={NFPResources} path="nfp-resources" />

            {/* Registration routes */}
            <EventRegistration path="events/:event_id/register" />
            <EventRegistrationThankyouScreen path="events/:event_id/register/thank-you" />
            <ProtectedRoute
              component={VoluntaryContribution}
              path="voluntary/:contribution_id/register"
            />
            <ProtectedRoute
              component={EventRSVP}
              path="events/:event_id/rsvp"
            />
            <ProtectedRoute
              component={EventRegistrationThankyouScreen}
              path="events/:event_id/rsvp/thank-you"
            />
            <MembershipRegistration path="members/:entity_type/register" />

            {/* My Account routes */}
            <ProtectedRoute component={MyPayments} path="payments" />
            <ProtectedRoute component={GuestList} path="guest-list" />
            <ProtectedRoute component={Register} path="register" />
            <ProtectedRoute component={Certificates} path="certificates" />
            {/* <ProtectedRoute component={AcePoints} path="ace-points" /> */}
            <ProtectedRoute component={NFPCalendar} path="nfp-calendar" />
            <ProtectedRoute component={Notifications} path="notifications" />

            {/* QPFP Certification routes */}
            <NestedRoute path="qpfp-certificant">
              <ProtectedRoute component={QPFPCertification} path="/" />
              <ProtectedRoute
                component={QPFPCertificationRegistration}
                path="/registration"
              />
              <ProtectedRoute
                component={QPFPCertificationDashboard}
                path="/dashboard"
              />
              <ProtectedRoute
                component={CertificateAndBadges}
                path="/certificates"
              />
              <ProtectedRoute
                component={QPFPBrandGuidlines}
                path="/brand-guidlines"
              />
              <ProtectedRoute component={QPFPCourseware} path="/courseware" />

              <ProtectedRoute
                component={ACEPointsTracker}
                path="/ace-points-tracker"
              />

              {/* QPFP ACE flow routes */}
              <NestedRoute path="ace-program-and-tests">
                <ProtectedRoute component={ACEProgramAndTests} path="/" />
                <ProtectedRoute
                  component={ACEProgramDetail}
                  path=":ace_program_id"
                />
              </NestedRoute>

              <ProtectedRoute
                component={QPFPCertificationRenewal}
                path="/renewal"
              />
            </NestedRoute>

            {/* Corporate flow routes */}
            <NestedRoute path="corporate">
              <ProtectedRoute component={CorporateFlow} path="/" />
              <ProtectedRoute
                component={FirmInviteUpdate}
                path="/update_firm_invitation"
              />
              <ProtectedRoute
                component={CorporateFlowRegistration}
                path="/register"
              />
              <ProtectedRoute component={CorporateProfile} path="/profile" />
              <ProtectedRoute component={CorporatePayments} path="/payments" />
              <ProtectedRoute component={Users} path="/users" />
              <ProtectedRoute
                component={CoroprateGuestList}
                path="/guest-list"
              />
            </NestedRoute>
            <WatchLiveSession path="/watch-live-session" />
            <GoogleOAuth path="/google-auth" />
            <RedirectTo path="/redirectTo/:from" />
            <ExchangeForum path="/exchange">
              <ProtectedRoute
                component={CreateForumModal}
                path="ask-question"
              />
              <ProtectedRoute
                component={CreateForumModal}
                path="share-something"
              />
            </ExchangeForum>
            <ProtectedRoute
              component={ViewForum}
              path="/exchange/:exchange_id/discuss"
            />

            {/* App Payment route */}
            <AppPayment path="/app/payment" />
            <DefaultScreen default />
          </Router>
          <Toaster />
        </ProfileProvider>
      </TourProvider>
    </div>
  );
};

export default App;
