/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import Label from "./Label";
import { AsteriskSimple } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";
import Tooltip from "./Tooltip";

const TextInput = ({
  showErrorIcon = false,
  infoContent = "",
  disable = false,
  height = "h-10",
  label = "Label",
  isRequired,
  placeholder = "",
  labelClassName = "",
  className,
  name,
  type,
  leadingComponent = undefined,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const textInputRef = useRef();

  const invalidChars = ["-", "+", "e", "E"];
  const checkChars = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (props.autoFocus) textInputRef.current.focus();
  }, []);

  return (
    <div className={classNames("flex flex-col items-start w-full ", className)}>
      <div className="relative w-full">
        <Label
          labelFor={name}
          className={classNames(
            "flex items-center text-left text-xs leading-none font-medium text-theme-black-300",
            labelClassName
          )}
        >
          {label ?? "Text Input"}
          {isRequired && (
            <AsteriskSimple
              size={8}
              weight="bold"
              className="mb-2 text-theme-red"
            />
          )}
          {infoContent && <Tooltip infoContent={infoContent} />}
        </Label>
        <div
          className={classNames(
            "border mt-1 flex focus:border rounded shadow-sm overflow-clip",
            meta.touched && meta.error
              ? "border-theme-red-300 focus:ring-theme-red-300 focus:border-theme-red-300"
              : "border-gray-200 focus:ring-gray-900 focus:border-theme-black-color"
          )}
        >
          {/* {leadingComponent && (
            <div className="inline-flex">{leadingComponent}</div>
          )} */}
          <input
            {...field}
            {...props}
            className={classNames(
              "inline-flex items-center justify-between text-left text-sm tracking-normal text-theme-black-color font-medium p-2.5 pr-3 w-full !border-none focus:ring-0 focus:outline-none placeholder:text-neutral-400 placeholder:font-normal placeholder:tracking-normal",
              type === "password" ? "tracking-widest" : "tracking-tight",
              height ?? "h-10"
            )}
            placeholder={placeholder ?? label}
            onKeyDown={(e) => (type === "number" ? checkChars(e) : "")}
            name={name}
            type={type}
            ref={textInputRef}
            disabled={!!disable}
            value={field.value ?? ""}
          />
        </div>
      </div>
      <ShowFormError name={name} />
    </div>
  );
};

TextInput.defaultProps = {
  showErrorIcon: false,
  infoContent: "",
  disable: false,
  height: "h-10",
  label: "Label",
  isRequired: false,
  placeholder: "",
  className: "",
  name: "",
  type: "",
};

TextInput.propTypes = {
  showErrorIcon: PropTypes.bool,
  infoContent: PropTypes.string,
  disable: PropTypes.bool,
  height: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};
export default TextInput;

TextInput.propTypes = {
  className: PropTypes.any,
  disable: PropTypes.bool,
  height: PropTypes.string,
  infoContent: PropTypes.string,
  isRequired: PropTypes.any,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.any,
  placeholder: PropTypes.string,
  showErrorIcon: PropTypes.bool,
  type: PropTypes.string,
};
