/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "@reach/router";
import { Formik, Form } from "formik";
import OnboardingHeader from "../../stories/OnboardingHeader";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import { AuthService } from "../../services/AuthService";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { CustomToast } from "../../stories/Toast";
import { Validations } from "../../constants/validations";
import QueryString from "qs";
import LoginUserModal from "../Modals/LoginUserModal";
import RedirectToRegistrationModal from "../Modals/RedirectToRegistrationModal";
import LoginLockModal from "../Modals/LoginLockModal";
import { User } from "../../store";
import { useProfile } from "../../context/userProvider";
import _ from "lodash";
import LoadingModal from "../Modals/LoadingModal";
import GetStartedRightContainer from "../../stories/GetStartedRightContainer";
import { ContentService } from "../../services/ContentService";

const GetStartedScreen = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = QueryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );
  const { userDetails } = useProfile();
  const [showLoginPopup, setShowLoginPopup] = useState();
  const [showLoginLockPopup, setShowLoginLockPopup] = useState();
  const [showRedirectPopup, setShowRedirectPopup] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [textContent, setTextContent] = useState({});
  useDocumentTitle("Get Started");

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    reference: parsed.ref,
  });

  useEffect(() => {
    const token = User.getToken();
    setIsLoading(!!token);
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await ContentService.fetchLandingPageContent();
    const data = response.dynamic_contents;
    const pricingData = data.filter((d) =>
      ["basic", "community", "qpfp", "pro_membership"].includes(d.kind)
    );
    const tooltipData = data
      .filter((d) => d.kind === "tool_tip")
      .map((e) => ({ ...e, section: _.last(e.section.split("_")) }));

    const pricingObj = {};
    pricingData.forEach((d) => {
      if (d.section.toLowerCase() === "description") {
        return;
      }
      if (d.section.includes("||")) {
        const key1 = d.section.split("||")[0].trim();
        const key2 = d.section.split("||")[1].trim();
        const order1 = d.order.split(".")[0];
        const order2 = d.order.split(".")[1];

        if (!pricingObj[key1]) {
          pricingObj[key1] = {
            heading: key1.trim(),
            data: {},
            order: parseInt(order1),
          };
        }
        if (!pricingObj[key1]["data"][key2]) {
          pricingObj[key1]["data"][key2] = {
            label: key2,
            tooltip: _.head(
              tooltipData.filter((e) => e.section.trim() === key2)
            )?.value,
            type: ["true", "false"].includes((d.value ?? "").toLowerCase())
              ? "checkbox"
              : false,
            basic: false,
            community: false,
            qpfp: false,
            pro_membership: false,
            order: parseInt(order2),
          };
        }
        pricingObj[key1]["data"][key2][d.kind] =
          (d.value ?? "").toLowerCase() === "true"
            ? true
            : (d.value ?? "").toLowerCase() === "false"
            ? false
            : d.value ?? "";
      }
    });
    setTextContent({
      data: _.sortBy(
        _.values(pricingObj).map((e) => ({
          heading: e.heading,
          order: e.order,
          data: _.sortBy(_.values(e.data), "order"),
        })),
        "order"
      ),
    });
  };

  useEffect(() => {
    const token = User.getToken();
    if (token && !_.isEmpty(userDetails)) {
      const submitDetails = async () => {
        const body = {
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          email: userDetails.email,
          phone_number: userDetails.phone_number,
          reference: parsed.ref,
        };
        setInitialValues(body);
        await handleSubmit(body);
      };
      submitDetails();
    }
  }, [userDetails]);

  const handleSubmit = async (values) => {
    try {
      let body = { ...values };
      if (parsed.ref) {
        body = { ...body, reference: parsed.ref };
      }
      const result = await AuthService.visitorEnquiry(body);
      const token = User.getToken();
      if (token) {
        redirectUser();
        return;
      }
      setInitialValues(values);
      setShowLoginPopup(result.email_present && result.signup_completed);
      setShowLoginLockPopup(result.email_present && !result.signup_completed);
      setShowRedirectPopup(!result.email_present);
      if (result.email_present && result.signup_completed) {
        CustomToast.info("Email already registered with us. Please login");
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  const redirectUser = () => {
    const reference = parsed.ref;
    const entity_type = reference.split("__")[0];
    const entity_id = reference.split("__")[1];
    if (entity_type === "event") {
      navigate(`events/${entity_id}/register`, {
        replace: true,
        state: { prefill_data: initialValues },
      });
    } else if (entity_type === "qpfp") {
      navigate(`/members/${entity_type}/register`, {
        replace: true,
        state: { course_id: entity_id, prefill_data: initialValues },
      });
    } else {
      navigate(`/members/${entity_type}/register`, {
        replace: true,
        state: { prefill_data: initialValues },
      });
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Get Started
            </div>
            <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Enter your details to continue with Membership / Event
              Registration
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                Validations.onBoarding.visitorEnquiryValidationSchema
              }
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-full mt-8 md:mt-12">
                    <AuthTextInput
                      name="first_name"
                      id="fname"
                      type="text"
                      label="First Name"
                    />
                  </div>
                  <div className="w-full mt-5">
                    <AuthTextInput
                      name="last_name"
                      id="lname"
                      type="text"
                      label="Last Name"
                    />
                  </div>
                  <div className="w-full mt-5">
                    <AuthTextInput
                      name="email"
                      id="email"
                      type="text"
                      label="Email"
                      autoComplete="email"
                    />
                  </div>
                  <div className="w-full mt-5">
                    <AuthTextInput
                      showLeading
                      name="phone_number"
                      id="phone_number"
                      type="text"
                      label="Mobile No."
                      leadingComponent={
                        <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                          +91
                        </span>
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    buttonStyle="primary"
                    height="60px"
                    width="100%"
                    className="mt-6"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    CONITNUE
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <GetStartedRightContainer
          content={textContent}
          className="hidden w-full h-full lg:flex"
        />
      </div>
      {showLoginPopup && (
        <LoginUserModal
          className=""
          open={showLoginPopup}
          prefill_data={{ email: initialValues.email }}
          onLoginSuccess={() => {
            redirectUser();
          }}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
      {showRedirectPopup && (
        <RedirectToRegistrationModal
          className=""
          open={showRedirectPopup}
          onSubmit={() => {
            redirectUser();
          }}
          onClose={() => setShowRedirectPopup(false)}
        />
      )}
      {showLoginLockPopup && (
        <LoginLockModal
          className=""
          open={showLoginLockPopup}
          onSubmit={() => {
            redirectUser();
          }}
          onClose={() => setShowLoginLockPopup(false)}
        />
      )}
      {isLoading && <LoadingModal open={isLoading} />}
    </div>
  );
};

export default GetStartedScreen;
