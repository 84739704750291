/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import "../index.css";
import React, { useEffect } from "react";
import playIcon from "../assets/svgs/play_icon.svg";
import quizIcon from "../assets/svgs/quiz_icon.svg";
import { ArrowDown, Check, File, PlayCircle } from "@phosphor-icons/react";
import { Star } from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import DownloadActionBar from "./DownloadActionBar";
import classNames from "classnames";
import { useWindowSize } from "../hooks/useWindowSize";
const TopicComponent = ({
  isCompleted = false,
  courseId,
  courseDataRef,
  sessionContentId,
  title = "Topic Title",
  isDownloadable,
  type,
  speaker,
  sessionTime,
  ratings,
  subChildren,
  url,
  currentState,
  className = "",
}) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const onChecked = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(isCompleted);
  }, [isCompleted]);

  return (
    <div
      id={"topic_component_" + sessionContentId}
      className={classNames(
        "container flex items-start w-full max-w-md p-3 space-x-4 align-top rounded-md cursor-pointer bg-gray-50",
        currentState.currentSessionContentId === sessionContentId &&
          "border border-gray-400 shadow-sm !bg-gray-100",
        className
      )}
      onClick={() => {
        navigate(`/programs/${courseId}/learn/${sessionContentId ?? ""}`, {
          replace: !isDesktop,
          state: { curriculumData: courseDataRef?.current ?? null },
        });
      }}
    >
      <ContentIcon kind={type} />
      <div className="flex flex-col items-start w-full ">
        <div className="flex items-center gap-2 w-full justify-between">
          <div className="leading-5 text-sm font-medium md:text-base">
            {title}
          </div>
          <div
            className={classNames(
              "flex items-center justify-center border-[1.5px] min-w-[20px] w-5 h-5 rounded-sm",
              isChecked
                ? "border-theme-orange bg-theme-orange focus:ring-theme-orange"
                : "focus:ring-gray-900 border-gray-200 bg-white"
            )}
            // onClick={type !== "quiz" && onChecked}
          >
            <Check
              weight="bold"
              className={classNames(
                "transition-all text-white",
                isChecked ? "w-4 h-4" : "w-0 h-0"
              )}
            />
          </div>
        </div>
        <div className="flex items-center justify-between space-x-1.5 w-full md:text-sm text-xs">
          {subChildren ?? (
            <TopicSubChildren
              sessionContentId={sessionContentId}
              topicType={type}
              speaker={speaker}
              sessionTime={sessionTime}
              ratings={ratings}
              url={url}
              isDownloadable={isDownloadable ?? true}
            />
          )}
          {ratings && (
            <div className="flex justify-end flex-grow space-x-1">
              <Star
                className="text-sm text-theme-yellow-500 md:text-xl"
                weight="fill"
              />
              <div className="text-theme-black-color">
                {ratings
                  ? ratings.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      minimumIntegerDigits: 1,
                    })
                  : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContentIcon = ({ kind = "video" }) => {
  switch (kind) {
    case "quiz":
      return (
        <File weight="fill" size={24} className="min-w-[20px] text-theme-red" />
      );

    case "protool":
      return (
        <div className="rounded-full flex items-center justify-center bg-theme-red w-5 h-5 min-w-[20px]">
          <ArrowDown weight="fill" size={12} className="text-white" />
        </div>
      );

    default:
      return (
        <PlayCircle
          weight="fill"
          size={24}
          className="min-w-[24px] text-theme-red"
        />
      );
  }
};

ContentIcon.propTypes = {
  kind: PropTypes.string,
};

const TopicSubChildren = ({
  sessionContentId,
  topicType,
  speaker,
  sessionTime,
  ratings,
  url,
  isDownloadable,
}) => {
  switch (topicType) {
    case "protool":
      return (
        isDownloadable && (
          <DownloadActionBar
            id={sessionContentId}
            url={url}
            ratings={ratings}
          />
        )
      );
    case "quiz":
      return (
        <button className="flex items-center space-x-1.5 hover:cursor-pointer">
          <img
            src={quizIcon}
            className="w-3.5 min-w-[12px] h-3.5 md:w-5 md:min-w-[20px] md:h-5 mr-2"
            alt={"quiz"}
          />{" "}
          Quiz
        </button>
      );
    default:
      return (
        <div className="flex items-center w-fit gap-2">
          {speaker && <div className="text-theme-black-300">{speaker}</div>}
          {speaker && (
            <div className="container w-1 h-1 rounded-full bg-gray-400" />
          )}
          <div className="text-theme-red">{sessionTime}</div>
        </div>
      );
  }
  // };

  // return getActionBar();
};

TopicSubChildren.propTypes = {
  isDownloadable: PropTypes.bool,
  ratings: PropTypes.string,
  sessionContentId: PropTypes.number,
  sessionTime: PropTypes.string,
  speaker: PropTypes.string,
  topicType: PropTypes.string,
  url: PropTypes.string,
};

TopicComponent.propTypes = {
  className: PropTypes.string,
  courseDataRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  courseId: PropTypes.any,
  currentState: PropTypes.object,
  isCompleted: PropTypes.bool,
  isDownloadable: PropTypes.bool,
  ratings: PropTypes.string,
  sessionContentId: PropTypes.number,
  sessionTime: PropTypes.string,
  speaker: PropTypes.string,
  subChildren: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    "session",
    "downloadable",
    "quiz",
    "video",
    "protool",
  ]),
  url: PropTypes.string,
};
export default TopicComponent;
