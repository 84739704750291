/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import TextInput from "../../stories/TextInput";
import { Form, Formik } from "formik";
import { UserService } from "../../services/UserService";
import { CustomToast } from "../../stories/Toast";
import { X } from "@phosphor-icons/react";
import { Validations } from "../../constants/validations";
import { useLocation, useNavigate } from "@reach/router";
import { useWindowSize } from "../../hooks/useWindowSize";
import TextArea from "../../stories/TextArea";
import FileInput1 from "../../stories/FileInput1";
import ChipInputField from "../../stories/ChipInputField";
import { useState } from "react";
import { ForumService } from "../../services/ForumService";
import { useEffect } from "react";
import _ from "lodash";
import fileUploadManager from "../../api/fileUploadManager";

const CreateForumModal = ({ className }) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const type = location.pathname.includes("ask-question")
    ? "ask-question"
    : "share-something";

  const onClose = () => navigate("/exchange");

  useEffect(() => {
    if (_.isEmpty(location.state?.categories)) {
      fetchCategories();
    } else {
      setCategories(location.state?.categories ?? []);
    }
  }, []);

  const fetchCategories = async () => {
    const response = await ForumService.getAllForumCategories();
    setCategories(response.forum_categories);
  };

  if (isDesktop) {
    return (
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={true}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <div className="absolute top-0 right-0 block pt-6 pr-6">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="p-3 text-3xl font-bold leading-6 border-b lg:p-6 text-theme-black-color border-theme-gray-300"
                >
                  {type === "ask-question" ? "Ask Question" : "Share Something"}
                </Dialog.Title>
                <CreateForumForm type={type} categories={categories} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <div className="flex justify-center min-h-[calc(100vh-80px)] p-4">
      <div className="flex flex-col w-full bg-white shadow-sm h-fit lg:hidden">
        <div className="p-3 text-3xl font-bold leading-6 border-b lg:p-6 text-theme-black-color border-theme-gray-300">
          {type === "ask-question" ? "Ask Question" : "Share Something"}
        </div>
        <CreateForumForm type={type} categories={categories} />
      </div>
    </div>
  );
};

const CreateForumForm = ({ categories, type }) => {
  const navigate = useNavigate();
  const onClose = (id) =>
    navigate(id ? `/exchange/${id}/discuss` : "/exchange");
  const onSubmit = async (values) => {
    try {
      const postBody = {};
      postBody["title"] = values.title;
      postBody["description"] = values.description;
      postBody["forum_category_ids"] = values.categories.map((c) => c.id);
      if (!_.isEmpty(values?.attachment?.name)) {
        const file_res = await fileUploadManager({
          file: values.attachment,
        });

        postBody["attachment_attributes"] = {
          file_type: file_res.file_type,
          attachment_type: "exchange_forum.attachment",
          url: file_res.signed_url,
        };
      }
      const res = await ForumService.createForum({
        data: { exchange_forum: postBody },
      });
      CustomToast.success(res.message);
      onClose(res.id);
    } catch (e) {
      CustomToast.error(e.error_messages);
    }
  };

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        attachment: {},
        categories: [],
      }}
      enableReinitialize
      validationSchema={() => Validations.exchangeForum.createFormValidation}
      onSubmit={onSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <Form className="flex p-3 lg:p-6 flex-col space-y-2.5 w-full">
            <TextInput
              name="title"
              label="Title"
              labelClassName="!text-sm !font-bold !tracking-tight mb-1 lg:!text-base !text-theme-black-color"
              placeholder={"Give a short title to your share"}
              type="text"
              isRequired={true}
            />
            <TextArea
              name="description"
              label="Description"
              labelClassname="!text-sm !font-bold !tracking-tight mb-1 lg:!text-base !text-theme-black-color"
              placeholder={"Share something useful"}
              rows={5}
              maxLength={5000}
              isRequired={true}
            />
            <FileInput1
              label="Add Attachments"
              labelClassName="!text-sm !font-bold !tracking-tight mb-1 lg:!text-base !text-theme-black-color"
              name="attachment"
              placeholder="Add PDF, image, excel, word, ppt etc"
              suggestion=""
              onFileChange={(file) => {
                formProps.setFieldTouched("attachment", true, false);
                formProps.setFieldValue("attachment", file, true);
              }}
              accept="application/pdf, image/*"
            />
            <ChipInputField
              onChange={(newValue) => {
                formProps.setFieldValue("categories", newValue);
              }}
              preSelected={values["categories"] ?? []}
              options={categories}
              label="Categories"
              labelClassName="!text-sm !font-bold !tracking-tight mb-1 lg:!text-base !text-theme-black-color"
              displayKey={"label"}
              idKey={"id"}
              name={"categories"}
            />

            <div className="flex flex-col items-end w-full mt-1 ">
              <button
                type="submit"
                disabled={formProps.isSubmitting}
                className="flex items-center justify-center w-40 text-white bg-theme-orange h-9"
              >
                SUBMIT
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateForumForm.propTypes = {
  categories: PropTypes.array,
  type: PropTypes.string,
};

CreateForumModal.defaultProps = {
  open: false,
  className: "",
};

CreateForumModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.oneOf(["ask_something", "share_something"]),
};
export default CreateForumModal;
