/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import AppSkeleton from "../AppSkeleton";
import { CaretLeft } from "@phosphor-icons/react";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import { useProfile } from "../../context/userProvider";
import { useQPFPProgressTracker } from "../../services/CourseDetails";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import { downloadFile } from "../../util/downloadFile";
import { CourseService } from "../../services/CourseService";
import { QPFPBadge } from "../QPFPCertification/CertificateAndBadges";

const QPFPCertificateBadges = (props) => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const [certificateData, setCertificateData] = useState({});

  const pageData = _.isEmpty(props.course_name)
    ? []
    : [
        { name: "Dashboard", to: "/" },
        { name: "My Programs", to: "/programs" },
        {
          name: props.course_name,
          to: `/programs/${props.course_id}`,
          current: true,
        },
      ];
  const { qpfpProgressTracker, loading: isProgressTrackerLoading } =
    useQPFPProgressTracker("qpfp", props.course_id, {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });

  const fetchCertificateData = async () => {
    const response = await CourseService.fetchQPFPCertificatesBadges({
      course_id: props.course_id,
    });
    setCertificateData(response);
  };

  useEffect(() => {
    fetchCertificateData();
  }, []);

  if (
    _.isEmpty(certificateData?.qpfp_badges) &&
    _.isEmpty(certificateData?.qpfp_final_certificate)
  ) {
    return (
      <AppSkeleton
        pageLabel={qpfpProgressTracker?.course_name}
        breadCrumbData={pageData}
        isLoading={isProgressTrackerLoading}
      >
        <div className="flex flex-col w-full px-10">
          <div className="text-base my-8 italic leading-5 text-theme-black-300">
            *All topic tests and level exams should be completed to get a Badge
            and to mark module as complete
            <br />
            *It may take up to 45 minutes to generate a badge/certificate and to
            mark module as complete
          </div>
          <EmptyState
            heading="No Certificates and Badges Yet!"
            subHeading={
              "You haven't finished your QPFP course yet, to continue learning click on the below button."
            }
            cta={
              <Button className="!mt-4" onClick={() => navigate(-1)}>
                <div className="flex items-center px-3 py-1">
                  <span>RESUME COURSE</span>
                </div>
              </Button>
            }
            image={
              <img
                alt="emptyStatePlaceHolder"
                className="mb-8 max-h-72"
                src={EmptyStatePlaceHolder}
              />
            }
          />
        </div>
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel={qpfpProgressTracker?.course_name}
      showMobileHeader={true}
      hideSidebar={true}
      breadCrumbData={pageData}
      isLoading={isProgressTrackerLoading}
    >
      <div className="py-8 px-10 flex flex-col w-full">
        <div
          className="flex gap-2 items-center font-medium text-xl cursor-pointer"
          onClick={() => navigate(`/programs/${props.course_id}`)}
        >
          <CaretLeft />
          Back to QPFP Program
        </div>
        <div className="mt-8 flex gap-3 flex-col w-full items-start">
          <div className="font-bold text-3xl text-theme-black-color">
            My Badges and Certificates
          </div>
          <div className="text-xl text-theme-black-300">
            Download and Share your well deserved QPFP Badges and Certificates
            with the world!
          </div>
          <div className="text-base italic leading-5 text-theme-black-300">
            *All topic tests and level exams should be completed to get a Badge
            and to mark module as complete
            <br />
            *It may take up to 45 minutes to generate a badge/certificate and to
            mark module as complete
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-12">
          {certificateData?.qpfp_badges?.map(
            (badge, index) =>
              !_.isEmpty(badge.certificate_url) && (
                <QPFPBadge
                  key={`qpfp-badge-${index}`}
                  title={badge?.title}
                  description={badge?.description}
                  imgSrc={badge.certificate_url}
                  certificantBadge={certificateData?.qpfp_badges?.length < 2}
                />
              )
          )}
          {!_.isEmpty(certificateData?.qpfp_final_certificate) && (
            <div className="col-span-full bg-white border rounded-xl border-neutral-200/80 shadow-[0_4px_44px_0px_rgba(0,0,0,0.09)] p-10 flex flex-col items-center gap-8">
              <img
                src={certificateData?.qpfp_final_certificate?.certificate_url}
                alt="Your QPFP Certificate"
                className="px-6 md:px-0 w-5/6 sm:w-2/3 md:w-1/4"
              />
              <div className="flex items-center justify-center gap-5">
                <Button
                  width="110px"
                  className=""
                  onClick={() => {
                    downloadFile(
                      certificateData?.qpfp_final_certificate?.certificate_url
                    );
                  }}
                >
                  Download
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppSkeleton>
  );
};

export default QPFPCertificateBadges;
