/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const useLeaderboard = (courseId, page, searchQuery) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [lastSearchQuery, setLastSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (lastPage !== page || lastSearchQuery !== searchQuery) {
      setLastPage(page);
      if (lastSearchQuery !== searchQuery) {
        setLastSearchQuery(searchQuery);
        setData([]);
      }
      API(
        {
          method: "GET",
          url: config.urls.courses.qpfp_leaderboard(courseId),
          params: {
            per_page: 10,
            page: page,
            search: searchQuery,
          },
        },
        true
      )
        .then((res) => {
          setData((prevForums) => {
            return [...prevForums, ...res.users];
          });
          setHasMore(!res.pagination.last_page);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }
    setLoading(false);
  }, [page, searchQuery]);

  return { loading, error, data, hasMore };
};
export default useLeaderboard;
