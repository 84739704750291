import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Details = ({ title, info }) => {
  if (_.isEmpty((info ?? "").toString())) return null;
  return (
    <div>
      <div className="text-sm font-normal text-neutral-600 ">{title}</div>
      <div className="text-base font-normal">{info}</div>
    </div>
  );
};
const QuizReportSummary = ({
  quizTitle,
  quizType,
  name,
  percentage,
  passingScore,
  timeTaken,
  totalQuestions,
  correctAnswers,
  incorrectAnswers,
  unAttended,
  scoreObtained,
  totalScore,
  date,
  acePoints,
}) => {
  return (
    <div className="p-3 border rounded-sm md:border-0 md:p-0">
      <div className="text-sm font-semibold md:text-xl">{quizTitle}</div>
      <div className="py-2 text-xs font-semibold md:text-base md:py-5">
        Your Result
      </div>
      <div className="grid w-full grid-cols-2 gap-6 md:grid-cols-4">
        <Details title="Name" info={name} />
        <Details
          title="Your Score"
          info={
            quizType === "final_exam"
              ? percentage
              : `${scoreObtained}/${totalScore} (${percentage})`
          }
        />
        <Details title="Passing Score" info={passingScore} />
        <Details title="Time Taken" info={timeTaken} />
        <Details title="Total Questions" info={totalQuestions} />
        <Details title="Correct Answers" info={correctAnswers} />
        <Details title="Incorrect Answers" info={incorrectAnswers} />
        <Details title="Not Attempted" info={unAttended} />
        <Details title="Ace points" info={acePoints} />
        <Details title="Date" info={date} />
      </div>
    </div>
  );
};

Details.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
};

QuizReportSummary.propTypes = {
  acePoints: PropTypes.number,
  correctAnswers: PropTypes.number,
  date: PropTypes.string,
  quizType: PropTypes.string,
  hasCleared: PropTypes.bool,
  incorrectAnswers: PropTypes.number,
  name: PropTypes.string,
  passingScore: PropTypes.string,
  quizTitle: PropTypes.string,
  scoreObtained: PropTypes.any,
  timeTaken: PropTypes.string,
  totalQuestions: PropTypes.number,
  totalScore: PropTypes.any,
  percentage: PropTypes.string,
  unAttended: PropTypes.any,
};
export default QuizReportSummary;
