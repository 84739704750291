/* eslint-disable no-unused-vars */
import { Info } from "@phosphor-icons/react";
import React from "react";

const AccountCreationBar = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col justify-start p-4 rounded md:items-center bg-red-50 md:flex-row">
        <div className="flex items-start text-base font-medium leading-5 tracking-tight text-red-800">
          <Info
            size={20}
            weight="fill"
            className="mr-3 min-w-[20px] text-red-300"
          />{" "}
          <div className="block">
            For new users, we will{" "}
            <span className="font-bold">Create a new login</span> &{" "}
            <span className="font-bold">user account</span> with the details
            given below. You will get an email to reset your password.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountCreationBar;
