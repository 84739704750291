/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ContactListColumn, GuestEventColumn } from "../../../Constants";
import { EventsService } from "../../../services/EventsService";
import Dropdown from "../../../stories/Dropdown";
import EmptyState from "../../../stories/EmptyState";
import Table from "../../../stories/Table";
import AppSkeleton from "../../AppSkeleton";
import EmptyStatePlaceHolder from "../../../assets/svgs/empty_state_bg.svg";
import GuestListAccordian from "../../../stories/GuestListAccordian";
import API from "../../../api/manager";
import config from "../../../api/config";
import { CustomToast } from "../../../stories/Toast";
import Button from "../../../stories/Button";
import ContactListAccordian from "../../../stories/ContactListAccordian";
import Tabs from "../../../stories/Tabs";
import { useProfile } from "../../../context/userProvider";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import SearchBar from "../../../stories/SearchBar";

const CoroprateGuestList = () => {
  const [selected_event, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [guests, setGuests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useProfile();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchEvents = async () => {
    const response = await EventsService.fetchGuestEvents();
    setEvents(response.guest_events ?? []);
    if ((response.guest_events ?? [])[0]) {
      setSelectedEvent(response.guest_events[0].id);
      const response1 = await EventsService.fetchGuestList({
        guest_event_id: response.guest_events[0].id,
        entity_id: userDetails.firm_id,
        entity_type: "Firm",
      });
      setGuests(response1.guests ?? []);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(userDetails)) {
      setIsLoading(true);
      fetchEvents();
      fetchContacts();
      if (
        !(
          userDetails.is_pro_member &&
          ["approved", "expires_soon"].includes(userDetails.pm_signup_status)
        )
      ) {
        navigate("/");
        CustomToast.info(
          "Become an active Promember to enjoy complete benefits."
        );
      }
    }
  }, [userDetails]);

  const fetchContacts = async () => {
    setIsLoading(true);
    const response = await EventsService.fetchContacts({
      entity_id: userDetails.firm_id,
      entity_type: "Firm",
    });
    setContacts(response.contacts ?? []);
    setIsLoading(false);
  };

  const fetchGuests = async (event_id) => {
    setIsLoading(true);
    const response = await EventsService.fetchGuestList({
      guest_event_id: event_id,
      entity_id: userDetails.firm_id,
      entity_type: "Firm",
    });
    setGuests(response.guests ?? []);
    setIsLoading(false);
  };

  const downloadGuestCSV = async () => {
    try {
      const response = await API({
        method: "GET",
        url: config.urls.events.guest_list_export,
        params: {
          guest_event_id: selected_event,
          entity_id: userDetails.firm_id,
          entity_type: "Firm",
        },
      });
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a link to download it
      const pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", "guest_list.csv");
      pom.click();
    } catch (e) {
      console.log(e);
      CustomToast.error("Something went wrong");
    }
  };
  const downloadContactCSV = async () => {
    try {
      const response = await API({
        method: "GET",
        url: config.urls.events.contact_export,
        params: {
          entity_id: userDetails.firm_id,
          entity_type: "Firm",
        },
      });
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a link to download it
      const pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", "guest_list.csv");
      pom.click();
    } catch (e) {
      console.log(e);
      CustomToast.error("Something went wrong");
    }
  };

  const GuestTab = () => {
    return (
      <div className="flex flex-col bg-white rounded h-fit">
        <div className="flex flex-col justify-between p-6 lg:flex-row">
          <div className="flex items-center justify-between text-2xl font-bold text-theme-black-color">
            My GuestList
            {selected_event && guests.length > 0 && (
              <Button
                width="110px"
                className="flex mt-2 lg:hidden lg:ml-4 lg:mt-0"
                onClick={async () => downloadGuestCSV()}
              >
                Export CSV
              </Button>
            )}
          </div>
          <div className="flex flex-col items-start mt-4 text-2xl font-bold lg:items-center lg:flex-row lg:mt-0 text-theme-black-color">
            <div className="flex items-center">
              <div className="mr-3 text-xs font-medium text-theme-black-300">
                Event
              </div>
              <div className="w-full max-w-sm lg:min-w-261">
                <Formik
                  initialValues={{ guest_event: events[0]?.id }}
                  enableReinitialize
                >
                  <Dropdown
                    name="guest_event"
                    id="guest_event"
                    type="text"
                    label=""
                    placeholder="None"
                    height={"h-8"}
                    options={events}
                    keepShadow={false}
                    buttonBgColor="bg-white"
                    displayCurrentOption={true}
                    displayKey="title"
                    idKey="id"
                    onChange={(e) => {
                      setSelectedEvent(e ?? "");
                      fetchGuests(e ?? "");
                    }}
                  />
                </Formik>
              </div>
            </div>
            {selected_event && guests.length > 0 && (
              <Button
                width="110px"
                className="hidden mt-2 lg:ml-4 lg:mt-0 lg:flex"
                onClick={async () => downloadGuestCSV()}
              >
                Export CSV
              </Button>
            )}
          </div>
        </div>
        <div className="mx-4 overflow-auto lg:mx-0 ">
          <Table
            isLoading={isLoading}
            data={guests.filter(
              (g) =>
                currentIndex !== 0 ||
                searchQuery.length === 0 ||
                (currentIndex === 0 &&
                  `${g.first_name} ${g.last_name} ${g.email} ${g.program_name}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()))
            )}
            showPagination={true}
            paginationProps={{
              perPage: 10,
            }}
            columns={GuestEventColumn}
            emptyScreen={
              <EmptyState
                heading="No registrations"
                subHeading={`The ${
                  events.filter((e) => e.id === selected_event)?.title ??
                  events?.[0]?.title
                } event does not have any attendees.`}
                cta={<></>}
                image={
                  <img
                    alt="emptyStatePlaceHolder"
                    className="py-9 max-h-80"
                    src={EmptyStatePlaceHolder}
                  />
                }
              />
            }
            MobileViewCard={({ data, col, i }) => (
              <GuestListAccordian item={data} key={i} index={i} />
            )}
          />
        </div>
      </div>
    );
  };

  const ContactTab = () => {
    return (
      <div className="flex flex-col bg-white rounded h-fit">
        <div className="flex justify-between p-6 ">
          <div className="flex items-center text-2xl font-bold text-theme-black-color">
            My Contacts
          </div>
          {contacts.length > 0 && (
            <Button
              width="110px"
              className="mt-2 lg:ml-4 lg:mt-0"
              onClick={async () => downloadContactCSV()}
            >
              Export CSV
            </Button>
          )}
        </div>
        <div className="mx-4 overflow-auto lg:mx-0 ">
          <Table
            isLoading={isLoading}
            data={contacts.filter(
              (c) =>
                currentIndex !== 1 ||
                (currentIndex === 1 &&
                  `${c.first_name} ${c.last_name} ${c.email} ${c.program_name}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()))
            )}
            showPagination={true}
            paginationProps={{
              perPage: 10,
            }}
            columns={ContactListColumn}
            emptyScreen={
              <EmptyState
                heading="No Contacts"
                subHeading={`You have not recieved any enquiries till now.`}
                cta={<></>}
                image={
                  <img
                    alt="emptyStatePlaceHolder"
                    className="py-9 max-h-80"
                    src={EmptyStatePlaceHolder}
                  />
                }
              />
            }
            MobileViewCard={({ data, col, i }) => (
              <ContactListAccordian item={data} key={i} index={i} />
            )}
          />
        </div>
      </div>
    );
  };

  const tabList = [
    {
      heading: "Guest List",
      component: <GuestTab key={1} />,
    },
    {
      heading: "Contact List",
      component: <ContactTab />,
    },
  ];

  return (
    <AppSkeleton pageLabel="My Accounts">
      <div className="flex flex-col w-full px-6 py-4 h-fit">
        <Tabs
          tabList={tabList}
          onTabClick={(index) => {
            setCurrentIndex(index);
            setSearchQuery("");
          }}
          headingLastComponent={
            <div className="justify-end hidden w-full md:flex grow">
              <div className="w-full max-w-lg">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={"Search here"}
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
          middleComponent={
            <div className="flex justify-start w-full md:hidden">
              <div className="w-full">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={"Search here"}
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
        />
        <div className="w-full h-16" />
      </div>
    </AppSkeleton>
  );
};

export default CoroprateGuestList;
