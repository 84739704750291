/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const fetchUserDetails = () => {
  return API(
    {
      method: "GET",
      url: config.urls.user.user_details,
    },
    true
  );
};

const fetchDashboard = () => {
  return API(
    {
      method: "GET",
      url: config.urls.user.dashboard,
    },
    true
  );
};

const fetchAceQuizData = () => {
  return API(
    {
      method: "GET",
      url: config.urls.ace_points.fetch_quiz,
    },
    true
  );
};

const updatePassword = ({
  current_password,
  password,
  password_confirmation,
}) => {
  return API(
    {
      method: "PUT",
      url: config.urls.user.update_password,
      data: { current_password, password, password_confirmation },
    },
    true
  );
};
const deleteUserDevice = (deviceId) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.user.delete_user_device,
      params: { token: deviceId },
    },
    true
  );
};
const sendDummyNotifications = (deviceId) => {
  return API(
    {
      method: "GET",
      url: config.urls.user.send_dummy_notification,
    },
    true
  );
};
const fetchNotifications = (params) => {
  return API(
    {
      method: "GET",
      url: config.urls.user.fetch_notifications,
      params: params,
    },
    true
  );
};
const readNotification = (id) => {
  return API(
    {
      method: "PUT",
      url: config.urls.user.read_notifications(id),
    },
    true
  );
};

const fetchQPFPCertificationStatus = () => {
  return API(
    {
      method: "GET",
      url: config.urls.user.qpfp_certification_status,
    },
    true
  );
};

export const UserService = {
  fetchUserDetails,
  fetchDashboard,
  fetchAceQuizData,
  updatePassword,
  deleteUserDevice,
  sendDummyNotifications,
  fetchNotifications,
  readNotification,
  fetchQPFPCertificationStatus,
};
