/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import Label from "./Label";
import { AsteriskSimple, CaretUp } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import Tooltip from "./Tooltip";
import _ from "lodash";

const Dropdown = ({
  name,
  label,
  placeholder,
  searchPlaceholder,
  options = [],
  infoContent = "",
  displayKey,
  idKey,
  isRequired,
  displayCurrentOption = false,
  height,
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  isSearchable = true,
  allowCreateNewOption,
  labelClassName,
  className,
  buttonBgColor,
  placeholderColor,
  getOptionLabel,
  keepShadow = true,
  onChange = () => {},
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    initialTouched: false,
    ...props,
  });
  const [selectedVal, setSelectedVal] = useState();

  const creatableSelectRef = React.useRef();
  useEffect(() => {
    if (displayCurrentOption) {
      const temp = options.filter((e) => e[idKey] === field?.value)[0];
      setSelectedVal(temp ? temp : "");
    }
  }, [displayCurrentOption, displayKey, field?.value, idKey, options]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {
          <CaretUp
            className={classNames(
              "w-5 h-5 ml-2 text-theme-black-color",
              !props.selectProps.menuIsOpen ? "transform rotate-180" : ""
            )}
            weight="fill"
          />
        }
      </components.DropdownIndicator>
    );
  };
  const OptionComponent = ({ children, ...rest }) => {
    return (
      <components.Option
        {...rest}
        className={classNames(
          rest.isFocused
            ? "!bg-gray-100 !text-neutral-900 cursor-pointer "
            : "!text-neutral-700",
          "block !px-4 !py-3 !bg-white !text-sm focus:!outline-none"
        )}
      >
        {children}
      </components.Option>
    );
  };

  const ControlComponent = ({ children, ...rest }) => {
    return (
      <components.Control
        {...rest}
        className={classNames(
          "!text-sm min-w-36 text-theme-black-color font-medium tracking-tight w-full !border  focus:!outline-none focus:border hover:!border-gray-200",
          meta.touched && meta.error
            ? "!border-theme-red-300 focus:!ring-theme-red-300 focus:!border-theme-red-300"
            : "!border-gray-200 focus:!ring-gray-900 focus:!border-theme-black-color",
          "!rounded-sm ",
          keepShadow && "!shadow-sm hover:!shadow-sm",
          rest.isFocused && "!shadow-none",
          height ?? "h-10"
        )}
      >
        {children}
      </components.Control>
    );
  };
  const inputStyles = {
    menu: (styles) => {
      return {
        ...styles,
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        zIndex: 10,
        maxHeight: "200px",
        borderRadius: "2px",
        overflowX: "hidden",
      };
    },
    input: (styles) => {
      return {
        ...styles,
        "input:focus": {
          boxShadow: "none",
        },
      };
    },
    indicatorSeparator: (styles) => {
      return { display: "none" };
    },
    placeholder: (styles) => ({
      ...styles,
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
      fontWeight: 500,
      color: "rgb(163, 163, 163)",
      letterSpacing: "0em",
    }),
  };
  return (
    <div className={className}>
      <div className="relative dropdown-floating-input">
        <Label
          labelFor={name}
          className={classNames(
            "flex flex-row min-w-186 items-center text-left tracking-tight text-xs leading-none font-medium text-theme-black-300",
            labelClassName
          )}
        >
          {label ?? "Label"}
          {isRequired && (
            <AsteriskSimple
              size={8}
              weight="bold"
              className="mb-2 text-theme-red"
            />
          )}
          {infoContent && <Tooltip infoContent={infoContent} />}
        </Label>
        {allowCreateNewOption ? (
          <CreatableSelect
            ref={creatableSelectRef}
            key={name}
            onChange={(val) => {
              if (props.onOptionSelect) {
                props.onOptionSelect(val);
              } else {
                setFieldValue(name, val[idKey]);
                onChange(val[idKey]);
              }
            }}
            classNames={{
              menuList: () => "!divide-y !divide-gray-100",
            }}
            value={field.value}
            //  uncomment below code to allow search on type feature. Alsow uncomment the code from FirmInfoCard.js file
            // onInputChange={(newVal) => {
            //   const fetchOptions = async (newVal) => {
            //     if (props.onInputChange) {
            //       setFilteredOptions(await props.onInputChange(newVal));
            //       creatableSelectRef.current.focus();
            //       return;
            //     }
            //   };
            //   fetchOptions(newVal);
            // }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            getOptionLabel={(e) => {
              if (e["__isNew__"]) return <b>+ Add {e[displayKey]}</b>;
              return e[displayKey];
            }}
            options={options}
            createOptionPosition="last"
            getNewOptionData={(val) => ({
              [idKey]: -1,
              [displayKey]: val,
              __isNew__: true,
            })}
            styles={inputStyles}
            components={{
              DropdownIndicator,
              Option: OptionComponent,
              Control: ControlComponent,
              // Placeholder,
            }}
          />
        ) : (
          <Select
            classNamePrefix="select"
            className={!_.isEmpty(label) && "mt-1"}
            classNames={{
              menuList: () => "!divide-y !divide-gray-100",
            }}
            value={selectedVal}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            name={name}
            onChange={(val) => {
              setFieldValue(name, val[idKey]);
              if (props.onOptionSelect) {
                props.onOptionSelect(val);
              } else if (onChange) {
                onChange(val?.[idKey] ?? "");
              }
            }}
            onBlur={() => {
              helpers.setTouched(true, true);
            }}
            formatOptionLabel={(e, meta) => {
              if (meta["selectValue"].map((v) => v[idKey]).includes(e[idKey]))
                return e[displayKey];
              else return getOptionLabel ? getOptionLabel(e) : e[displayKey];
            }}
            getOptionLabel={(e) => e[displayKey]}
            styles={inputStyles}
            components={{
              DropdownIndicator,
              Option: OptionComponent,
              Control: ControlComponent,
              // Placeholder,
            }}
            options={options}
          />
        )}
      </div>
      <ShowFormError name={name} />
    </div>
  );
};
Dropdown.defaultProps = {
  infoContent: "",
  isRequired: false,
  height: "h-10",
  displayCurrentOption: false,
  labelClassName: "",
  placeholderColor: "",
  onClick: () => {},
};
Dropdown.propTypes = {
  allowCreateNewOption: PropTypes.any,
  buttonBgColor: PropTypes.any,
  className: PropTypes.any,
  displayCurrentOption: PropTypes.bool,
  displayKey: PropTypes.string.isRequired,
  height: PropTypes.string,
  idKey: PropTypes.string.isRequired,
  infoContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  placeholderColor: PropTypes.string,
  searchPlaceholder: PropTypes.any,
  size: PropTypes.oneOf(["xs", "md", "lg", "xl"]),
};
export default Dropdown;
