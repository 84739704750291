/* eslint-disable no-unused-vars */
import { useNavigate } from "@reach/router";
import React from "react";
import Joyride from "react-joyride";
import { useTourContext } from "../context/tourProvider";
import _ from "lodash";

export const tourSteps = [
  {
    target: ".nfp-sidebar",
    content: (
      <>
        <div className="text-lg font-bold">Welcome to Network FP</div>
        <div>
          Welcome to the home page of your learn account, by clicking next will
          guide you to the platform.
        </div>
      </>
    ),
    data: {
      next: "/programs",
    },
    placement: "center",
    disableBeacon: true,
  },
  {
    target: ".nfp-program-card:nth-of-type(1)",
    content: (
      <>
        <div className="text-lg font-bold">My Program section</div>
        <div>
          In the My Program section you can able to watch all the courses you
          have opted for,
        </div>
      </>
    ),
    data: {
      previous: "/",
    },
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".nfp-program-card:nth-of-type(1) .nfp-start-course-btn",
    content: (
      <>
        <div className="text-lg font-bold">Lets start the course</div>
        <div>By clicking this button, you will start watching this course.</div>
      </>
    ),
    data: {
      next: "/register",
    },
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".nfp-register-event-card:nth-of-type(2)",
    content: (
      <>
        <div className="text-lg font-bold">Register for Network FP courses</div>
        <div>You can register for this by clicking here.</div>
      </>
    ),
    data: {
      previous: "/programs",
      next: "/public-profile",
    },
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".nfp-page-container",
    content: (
      <>
        <div className="text-lg font-bold">Update your Public Profile</div>
        <div>Kindly update your public profile and share with others.</div>
      </>
    ),
    data: {
      previous: "/register",
    },
    placement: "center",
    disableBeacon: true,
  },
];

const Tour = () => {
  const navigate = useNavigate();
  const {
    setState,
    state: { run, stepIndex, tourActive },
  } = useTourContext();

  const handleCallback = (callbackData) => {
    const { action, index, step, type } = callbackData;
    const isPreviousAction = action === "prev";
    const { data = {} } = step;
    const { next, previous } = data;

    if (action === "close" || action === "skip") {
      setState({ run: false, stepIndex: 0, tourActive: false });
      return;
    }

    if (type === "step:after") {
      if (index === 0 && isPreviousAction) {
        setState({ run: false, stepIndex: 0, tourActive: false });
        return;
      }

      if (index < tourSteps.length) {
        setState((prev) => ({
          ...prev,
          stepIndex: isPreviousAction ? index - 1 : index + 1,
        }));
        if (!_.isEmpty(isPreviousAction ? previous : next)) {
          setState((prev) => ({
            ...prev,
            run: false,
          }));
          navigate(isPreviousAction ? previous : next);
        }
        return;
      }
      if (index === tourSteps.length - 1 && !isPreviousAction) {
        setState({ run: false, stepIndex: 0, tourActive: false });
        return;
      }
    }
  };
  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run && tourActive}
      stepIndex={stepIndex}
      steps={tourSteps}
      hideCloseButton
      showSkipButton
      locale={{ last: "Close", skip: "Skip Tour" }}
      scrollOffset={100}
      disableCloseOnEsc
      disableScrolling
      disableOverlayClose
      styles={{
        options: {
          arrowColor: "#FFF",
          backgroundColor: "#FFF",
          beaconSize: 36,
          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: "#E10000",
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
          textColor: "#141414",
          width: undefined,
          zIndex: 100,
        },
      }}
    />
  );
};

export default Tour;
