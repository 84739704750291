/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PublicProfileCard from "../../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../../services/PublicProfileService";
import _ from "lodash";
import fileUploadManager from "../../../api/fileUploadManager";
import { AuthService } from "../../../services/AuthService";
import Button from "../../../stories/Button";
import { CustomToast } from "../../../stories/Toast";
import FormBuilder from "../../../stories/FormBuilder";
import { Validations } from "../../../constants/validations";
import { Buildings } from "@phosphor-icons/react";
import ImageComponent from "../../../stories/ImageComponent";
import { getDirectoryFirmURL } from "../../../util/getURLs";

const FirmInfoCard = ({
  className,
  corporateProfileData = {},
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const cancelRef = React.useRef();
  const [address, setAddress] = useState("");

  const [states, setStates] = useState([]);

  const fetchStates = async () => {
    try {
      const response1 = await AuthService.getCities("states");
      setStates(response1.locations);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // fetchFirmList();
    fetchStates();
  }, []);

  useEffect(() => {
    setInitialValues({
      firmId: corporateProfileData?.firm?.id ?? -1,
      firmImage: corporateProfileData?.firm?.firm_logo?.signed_url ?? null,
      firmName: corporateProfileData?.firm?.name,
      linkedin_link: corporateProfileData?.firm?.linkedin_link ?? "",
      no_of_members: corporateProfileData?.firm?.no_of_members ?? "",
      short_bio: corporateProfileData?.firm?.short_bio ?? "",
      firmWebsite: corporateProfileData?.firm?.website ?? "",
      uid: corporateProfileData?.firm?.uid ?? "",
      addressLine1: corporateProfileData?.firm?.address?.line_1 ?? "",
      city: corporateProfileData?.firm?.address?.city ?? "",
      city_id: corporateProfileData?.firm?.address?.city_id ?? "",
      state: corporateProfileData?.firm?.address?.state ?? "",
      state_id: corporateProfileData?.firm?.address?.state_id ?? "",
      pincode: corporateProfileData?.firm?.address?.pin ?? "",
      options: {
        cities: [
          {
            id: corporateProfileData?.firm?.address?.city_id,
            name: corporateProfileData?.firm?.address?.city,
          },
        ],
      },
    });
    if (corporateProfileData.firm?.address) {
      setAddress((_) => {
        let s = [];
        s = [...s, corporateProfileData.firm?.address?.line_1 ?? ""];
        s = [...s, corporateProfileData.firm?.address?.city ?? ""];
        s = [...s, corporateProfileData.firm?.address?.state ?? ""];
        s = [...s, corporateProfileData.firm?.address?.pin ?? ""];
        return s.join(", ");
      });
    }
  }, [corporateProfileData]);

  const textInputDetails = [
    {
      name: "firmImage",
      type: "avatarImgUpload",
      isRequired: false,
      ErrorComponent: () => (
        <Buildings size={40} weight="bold" className="text-theme-black-300" />
      ),
    },
    {
      label: "Full Company Name",
      name: "firmName",
      placeholder: "WRP India",
      className: "lg:min-w-186",
      type: "text",
      isRequired: true,
    },
    {
      label: "Firm Website",
      name: "firmWebsite",
      placeholder: "www.xyz.com",
      className: "lg:min-w-186",
      type: "text",
      isRequired: false,
    },
    {
      label: "LinkedIn Profile link",
      name: "linkedin_link",
      placeholder: "LinkedIn profile link",
      className: "col-span-full",
      type: "text",
      infoContent: "Copy Paste your LinkedIn profile url link here",
      isRequired: false,
    },
    {
      label: "Address Line",
      name: "addressLine1",
      placeholder: "12/14, Vaju Kotak Marg, Ballard Estate",
      className: "lg:min-w-186 col-span-full",
      type: "text",
    },
    {
      label: "State",
      placeholder: "Select state",
      type: "dropdown",
      name: "state_id",
      displayCurrentOption: true,
      options: states,
      displayKey: "name",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("state_id", value["id"]);
        formProps.setFieldValue("state", value["name"]);
        formProps.setFieldValue("city_id", "");
        const response2 = await AuthService.getCities("cities", value["id"]);
        formProps.setFieldValue("options.cities", response2.locations);
      },
    },
    {
      label: "City",
      placeholder: "Select city",
      type: "dropdown",
      name: "city_id",
      displayCurrentOption: true,
      options: [],
      optionFormFormikName: "cities",
      displayKey: "name",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("city_id", value["id"]);
        formProps.setFieldValue("city", value["name"]);
      },
    },
    {
      label: "Pincode",
      name: "pincode",
      placeholder: "400001",
      className: "lg:min-w-186",
      maxLength: 6,
      type: "number",
    },
    {
      label: "No. of Team Members in Company",
      name: "no_of_members",
      placeholder: "5",
      className: "lg:min-w-186",
      type: "number",
      isRequired: false,
      infoContent:
        "Mention number of active clients/families being serviced by you",
      isViewOnly: !isEditing,
    },
    {
      label: "City",
      name: "city",
      isViewOnly: true,
    },
    {
      label: "State",
      name: "state",
      isViewOnly: true,
    },
    {
      label: "Profile Link",
      name: "profile_slug",
      renderValue: () => "/" + corporateProfileData.firm.uid ?? "",
      isViewOnly: true,
    },
    {
      label: "Bio",
      name: "short_bio",
      placeholder: "Write down here . . .",
      className: "col-span-full",
      rows: 4,
      isRequired: false,
      maxLength: 1000,
      infoContent: "Add a short bio here",
      type: "textarea",
    },
  ];

  const handleSubmit = async (data) => {
    try {
      let firmLogoResponse;
      const postBody = {
        name: data.firmName,
        website: data.firmWebsite,
        linkedin_link: data.linkedin_link,
        no_of_members: data.no_of_members,
        short_bio: data.short_bio,
        address_attributes: {
          line_1: data.addressLine1,
          pin: data.pincode,
        },
      };
      if (data.city_id !== undefined || data.city_id !== null) {
        postBody["address_attributes"]["city_id"] = data.city_id;
      }
      if (
        corporateProfileData.firm?.address?.id !== undefined ||
        corporateProfileData.firm?.address?.id !== null
      ) {
        postBody["address_attributes"]["id"] =
          data.firmId !== -1 && data?.address?.addressId;
      }

      if (
        typeof data.firmImage !== "string" &&
        typeof data.firmImage !== "undefined" &&
        data.firmImage !== null
      ) {
        firmLogoResponse = await fileUploadManager({
          file: data.firmImage,
          key: "firm-logos",
        });
        postBody["attachments_attributes"] = [
          {
            id: corporateProfileData?.firm?.firm_logo?.id,
            file_type: firmLogoResponse.file_type,
            attachment_type: "firm.logo",
            url: firmLogoResponse.signed_url,
          },
        ];
      }

      if (data.firmId !== -1) {
        postBody["id"] = data.firmId;
      }

      try {
        const result = await PublicProfileService.updateCorporateInfo({
          data: postBody,
          id: corporateProfileData.firm.id,
        });
        if (result.message) {
          CustomToast.success(result.message);
          onUpdate();
          setIsEditing(false);
        }
      } catch (e) {
        CustomToast.error(e.error_messages[0]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setIsEmpty(
      Object.values(initialValues).filter((val) => !_.isEmpty(val)).length === 0
    );
  }, [initialValues]);
  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Company Info"
        subHeading="Update your Company Info here."
        isEditing={isEditing}
        isLoading={isLoading}
        id="firm-details-card"
        setIsEditing={setIsEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && isEmpty}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Firm Information"
              message="To see firm information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#firm-details-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              {isEditing ? (
                <div className="flex ">
                  <FormBuilder
                    initialValues={initialValues}
                    validationSchema={
                      Validations.corporateProfile.companyInfoValidationSchema
                    }
                    onFormSubmit={handleSubmit}
                    submitButtonRef={submitRef}
                    cancelButtonRef={cancelRef}
                    inputFieldsDetails={textInputDetails}
                    formClassName="grid w-full grid-cols-2 gap-y-5 gap-x-6"
                  />
                </div>
              ) : (
                <div className="flex flex-col items-start w-full">
                  <div className="flex justify-start w-full mt-4">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Firm
                    </div>
                    <div className="flex flex-col w-full max-w-[231px] text-sm text-theme-black-color tracking-tight text-left font-medium">
                      <div className="flex space-x-3">
                        {/* Uncomment below code to display firmImage Upload in frontend */}
                        <div className="flex items-center justify-center w-8 h-8 text-white rounded-full bg-theme-gray-300">
                          {initialValues.firmImage !== undefined ||
                          initialValues.firmImage !== null ? (
                            <ImageComponent
                              src={initialValues.firmImage}
                              className="object-cover w-full h-full rounded-full"
                            />
                          ) : (
                            <Buildings
                              size={18}
                              weight="bold"
                              className="text-theme-black-300"
                            />
                          )}
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="text-sm font-normal tracking-tight text-left text-theme-black-color">
                            {initialValues?.firmName}
                          </div>
                          <div className="text-sm font-normal leading-none text-left text-neutral-400">
                            {initialValues.firmWebsite}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {initialValues.linkedin_link && (
                    <div className="flex justify-between w-full mt-7 ">
                      <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                        LinkedIn Profile
                      </div>
                      <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                        {initialValues.linkedin_link}
                      </div>
                    </div>
                  )}
                  <div className="flex justify-between w-full mt-7 ">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Profile Link
                    </div>
                    <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                      {getDirectoryFirmURL(initialValues.uid)}
                    </div>
                  </div>
                  <div className="flex justify-between w-full mt-7 ">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Firm Address
                    </div>
                    <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                      {address}
                    </div>
                  </div>
                  <div className="flex justify-between w-full mt-7 ">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Team Members
                    </div>
                    <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                      {initialValues.no_of_members} Members
                    </div>
                  </div>
                  {initialValues.short_bio && (
                    <div className="flex flex-col items-start justify-between w-full mt-7">
                      <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                        Bio
                      </div>
                      <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                        {initialValues.short_bio}
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
            </div>
          )
        }
      />
    </div>
  );
};

FirmInfoCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  corporateProfileData: PropTypes.object,
};

export default FirmInfoCard;
