/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import assetbook from "../assets/images/onboarding/assetbook.png";
import career_blueprint from "../assets/images/onboarding/career_blueprint.png";
import growth_showcase from "../assets/images/onboarding/growth_showcase.png";
import income_builder from "../assets/images/onboarding/income_builder.png";
import partner_demos from "../assets/images/onboarding/partner_demos.png";
import tech_stack from "../assets/images/onboarding/tech_stack.png";
import onboarding_bg from "../assets/svgs/onboarding_bg.svg";
import clients from "../assets/svgs/clients.svg";
import competency from "../assets/svgs/competency.svg";
import credibility from "../assets/svgs/credibility.svg";
import { CaretLeft, CaretRight, Circle } from "@phosphor-icons/react";

const courses = [
  {
    img: career_blueprint,
    heading: "PFP Career Blueprint",
    description: "1 Hour 10 Minutes",
  },
  {
    img: income_builder,
    heading: "PFP Income Builder",
    description: "1 Hour 10 Minutes",
  },
  {
    img: assetbook,
    heading: "PFP Assetbook Builder",
    description: "1 Hour 10 Minutes",
  },
  {
    img: tech_stack,
    heading: "PFP TechStack Roadmap",
    description: "1 Hour 10 Minutes",
  },
  {
    img: partner_demos,
    heading: "PFP Partner Demos",
    description: "1 Hour 10 Minutes",
  },
  {
    img: growth_showcase,
    heading: "PFP Growth Showcase",
    description: "1 Hour 10 Minutes",
  },
];

const reasons = [
  {
    img: competency,
    heading: "Build Competency",
    description: "Grow your knowledge & skills",
  },
  {
    img: credibility,
    heading: "Grow Credibility",
    description: "Create and build your professional profile",
  },
  {
    img: clients,
    heading: "Attract Client",
    description: "Attract ideal clients with competency & Credibility",
  },
];

const user_types = [
  "Financial Advisors",
  "MF Distributor",
  "Investment Advisors",
  "Insurance Consultants",
  "Stock-Brokers",
  "Capital Market Pros",
  "Financial Planners",
  "Wealth Managers",
  "Relationship Managers",
  "CFPs / QPFPs / CFAs / CAs",
];

const OnboardingRightContainer = ({ className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef();

  useEffect(() => {
    changeSlide(0);
    return () => clearInterval(intervalRef.current);
  }, []);

  const restartTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % 3);
    }, 4000);
  };

  const getSlideIndicatorStyle = (i) => {
    if (currentSlide === i)
      return "w-4 h-4 min-w-[16px] text-theme-red cursor-pointer";
    return "w-2 h-2 min-w-[8px] text-theme-black-300/40 cursor-pointer";
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % 3);
    restartTimer();
  };
  const changeSlide = (i) => {
    setCurrentSlide(i);
    restartTimer();
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(2);
      restartTimer();
      return;
    }
    setCurrentSlide((e) => e - 1);
    restartTimer();
  };

  const slides = [
    <React.Fragment key={0}>
      <div className="text-3xl font-bold leading-10 text-center text-theme-black-color">
        Join India&apos;s No.1 Knowledge Platform & Community for Personal
        Finance Professionals
      </div>
      <div className="mt-10 text-xl font-semibold leading-6 text-center text-theme-black-color">
        Courses included in your free basic membership
      </div>
      <div className="grid grid-cols-3 gap-10 mt-8">
        {courses.map((course, c_index) => (
          <div key={c_index} className="flex flex-col bg-white">
            <img
              src={course.img}
              alt={course.heading}
              className="object-cover w-auto lg:h-36 xl:h-60"
            />
            <div className="px-4 my-4 text-base font-medium leading-5 text-theme-black-color">
              {course.heading}
            </div>
            {/* <div className="mt-0.5 px-4 mb-4 leading-3 text-xs text-theme-black-300">
              {course.description}
            </div> */}
          </div>
        ))}
      </div>
    </React.Fragment>,
    <React.Fragment key={1}>
      <div className="text-3xl font-bold leading-10 text-center text-theme-black-color">
        Inviting all Personal Finance Professionals to join India&apos;s largest
        knowledge platform
      </div>
      <div className="mt-10 text-xl font-semibold leading-6 text-center text-theme-black-color">
        Who Should Join Network FP?
      </div>
      <div className="grid grid-cols-2 gap-10 mt-8">
        {user_types.map((type, t_index) => (
          <div
            key={t_index}
            className="flex py-3.5 gap-2.5 px-4 bg-white items-start"
          >
            <div className="w-6 h-6 min-w-[24px] flex items-center justify-center bg-theme-red/20 rounded-full">
              <div className="w-3 h-3 min-w-[12px] bg-theme-red rounded-full"></div>
            </div>
            <div className="text-xl font-medium leading-5 text-theme-black-color">
              {type}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>,
    <React.Fragment key={2}>
      <div className="text-3xl font-bold leading-10 text-center text-theme-black-color">
        Get all the practical knowledge & tools you need for success at one
        place!
      </div>
      <div className="mt-10 text-xl font-semibold leading-6 text-center text-theme-black-color">
        Why Join Network FP?
      </div>
      <div className="grid max-w-xl grid-cols-1 gap-10 mx-auto mt-8">
        {reasons.map((reason, r_index) => (
          <div key={r_index} className="flex items-center gap-6 p-8 bg-white">
            <img
              src={reason.img}
              alt={reason.heading}
              className="w-auto h-20"
            />
            <div className="flex flex-col items-start justify-center gap-1">
              <div className="text-2xl font-bold leading-5 text-theme-black-color">
                {reason.heading}
              </div>
              <div className="text-xl text-theme-black-300">
                {reason.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>,
  ];

  return (
    <div className={classNames("", className)}>
      <div className="flex bg-sky-50 justify-between py-9 md:py-16 flex-col items-center px-[5vw] xl:px-[8vw] relative w-full h-[1024px] overflow-clip">
        <img
          src={onboarding_bg}
          className="absolute z-0 object-cover w-full h-full left-2/3 bottom-2/3"
          alt="bg"
        />
        <img
          src={onboarding_bg}
          className="absolute z-0 object-cover w-full h-full right-2/3 top-2/3"
          alt="bg"
        />
        <div className="z-10">{slides[currentSlide]}</div>
        <div className="flex items-center justify-center gap-6 mt-10">
          <CaretLeft
            className="w-4 h-4 min-w-[16px] text-theme-black-color cursor-pointer"
            weight="fill"
            onClick={() => prevSlide()}
          />
          <div className="flex items-center justify-center gap-3.5">
            <Circle
              className={getSlideIndicatorStyle(0)}
              onClick={() => changeSlide(0)}
              weight="fill"
            />
            <Circle
              className={getSlideIndicatorStyle(1)}
              onClick={() => changeSlide(1)}
              weight="fill"
            />
            <Circle
              className={getSlideIndicatorStyle(2)}
              onClick={() => changeSlide(2)}
              weight="fill"
            />
          </div>
          <CaretRight
            className="w-4 h-4 min-w-[16px] text-theme-black-color cursor-pointer"
            weight="fill"
            onClick={() => nextSlide()}
          />
        </div>
      </div>
    </div>
  );
};
OnboardingRightContainer.defaultProps = { className: "" };
OnboardingRightContainer.propTypes = { className: PropTypes.string };

export default OnboardingRightContainer;
