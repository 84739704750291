/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { CourseService } from "../../../services/CourseService";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../../stories/Toast";

const RedirectToSessionContent = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    try {
      let res;
      if (_.isEmpty(props.location?.state?.curriculumData)) {
        res = await CourseService.fetchCourseDetails({
          course_id: props.course_id,
        });
      } else {
        res = props.location?.state?.curriculumData;
      }
      if (
        res.course.modules.filter((m) => m.is_locked !== true).length === 0 &&
        res.course.course_type !== "pro_member"
      ) {
        CustomToast.error("Content is not available to watch");
        navigate(`/programs/${props.course_id}`, { replace: true });
        return;
      }
      if (!props.session_content_id) {
        navigate(
          `/programs/${props.course_id}/learn/${
            _.head(
              _.compact(
                _.flatMapDeep(res.course.modules, (e) =>
                  e.sessions?.map((e1) => e1.session_content)
                )
              )
            ).id ?? ""
          }`,
          {
            replace: true,
            state: { curriculumData: res },
          }
        );
        return;
      }
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_message);
    }
  };

  return <div></div>;
};

export default RedirectToSessionContent;
