import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PublicProfileCard from "../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import _ from "lodash";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import FormBuilder from "../../stories/FormBuilder";
import { Validations } from "../../constants/validations";

const ContactInfoCard = ({
  className,
  publicProfileData,
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    setInitialValues({
      email: publicProfileData.profile_detail?.public_email ?? "",
      phone:
        publicProfileData.profile_detail?.public_mobile_number ??
        publicProfileData.phone_number ??
        "",
      whatsapp_link: publicProfileData.profile_detail?.whatsapp_link ?? "",
    });
  }, [publicProfileData]);

  const handleSubmit = async (data) => {
    try {
      let postBody = {
        public_email: data.email,
        // public_mobile_number: data.phone,
        whatsapp_link: data.whatsapp_link,
      };
      if (publicProfileData["profile_detail"]?.id) {
        postBody["id"] = publicProfileData["profile_detail"].id;
      }
      if (data.phone !== undefined && data.phone !== null) {
        postBody = {
          ...postBody,
          public_mobile_number: data.phone,
        };
      }
      if (data.whatsapp_link !== undefined && data.whatsapp_link !== null) {
        postBody = {
          ...postBody,
          whatsapp_link: data.whatsapp_link,
        };
      }
      try {
        const result = await PublicProfileService.updatePublicProfile({
          profile_detail_attributes: postBody,
        });
        if (result.message) {
          CustomToast.success(result.message);
          onUpdate();
          setIsEditing(false);
        }
      } catch (e) {
        CustomToast.error(e.error_messages[0]);
      }
    } catch (e) {}
  };
  useEffect(() => {
    setIsEmpty(
      Object.values(initialValues).filter((val) => !_.isEmpty(val)).length === 0
    );
  }, [initialValues]);
  const textInputDetails = [
    {
      label: "Email Address",
      name: "email",
      placeholder: "Email Address",
      className: "min-w-186",
      isViewOnly: !isEditing,
    },
    {
      label: "Mobile No. (10 digits)",
      name: "phone",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186",
      type: "number",
      isRequired: false,
      isViewOnly: !isEditing,
    },
    {
      label: "Whatsapp No. (10 digits)",
      name: "whatsapp_link",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
    },
  ];

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Contact Info"
        subHeading={
          "Update your Contact information here for public to contact you."
        }
        id={"contact-details-card"}
        isEditing={isEditing}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && isEmpty}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              message="To see contact information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#contact-details-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              <div className="flex ">
                <FormBuilder
                  initialValues={initialValues}
                  validationSchema={
                    Validations.publicProfile.contactInfoValidationSchema
                  }
                  onFormSubmit={handleSubmit}
                  formClassName="grid w-full gap-y-5 gap-x-6 grid-cols-1 md:grid-cols-2"
                  inputFieldsDetails={textInputDetails}
                  submitButtonRef={submitRef}
                  cancelButtonRef={cancelRef}
                />
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

ContactInfoCard.propTypes = {
  className: PropTypes.any,
  isLoading: PropTypes.any,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default ContactInfoCard;
