/* eslint-disable no-unused-vars */
import { Circle, User } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import forum_comment from "../assets/svgs/forum_comment.svg";
import forum_thumb from "../assets/svgs/forum_thumb.svg";
import forum_thumb_filled from "../assets/svgs/forum_thumb_filled.svg";
import { ContentBox } from "./CCContent";
import { ForumService } from "../services/ForumService";
import { CustomToast } from "./Toast";
import { useNavigate } from "@reach/router";
import moment from "moment";
import _ from "lodash";
import CustomLink from "./Link";
import ImageComponent from "./ImageComponent";
import { User as UserStore } from "../store";
import LoginUserModal from "../components/Modals/LoginUserModal";
import { useProfile } from "../context/userProvider";
import mixpanel from "mixpanel-browser";
import { getDirectoryMemberURL } from "../util/getURLs";

const ForumList = ({ forumList = [], categories = [] }) => {
  return (
    <div className="flex flex-col w-full gap-6">
      {forumList.map((forum, index) => {
        return (
          <ForumListItem data={forum} key={forum.uid} categories={categories} />
        );
      })}
    </div>
  );
};

ForumList.propTypes = {
  categories: PropTypes.array,
  currentPage: PropTypes.number,
  forumList: PropTypes.array,
  onPageChange: PropTypes.func,
  paginationProps: PropTypes.any,
};

export const ForumListItem = ({
  data = {},
  isInList = true,
  categories = [],
  onViewResponseClick = () => {},
}) => {
  const navigate = useNavigate();
  const isLoggedIn = !!UserStore.getToken();
  const [showLoginPopup, setShowLoginPopup] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(data.total_likes ?? 0);
  const { userDetails } = useProfile();
  const isProMember =
    (userDetails.is_pro_member &&
      ["provisional", "approved", "expires_soon"].includes(
        userDetails.pm_signup_status
      )) ||
    (!userDetails.is_pro_member &&
      ["provisional"].includes(userDetails.pm_signup_status));

  const validateIsPromember = () => {
    if (!isProMember) {
      CustomToast.info("Become a promember to enjoy exclusive benefits");
      return false;
    }
    return true;
  };

  useEffect(() => {
    setIsLiked(data.is_user_liked);
    setLikeCount(data.total_likes);
  }, [data]);

  const onClickLike = async () => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    if (!validateIsPromember()) {
      return;
    }
    try {
      isLiked
        ? await ForumService.unlikeForum(data.id)
        : await ForumService.likeForum(data.id);
      setIsLiked(!isLiked);
      setLikeCount((prev) => (isLiked ? prev - 1 : prev + 1));
      mixpanel.track("Exchange Forum Upvote", {
        distinct_id: userDetails.email,
        "Unique ID": `${userDetails.id}_${data.id}`,
        "Forum ID": data.id,
        status: isLiked ? "Unlike" : "Liked",
      });
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };
  return (
    <>
      <div className="flex flex-col w-full gap-3 p-3 bg-white lg:p-6 lg:gap-4">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-full bg-theme-black-300/20">
            <CustomLink
              textColor="text-blue-600"
              activeTextColor="text-blue-600"
              showUnderLine={false}
              underLineOnlyOnHover={false}
              fontSize="sm"
              target="_blank"
              href={getDirectoryMemberURL(data?.user_detail?.profile_slug)}
              className="font-normal leading-5 tracking-tight h-fit"
            >
              <ImageComponent
                src={data?.user_detail?.profile_picture}
                errorComponent={
                  <div className="flex items-center justify-center w-8 h-8  min-w-[32px] bg-gray-200 rounded-full">
                    <User weight="bold" className="w-6 h-6 text-gray-400" />
                  </div>
                }
                alt="user"
                className="object-cover w-8 h-8 min-w-[32px] rounded-full"
              />
            </CustomLink>
          </div>
          <div className="flex flex-col">
            <div className="block text-xs leading-none lg:text-sm">
              <span className="text-theme-black-color">Posted by</span>{" "}
              <CustomLink
                textColor="text-blue-600"
                activeTextColor="text-blue-600 font-medium"
                showUnderLine={false}
                underLineOnlyOnHover={false}
                fontSize="sm"
                target="_blank"
                href={getDirectoryMemberURL(data?.user_detail?.profile_slug)}
                className="font-normal leading-5 tracking-tight h-fit"
              >
                {data?.user_detail?.first_name ?? ""}{" "}
                {data?.user_detail?.last_name ?? ""}
              </CustomLink>
            </div>
            <div className="block leading-none text-xxs lg:text-xs">
              {data.user_detail?.is_pro_member && (
                <>
                  <span className="inline-block font-medium text-theme-red">
                    ProMember
                  </span>
                  <Circle
                    size={4}
                    weight="fill"
                    className="inline-block mx-1 text-theme-gray min-w-[4px]"
                  />
                </>
              )}
              <span className="inline-block text-theme-gray-600">
                {moment(data.created_at).fromNow()}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full border-t border-gray-100"></div>
        <div className="flex flex-wrap gap-2.5">
          {categories
            .filter((c) => data.forum_category_ids?.includes(c.id))
            .map((category) => (
              <div
                key={category.id}
                className="border border-zinc-300 text-theme-black-color rounded-full py-1.5 px-3 text-xxs lg:text-xs leading-3 lg:leading-4"
                // onClick={() => {
                //   navigate(
                //     `/exchange?${queryString.stringify({
                //       filters: [category.id],
                //     })}`
                //   );
                // }}
              >
                {category.label}
              </div>
            ))}
        </div>
        <div className="flex flex-col gap-2 pt-1 lg:pt-0">
          <div className="text-lg font-semibold leading-none lg:font-bold lg:text-2xl text-theme-black-color">
            {data.title}
          </div>
          <div className="text-sm lg:text-base text-theme-black-300">
            {data.description}
          </div>
          {!_.isEmpty(data.attachment) && (
            <ContentBox
              type={data.attachment.file_type}
              src={data.attachment.signed_url}
            />
          )}
        </div>
        <div className="w-full border-t border-gray-100"></div>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex text-xxs lg:text-xs text-theme-black-color">
            <div className="block cursor-pointer" onClick={onClickLike}>
              <img
                src={isLiked ? forum_thumb_filled : forum_thumb}
                className="w-4 h-4 inline-block mr-1.5"
                alt="Like button"
              />
              {likeCount}
            </div>
            <div
              className="block ml-4 cursor-pointer lg:ml-8"
              onClick={
                isLoggedIn
                  ? isProMember
                    ? isInList
                      ? () => navigate(`/exchange/${data.id ?? 1}/discuss`)
                      : onViewResponseClick
                      ? () => onViewResponseClick()
                      : null
                    : () =>
                        CustomToast.info(
                          "Become a promember to enjoy exclusive benefits"
                        )
                  : () => setShowLoginPopup(true)
              }
            >
              <img
                src={forum_comment}
                className="w-4 h-4 inline-block mr-1.5"
                alt="Comment button"
              />
              {data.total_responses ?? 0}
              <span className="text-theme-black-300"> response</span>
            </div>
          </div>
          {!_.isEmpty(data?.last_response_user_detail) && (
            <div className="flex items-center gap-1.5">
              <div className="w-8 h-8 rounded-full bg-theme-black-300/20">
                <CustomLink
                  textColor="text-blue-600"
                  activeTextColor="text-blue-600"
                  showUnderLine={false}
                  underLineOnlyOnHover={false}
                  fontSize="sm"
                  target="_blank"
                  href={getDirectoryMemberURL(
                    data?.last_response_user_detail?.profile_slug
                  )}
                  className="font-normal leading-5 tracking-tight h-fit"
                >
                  <ImageComponent
                    src={data?.last_response_user_detail?.profile_picture}
                    errorComponent={
                      <div className="flex items-center justify-center w-8 h-8 min-w-[32px] bg-gray-200 rounded-full">
                        <User weight="bold" className="w-6 h-6 text-gray-400" />
                      </div>
                    }
                    alt="user"
                    className="object-cover w-8 h-8 min-w-[32px] rounded-full"
                  />
                </CustomLink>
              </div>
              <div className="flex flex-col">
                <div className="block leading-none tracking-tight text-xxs lg:text-sm">
                  <span className="text-theme-black-color">
                    Last response by
                  </span>{" "}
                  <CustomLink
                    textColor="text-blue-600"
                    activeTextColor="text-blue-600"
                    showUnderLine={false}
                    underLineOnlyOnHover={false}
                    fontSize="sm"
                    target="_blank"
                    href={getDirectoryMemberURL(
                      data?.last_response_user_detail?.profile_slug
                    )}
                    className="font-normal leading-5 tracking-tight h-fit"
                  >
                    {data?.last_response_user_detail?.first_name ?? ""}{" "}
                    {data?.last_response_user_detail?.last_name ?? ""}
                  </CustomLink>
                </div>
                <div className="block leading-none tracking-tighter text-[8px] lg:text-xs">
                  {data.last_response_user_detail?.is_pro_member && (
                    <>
                      <span className="inline-block font-medium text-theme-red">
                        ProMember
                      </span>{" "}
                      <Circle
                        size={4}
                        weight="fill"
                        className="inline-block mx-1 text-theme-gray min-w-[4px]"
                      />
                    </>
                  )}
                  <span className="inline-block tracking-wide text-theme-gray-600">
                    {moment(data.updated_at).format("DD-MM-YYYY hh:mm A")}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showLoginPopup && (
        <LoginUserModal
          className=""
          open={showLoginPopup}
          prefill_data={{}}
          onLoginSuccess={() => {
            window.location.refresh();
          }}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </>
  );
};

ForumListItem.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.object,
  isInList: PropTypes.bool,
  onViewResponseClick: PropTypes.func,
};

export default ForumList;
