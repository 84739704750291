import PropTypes from "prop-types";
import { useProfile } from "../../../context/userProvider";
import { Check, ExclamationMark, LockSimple, X } from "@phosphor-icons/react";
import { Certificate } from "@phosphor-icons/react";
import progressSvg from "../../../assets/svgs/progress.svg";
import qpfpBadge from "../../../assets/svgs/qpfp_badge.svg";
import qpfpCertificate from "../../../assets/svgs/qpfp_certificate.svg";
import classNames from "classnames";
import UserProfileAvatar from "../../../stories/UserProfileAvatar";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../../stories/Toast";

export const CertificateBadgesCard = ({
  moduleStatuses,
  certificateStatus,
  onClick,
}) => {
  return (
    <div
      className="w-full overflow-clip cursor-pointer p-4 bg-theme-orange/10 rounded md:rounded-2xl flex flex-col md:flex-row gap-4 md:items-center"
      onClick={onClick}
    >
      <div className="hidden md:flex w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
        <Certificate
          weight="fill"
          className="h-4 w-4 md:h-8 md:w-8 text-white"
        />
      </div>
      <div className="flex grow flex-col gap-3 text-theme-black-color">
        <div className="text-sm flex items-center gap-3 font-bold leading-4 lg:leading-5 tracking-tight lg:text-xl md:text-base">
          <div className="flex md:hidden w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
            <Certificate
              weight="fill"
              className="h-4 w-4 md:h-8 md:w-8 text-white"
            />
          </div>
          Badges & Certificates
        </div>
        <div className="text-xs md:text-sm leading-4 lg:leading-5 tracking-tight lg:text-base md:flex">
          View, download and shares your badges and certificate
        </div>
      </div>
      <div className="flex gap-4 items-center">
        {moduleStatuses.map((e, index) => {
          return (
            <img
              key={index}
              src={qpfpBadge}
              className={classNames("w-10 h-10", !e && "opacity-40")}
              alt="QPFP badge"
            />
          );
        })}
        <img
          src={qpfpCertificate}
          className={classNames(
            "w-10 h-10",
            !certificateStatus && "opacity-40"
          )}
          alt="QPFP badge"
        />
      </div>
    </div>
  );
};

CertificateBadgesCard.propTypes = {
  certificateStatus: PropTypes.bool,
  moduleStatuses: PropTypes.array,
  onClick: PropTypes.func,
};

export const LeaderboardCard = ({
  trackStatus,
  completionPercentage,
  onClick,
}) => {
  const { userDetails } = useProfile();
  return (
    <div
      className="w-full p-4 cursor-pointer bg-theme-orange/10 rounded md:rounded-2xl flex flex-col md:flex-row gap-4 md:items-center"
      onClick={onClick}
    >
      <div className="hidden md:flex w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
        <img
          src={progressSvg}
          className="h-4 w-4 md:h-8 md:w-8"
          alt="Progress tracker"
        />
      </div>
      <div className="flex grow flex-col gap-3 text-theme-black-color">
        <div className="text-sm flex items-center gap-3 font-bold leading-4 lg:leading-5 tracking-tight lg:text-xl md:text-base">
          <div className="flex md:hidden w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
            <img
              src={progressSvg}
              className="h-4 w-4 md:h-8 md:w-8"
              alt="Progress tracker"
            />
          </div>
          Leaderboard
        </div>
        <div className="text-xs md:text-sm leading-4 lg:leading-5 tracking-tight lg:text-base md:flex">
          Check out how your batchmates & friends are progressing
        </div>
      </div>
      <div>
        <div className="flex items-center self-center flex-shrink-0">
          <div className="pr-2.5 flex flex-col gap-1 items-start">
            <div className="text-base whitespace-nowrap font-medium text-theme-black-color">
              {userDetails.first_name} {userDetails.last_name}
            </div>
            <div className="flex gap-1 items-center">
              <div
                className={classNames(
                  "rounded-full py-0.5 px-1 font-medium text-xxs text-white",
                  trackStatus === "Off Track"
                    ? "bg-theme-red"
                    : trackStatus === "On Track"
                    ? "bg-green-600"
                    : "bg-theme-yellow"
                )}
              >
                {completionPercentage ?? 0}%
              </div>
              <div
                className={classNames(
                  "text-xxs font-semibold tracking-wider",
                  trackStatus === "Off Track"
                    ? "text-theme-red"
                    : trackStatus === "On Track"
                    ? "text-green-600"
                    : "text-theme-yellow"
                )}
              >
                {trackStatus}
              </div>
            </div>
          </div>
          <UserProfileAvatar
            imageUrl={userDetails.profile_picture?.signed_url}
            isLoggedInUser
          />
        </div>
      </div>
    </div>
  );
};
LeaderboardCard.propTypes = {
  completionPercentage: PropTypes.number,
  onClick: PropTypes.func,
  trackStatus: PropTypes.string,
};

const ShowStatus = ({ status }) => {
  switch (status) {
    case "passed":
      return (
        <div className="flex items-center gap-1">
          <div className="w-4 min-w-[16px] flex items-center justify-center h-4 rounded-full bg-green-600/20">
            <Check
              className="text-green-600 min-w-[8px]"
              weight="bold"
              size={12}
            />
          </div>
          <div className="text-green-600 whitespace-nowrap text-sm font-medium">
            Passed
          </div>
        </div>
      );
    case "failed":
      return (
        <div className="flex items-center gap-1">
          <div className="w-4 min-w-[16px] flex items-center justify-center h-4 rounded-full bg-theme-red-300/20">
            <X
              className="text-theme-red-300 min-w-[8px]"
              weight="bold"
              size={12}
            />
          </div>
          <div className="text-theme-red-300 whitespace-nowrap text-sm font-medium">
            Failed
          </div>
        </div>
      );

    default:
      return (
        <div className="flex items-center gap-1">
          <div className="w-4 min-w-[16px] flex items-center justify-center h-4 rounded-full bg-yellow-600/20">
            <ExclamationMark
              className="text-yellow-600 min-w-[12px]"
              weight="bold"
              size={12}
            />
          </div>
          <div className="text-yellow-600 whitespace-nowrap text-sm font-medium">
            Not Completed
          </div>
        </div>
      );
  }
};

ShowStatus.propTypes = {
  status: PropTypes.string,
};

const ShowLock = ({ isLocked, className }) => {
  if (!isLocked) return null;
  return (
    <LockSimple
      weight="fill"
      className={classNames(
        "ml-auto min-w-[18px] text-sm font-semibold leading-5 tracking-tighter md:text-lg lg:text-md text-theme-red",
        className
      )}
    />
  );
};

ShowLock.propTypes = {
  className: PropTypes.string,
  isLocked: PropTypes.bool,
};

export const TopicTestTrackerCard = ({
  quiz,
  courseId,
  moduleIsLock = true,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex lg:flex-col gap-2 col-span-1 rounded-xl bg-white justify-between shadow-card-shadow p-4 relative">
      <ShowLock isLocked={moduleIsLock} className="absolute top-4 right-4" />
      <div className="flex flex-col gap-1 items-start">
        <div className="font-bold text-sm text-theme-black-color">
          {quiz.title}
        </div>
        <div className="text-theme-black-300 text-sm">{quiz.start_time}</div>
      </div>
      <div className="flex flex-col gap-1 items-end md:items-start">
        <ShowStatus status={quiz.status} />
        {quiz.status != null && (
          <div className="text-theme-black-color text-sm font-medium">
            {quiz.percentage}%
          </div>
        )}
        <button
          className={classNames(
            "flex w-fit px-1.5 py-0.5 items-center mt-1 justify-center text-xs whitespace-nowrap lg:text-sm font-medium rounded-full transition-all line-clamp-1",
            quiz.status === null
              ? "bg-theme-black-color hover:bg-theme-black-color/80 text-white"
              : "bg-theme-gray-400 hover:bg-theme-gray-400/80"
          )}
          onClick={() => {
            if (moduleIsLock) {
              CustomToast.error(
                "Kindly make the payment to access these modules & sessions"
              );
              return;
            }
            navigate(
              `/programs/${courseId}/learn/${quiz.course_session_content_id}`
            );
          }}
        >
          {quiz.status === null ? "Click to attempt" : "View Result"}
        </button>
      </div>
    </div>
  );
};

TopicTestTrackerCard.propTypes = {
  courseId: PropTypes.number,
  moduleIsLock: PropTypes.bool,
  quiz: PropTypes.object,
};

export const LevelExamTrackerCard = ({
  quiz,
  courseId,
  moduleIsLock = true,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between rounded-md bg-white border border-gray-200 w-full p-4">
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-3.5">
        <div className="font-bold text-sm text-theme-black-color">
          {quiz.title}
        </div>
        <div className="text-theme-black-300 text-sm">{quiz.start_time}</div>
      </div>
      <div className="flex flex-col lg:flex-row items-end lg:items-center gap-1 lg:gap-3.5">
        <ShowLock isLocked={moduleIsLock} />
        <ShowStatus status={quiz.status} />
        {quiz.status != null && (
          <div className="text-theme-black-color text-sm font-medium">
            {quiz.percentage}%
          </div>
        )}
        <button
          className={classNames(
            " py-0.5 w-full mt-1 px-1.5 items-center justify-center text-xs whitespace-nowrap lg:text-sm font-medium rounded-full transition-all line-clamp-1",
            quiz.status === null
              ? "bg-theme-black-color hover:bg-theme-black-color/80 text-white"
              : "bg-theme-gray-500 hover:bg-theme-gray-500/80"
          )}
          onClick={() => {
            if (moduleIsLock) {
              CustomToast.error(
                "Kindly make the payment to access these modules & sessions"
              );
              return;
            }
            navigate(
              `/programs/${courseId}/learn/${quiz.course_session_content_id}`
            );
          }}
        >
          {quiz.status === null
            ? "Click to attempt"
            : quiz.status === "failed" && quiz.quiz_type === "level_exam"
            ? "Retry"
            : "View Result"}
        </button>
      </div>
    </div>
  );
};

LevelExamTrackerCard.propTypes = {
  courseId: PropTypes.number,
  moduleIsLock: PropTypes.bool,
  quiz: PropTypes.object,
};
