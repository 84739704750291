/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import AppSkeleton from "../AppSkeleton";
import { ArrowLeft } from "@phosphor-icons/react";
import { ForumListItem } from "../../stories/ForumList";
import {
  ForumCommentAndReplies,
  ForumReplyBox,
} from "../../stories/ForumComment";
import { useNavigate } from "@reach/router";
import { ForumService } from "../../services/ForumService";
import useComments from "../../hooks/useComments";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import Button from "../../stories/Button";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";
import _ from "lodash";
import mixpanel from "mixpanel-browser";

const ViewForum = (props) => {
  const navigate = useNavigate();
  const [forum, setForum] = useState({});
  const [page, setPageNumber] = React.useState(1);
  const { loading, error, comments, hasMore } = useComments(
    props.exchange_id,
    page
  );
  const [newComments, setNewComments] = useState([]);
  const forumItemRef = useRef();
  const commentRef = useRef();
  const { userDetails } = useProfile();

  const fetchForum = async () => {
    const response = await ForumService.getForum(props.exchange_id);
    setForum(response.exchange_forum);
  };

  useEffect(() => {
    commentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    fetchForum();
  }, [props.exchange_id]);

  useEffect(() => {
    const isProMember =
      (userDetails.is_pro_member &&
        ["provisional", "approved", "expires_soon"].includes(
          userDetails.pm_signup_status
        )) ||
      (!userDetails.is_pro_member &&
        ["provisional"].includes(userDetails.pm_signup_status));

    if (!_.isEmpty(userDetails) && !isProMember) {
      CustomToast.info(
        "Become an active Promember to enjoy complete benefits."
      );
      navigate("/");
    }
  }, [userDetails]);

  useEffect(() => {
    const isProMember =
      (userDetails.is_pro_member &&
        ["provisional", "approved", "expires_soon"].includes(
          userDetails.pm_signup_status
        )) ||
      (!userDetails.is_pro_member &&
        ["provisional"].includes(userDetails.pm_signup_status));
    if (!_.isEmpty(userDetails) && !_.isEmpty(forum) && isProMember) {
      mixpanel.track("View Exchange Forum", {
        distinct_id: userDetails.email,
        "Forum ID": forum.id,
        "Forum Title": forum.title,
      });
    }
  }, [userDetails, forum]);

  return (
    <AppSkeleton
      hideSidebar
      pageLabel=""
      bgColor="bg-theme-grey-200"
      headerClassName="shadow-none"
    >
      <div className={classNames("min-h-[calc(100vh-80px)]")}>
        <Header className={"hidden lg:flex"} hideControls={true} />
        <div className="flex justify-center px-4 py-6 lg:px-20">
          <div className="flex relative w-full flex-col max-w-[938px]">
            <div
              ref={forumItemRef}
              className="flex items-center justify-start gap-2 mb-6 text-base cursor-pointer w-fit text-theme-black-color"
              onClick={() => navigate("/exchange")}
            >
              <ArrowLeft
                size={16}
                className="w-4 min-w-[16px] h-4 text-theme-black-color"
              />{" "}
              Back
            </div>
            <ForumListItem
              isInList={false}
              data={forum}
              onViewResponseClick={() => {
                commentRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
            />
            <div className="h-6" ref={commentRef} />
            {!loading && !error && (
              <ForumReplyBox
                forum_id={props.exchange_id}
                afterInsertCommentCallback={(comment) => {
                  setNewComments((prev) => [...prev, comment]);
                  mixpanel.track("Exchange Forum Comment", {
                    distinct_id: userDetails.email,
                    "Forum ID": props.exchange_id,
                    Comment: comment.content,
                  });
                  commentRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              />
            )}
            {!loading && !error && (
              <div className="mt-6 mb-4 text-xl font-bold leading-6 text-theme-black-color">
                {(forum.total_responses ?? 0) + newComments.length} responses
              </div>
            )}
            {[...newComments, ...comments].map((comment, index) => (
              <ForumCommentAndReplies
                key={comment.id}
                comment={comment}
                forum_id={props.exchange_id}
              />
            ))}
            {loading && (
              <div className="my-5">
                <Loader />
              </div>
            )}
            {!loading && !error && hasMore && (
              <Button
                buttonStyle="transparent-textonly-primary"
                className="mb-4"
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }}
              >
                Load more responses
              </Button>
            )}
            <div>{!loading && error && <ErrorScreen />}</div>
            {!loading && !error && comments.length > 5 && (
              <ForumReplyBox
                forum_id={props.exchange_id}
                afterInsertCommentCallback={(comment) => {
                  setNewComments((prev) => [...prev, comment]);
                  mixpanel.track("Exchange Forum Comment", {
                    distinct_id: userDetails.email,
                    "Forum ID": props.exchange_id,
                    Comment: comment.content,
                  });
                  commentRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default ViewForum;
