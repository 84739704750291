import { useAPI } from "./SWRService";
import ApiConfig from "../api/config";
import _ from "lodash";

export const useCourses = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.fetch_all_courses,
    config
  );
  return {
    courses: data?.courses || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCourseCurriculum = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null && course_id !== undefined
      ? ApiConfig.urls.courses.course_details(course_id)
      : null,
    config
  );
  console.log(course_id);
  return {
    curriculum: data?.course || {},
    eventDetails: data?.event_details || {},
    lastViewContentDetails: data?.last_viewed_content,
    error,
    loading: course_id === null || course_id === undefined ? false : loading,
    mutate,
    isValidating,
  };
};

export const useAcePrograms = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.ace_programs,
    config
  );
  return {
    programs: data?.ace_programs ?? [],
    allowRegistration: data?.allow_registration,
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCourseLockDetail = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_lock_details(course_id)
      : null,
    config
  );
  return {
    course_lock_details: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useRatingsStats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null ? ApiConfig.urls.courses.rating_stats(course_id) : null,
    config
  );
  return {
    ratings_stats: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCourseStats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_content_stats(course_id)
      : null,
    config
  );
  let newData = {};
  if (_.isEmpty(data)) {
    newData = {};
  } else {
    newData = _.mapKeys(data?.data?.progress ?? {}, (__, key) => {
      return key.replace("_", "");
    });
  }

  return {
    course_stats: newData,
    zoom_session_details: _.isEmpty(data) ? {} : data.zoom_session_detail,
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCourseSessionStats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_session_stats(course_id)
      : null,
    config
  );
  return {
    course_session_stats: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useSessionContentReview = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.curriculum(course_id),
    config
  );
  return {
    curriculum: data?.course || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useQPFPQuizTracker = (course_type, course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_type === "qpfp"
      ? ApiConfig.urls.courses.qpfp_quiz_tracker(course_id)
      : null,
    config
  );

  return {
    qpfpQuizTracker: data?.modules || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useQPFPProgressTracker = (course_type, course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_type === "qpfp" && _.isInteger(_.parseInt(course_id))
      ? ApiConfig.urls.courses.qpfp_progress_tracker(course_id)
      : null,
    config
  );

  return {
    qpfpProgressTracker: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
