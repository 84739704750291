/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import CourseDetails from "./CourseDetails";
import { User } from "../../store";
import { useWindowSize } from "../../hooks/useWindowSize";
import AppSkeleton from "../AppSkeleton";
import AccountCreationBar from "../../stories/AccountCreationBar";
import MembershipFormContainer from "./MembershipFormContainer";
import { useLocation } from "@reach/router";
import QueryString from "qs";
import {
  useCurrentUserPublicProfile,
  useMembershipDetails,
  useOptions,
} from "../../services/BasicDetails";
import { CourseService } from "../../services/CourseService";
import { CustomToast } from "../../stories/Toast";
import { EventsService } from "../../services/EventsService";
import _ from "lodash";

const MembershipRegistration = (props) => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const isNewUser = !User.getToken();
  const userTempTokenRef = useRef("");
  const location = useLocation();
  const parsed = QueryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );
  const [courseDetails, setCourseDetails] = useState({});
  const [showRSVP, setShowRSVP] = useState(false);
  const {
    membership: currentMembershipDetails,
    loading: membershipLoading,
    mutate: fetchMembership,
  } = useMembershipDetails(
    ["community_member", "provisional_member"].includes(props.entity_type)
      ? "provisional_member"
      : props.entity_type,
    {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    },
    userTempTokenRef.current,
    props.location?.state?.course_id ?? parsed.course_id
  );
  const section_names = ["community_member", "provisional_member"].includes(
    props.entity_type
  )
    ? [
        "provisional_member_qpfp_interested_id",
        "provisional_member_qualification_id",
        "provisional_member_exam_id",
        "provisional_member_graduation_stream_id",
      ]
    : [
        `${props.entity_type}_qualification_id`,
        `${props.entity_type}_exam_id`,
        `${props.entity_type}_graduation_stream_id`,
      ];

  const { options } = useOptions(section_names, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const { publicProfileData, loading: publicProfileLoading } =
    useCurrentUserPublicProfile({
      revalidateOption: { revalidateOnFocus: false },
    });

  const fetchData = async () => {
    try {
      const response = await CourseService.fetchCourseDetailsForMembership({
        course_type: ["community_member", "provisional_member"].includes(
          props.entity_type
        )
          ? "provisional_member"
          : props.entity_type,
        course_id: props.location?.state?.course_id ?? parsed.course_id,
      });
      setCourseDetails(response);
    } catch (e) {
      CustomToast.error("Failed to fetch course details.");
    }
  };

  const fetchEvents = async () => {
    try {
      if (
        props.entity_type !== "pro_member" &&
        props.entity_type !== "community_member" &&
        props.entity_type !== "provisional_member"
      )
        return;
      const response = await EventsService.fetchRSVPEventDetails();
      const tempevents = isNewUser
        ? response.events
        : response.events.filter((event) => !event.has_rsvp_registered);
      setShowRSVP(tempevents.length > 0);

      if (_.isEmpty(currentMembershipDetails)) return;
      if (
        currentMembershipDetails.status === "approved" &&
        currentMembershipDetails.course.id &&
        tempevents.length === 0
      ) {
        // navigate(`/programs/${currentMembershipDetails.course.id}/`);
        CustomToast.info(
          "You are already a member. We are redirecting you to course."
        );
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!membershipLoading) {
      fetchEvents();
    }
  }, [membershipLoading]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
      isLoading={membershipLoading || publicProfileLoading}
    >
      <div className="flex justify-center min-h-screen px-4 py-6 lg:px-16 h-fit">
        <div className="flex flex-col justify-center w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
          <div className="flex bg-white rounded-sm shadow-none lg:sticky lg:top-20 h-fit basis-full lg:basis-5/12 lg:shadow-sm">
            <CourseDetails
              className=""
              isNewUser={false}
              courseDetails={courseDetails}
            />
          </div>
          <div className="flex flex-col basis-full lg:basis-7/12">
            {isNewUser && (
              <div className="mb-2">
                <AccountCreationBar />
              </div>
            )}
            <MembershipFormContainer
              isNewUser={isNewUser}
              currentMembershipDetails={currentMembershipDetails}
              publicProfileData={publicProfileData}
              loading={membershipLoading || publicProfileLoading}
              options={options}
              hasRSVP={showRSVP}
              prefillData={props?.location?.state?.prefill_data}
              userTempTokenRef={userTempTokenRef}
              entityProps={{
                entity_type: [
                  "community_member",
                  "provisional_member",
                ].includes(props.entity_type)
                  ? "provisional_member"
                  : props.entity_type,
                entity_id: currentMembershipDetails.entity_id,
              }}
              course_id={
                props.location?.state?.course_id ??
                parsed.course_id ??
                courseDetails.id ??
                currentMembershipDetails?.course?.id
              }
              onUpdate={() => {
                fetchMembership();
              }}
            />
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

MembershipRegistration.propTypes = {
  entity_type: PropTypes.any,
  location: PropTypes.shape({
    state: PropTypes.shape({
      course_id: PropTypes.any,
      prefill_data: PropTypes.any,
    }),
  }),
};

export default MembershipRegistration;
