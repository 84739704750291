/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";
import { CaretUp, Circle } from "@phosphor-icons/react";
import CalendarSessionDetails from "../../stories/CalendarSessionDetails";
import { getCourseColor } from "../../constants/courseColor";

const WEEK_ARR = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

const MonthView = ({
  setEventToDisplay,
  eventToDisplay,
  events,
  dateRange,
}) => {
  return (
    <div className="bg-zinc-50">
      <div className="grid grid-cols-7 lg:hidden">
        {dateRange.map((date, date_index) => {
          const dateObj = moment(date, "DD-MM-YYYY");
          const isSelected =
            moment(
              eventToDisplay?.[0]?.date?.split("T")[0],
              "YYYY-MM-DD"
            ).format("DD-MM-YYYY") === date;
          return (
            <div
              key={`${date}_${date_index}`}
              className="flex h-16 justify-center items-center px-2 py-2.5"
            >
              <div
                className={classNames(
                  "gap-0.5 rounded-full px-2 py-1.5 flex flex-col items-center",
                  isSelected && "border border-theme-orange bg-theme-orange/10"
                )}
                onClick={() => {
                  setEventToDisplay(events[date]);
                }}
              >
                <span className="text-xs font-semibold">{dateObj.date()}</span>
                <span className="text-xxs font-regular">
                  {WEEK_ARR[dateObj.day()]}
                </span>
                {isSelected && events[date]?.length > 0 ? (
                  <Circle
                    weight="fill"
                    className="w-1.5 h-1.5 text-theme-orange"
                  />
                ) : (
                  <div className="h-1.5" />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col w-full gap-4 lg:hidden">
        {!_.isEmpty(eventToDisplay) && (
          <CalendarSessionDetails eventToDisplay={eventToDisplay} />
        )}
      </div>
      <div className="flex-col hidden w-full lg:flex">
        <div className="grid w-full grid-cols-7 ">
          <div className="flex items-center justify-center text-sm border border-l-0 border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Mon
          </div>
          <div className="flex items-center justify-center text-sm border border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Tue
          </div>
          <div className="flex items-center justify-center text-sm border border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Wed
          </div>
          <div className="flex items-center justify-center text-sm border border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Thr
          </div>
          <div className="flex items-center justify-center text-sm border border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Fri
          </div>
          <div className="flex items-center justify-center text-sm border border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Sat
          </div>
          <div className="flex items-center justify-center text-sm border border-r-0 border-collapse border-theme-gray-300 text-theme-black-color h-11">
            Sun
          </div>
        </div>
        <div className="grid w-full grid-cols-7">
          {dateRange.map((date, date_index) => {
            const dateObj = moment(date, "DD-MM-YYYY");
            const eventsInADay = events[date] ?? [];
            const eventGroup = {};
            eventsInADay?.forEach((event) => {
              if (_.isEmpty(eventGroup[event.course_short_title])) {
                eventGroup[event.course_short_title] = [event];
                return;
              }
              eventGroup[event.course_short_title].push(event);
            });
            return (
              <div
                key={`${date}_${date_index}`}
                className="flex flex-col pb-6 items-center  border border-collapse border-theme-gray-300 justify-start gap-3 px-2 min-h-[100px] bg-white py-1.5"
              >
                <div className="text-sm font-semibold">{dateObj.date()}</div>
                {_.keys(eventGroup)?.map((course_name, e_index) => {
                  return (
                    <ShowCourseEvents
                      key={e_index}
                      course_name={course_name}
                      events={eventGroup[course_name]}
                      setEventToDisplay={setEventToDisplay}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

MonthView.propTypes = {
  dateRange: PropTypes.array,
  events: PropTypes.object,
  eventToDisplay: PropTypes.any,
  setEventToDisplay: PropTypes.func,
};

export default MonthView;

const ShowCourseEvents = ({ course_name, events, setEventToDisplay }) => {
  const [isOpen, setIsOpen] = useState(false);
  const color = getCourseColor(events[0]?.course_type);
  return (
    <div className="flex flex-col w-full gap-2">
      <div
        className={`flex items-center cursor-pointer mx-0.5 justify-between w-[calc(100%-8px)] p-1 border rounded border-${color}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div
          className={`flex break-all leading-none text-${color} line-clamp-2 max-h-8 text-xs tracking-tight`}
        >
          {course_name}
        </div>
        <CaretUp
          className={classNames(
            "w-4 h-4 min-w-[16px] flex text-theme-black-color",
            isOpen ? "" : "transform rotate-180"
          )}
          weight="fill"
        />
      </div>
      {isOpen && (
        <div className="flex flex-col items-start w-full gap-1 pb-3">
          {events?.map((event, e_index) => (
            <>
              <div
                className="flex items-center justify-start h-4 text-xs leading-4 cursor-pointer line-clamp-1"
                onClick={() => setEventToDisplay([event])}
              >
                <Circle
                  className="inline-flex w-2 h-2 mr-1"
                  weight="fill"
                  color="#f36c04"
                />
                {event.title}
              </div>
              {e_index !== events.length - 1 && (
                <div className="w-full my-0.5 border-t border-theme-gray-300" />
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

ShowCourseEvents.propTypes = {
  course_name: PropTypes.string,
  events: PropTypes.array,
  setEventToDisplay: PropTypes.func,
};
